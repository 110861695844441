import React, {useEffect, useState} from "react";
import {
    Card,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import WarehouseService from "./WarehouseService";
import Warehouse from "./Warehouse";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import WarehouseForm from "./WarehouseForm";
import {Autocomplete, DialogTitle, Paper, TextField} from "@mui/material";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import InfoChip from "../components/chips/InfoChip";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "15%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function WarehousesTable(props) {


    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [code, setCode] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [warehouses, setWarehouses] = useState([]);

    const [fetching, setFetching] = useState(true);
    const [pages, setPages] = useState([]);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchContracts() {

        let filters = {}
        if (account) {
            filters.accountId = account.id;
        }

        await new ContractService().getContractsByFilters(filters)
            .then(result => {
                setContracts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };

    function handleAccountChange(event, account) {
        setContract(null);
        setAccount(account);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleCodeChange(event) {
        setCode(event.target.value)
    }

    async function fetchWarehouses() {

        setFetching(true);

        let filters = {};

        if (code) {
            filters.code = code;
        }
        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        await new WarehouseService().getWarehousesByFilters(page, rowsPerPage, filters)
            .then(data => {
                setWarehouses(data.warehouses);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function goToWarehouseForm() {
        history.push("/warehouses/new")
    }

    function refresh() {
        setRefreshTable(!refreshTable);
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");

        setRefreshTable(!refreshTable);

    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    useEffect(() => {
        fetchWarehouses(); //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, page, rowsPerPage, refreshTable])


    return <div className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/warehouses`}>
                <Card>
                    <DialogTitle>{t("filters")}</DialogTitle>
                    <TextField
                        label={t("code")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        size={"small"}
                        onChange={handleCodeChange}
                        value={code}
                        className={classes.field}
                    />
                    <Autocomplete
                        id="accountId"
                        size="small"
                        style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                        options={accounts}
                        getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                        value={account}
                        defaultValue={account}
                        onOpen={fetchAccounts}
                        noOptionsText={t("no_options")}
                        onChange={handleAccountChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("account")}
                                InputLabelProps={{shrink: true}}
                                placeholder={t("any")}
                                variant="outlined"
                                margin="dense"
                                value={account}
                                defaultValue={account}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="contractId"
                        size="small"
                        disabled={!account}
                        style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                        options={contracts}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={contract}
                        defaultValue={contract}
                        onOpen={fetchContracts}
                        noOptionsText={t("no_options")}
                        onChange={handleContractChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("contract")}
                                InputLabelProps={{shrink: true}}
                                placeholder={t("any")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <div>

                        <Button startIcon={<SearchOutlined/>} className={classes.button} variant="contained"
                                color="primary" type="button" onClick={refresh}>
                            {t("search")}
                        </Button>
                    </div>
                </Card>
                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToWarehouseForm}
                        className={classes.button}>
                    {t("create_new_warehouse")}
                </Button>
                <Paper className={classes.container}>
                    {fetching ?

                        <LoadingComponent/>

                        :
                        <div>
                            {warehouses.length > 0 ?
                                <Table stickyHeader className={classes.table}>
                                    <TableHead>
                                        <TableCell>{t("ID")}</TableCell>
                                        <TableCell>{t("code")}</TableCell>
                                        <TableCell>{t("name")}</TableCell>
                                        <TableCell>{t("company")}</TableCell>
                                        <TableCell>{t("contract")}</TableCell>
                                        <TableCell>{t("address")}</TableCell>
                                        <TableCell>{t("contacts")}</TableCell>
                                        <TableCell/>
                                    </TableHead>
                                    <TableBody>
                                        {warehouses.map((warehouse, index) => {

                                            return <Warehouse deleteCallback={deleteCallback} warehouse={warehouse}/>
                                        })}
                                    </TableBody>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={-1}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: false,
                                        }}
                                        labelRowsPerPage={t("rows_per_page")}
                                        labelDisplayedRows={() => {
                                            return t("page")
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Table>
                                :
                                <InfoChip>{t("no_records_found")}</InfoChip>
                            }
                        </div>

                    }
                </Paper>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>

            </PrivateRoute>

            <PrivateRoute strict path={`/warehouses/:id/edit/`} children={<WarehouseForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/warehouses/new/`}>
                <WarehouseForm warehouse={null}/>
            </PrivateRoute>

        </Switch>
    </div>

}

export default WarehousesTable;