import {Button, DialogTitle, Paper, Table, TableCell, TextField, Typography} from "@mui/material";
import {Autocomplete} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MessageTemplateService from "./MessageTemplateService";
import {useHistory, useParams} from "react-router-dom";
import LanguageService from "./LanguageService";
import {LoadingComponent} from "../../../components/loading/LoadingComponent";
import {ArrowBackRounded, Save} from "@material-ui/icons";
import StyledButton from "../../../components/buttons/StyledButton";
import Notification from "../../../components/notifications/Notification";
import {Info, Preview} from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import {Backdrop, Box, Fade, Modal, TableRow} from "@material-ui/core";


function MessageTemplateForm(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [template, setTemplate] = useState(null);

    const [loading, setLoading] = useState(false);

    const [preview, setPreview] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [openLegend, setOpenLegend] = useState(false);



    const [body, setBody] = useState('');
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [languages, setLanguages] = useState(JSON.parse(localStorage.getItem("languages")));
    const [language, setLanguage] = useState(null)
    const [notificationType, setNotificationType] = useState(null)
    const [messageType, setMessageType] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const {contractId, templateId} = useParams();

    let notificationTypes = [
        'SHIPMENT_REGISTERED',
        'SHIPMENT_TAKEN_IN_CHARGE',
        'IN_DELIVERY',
        'DELIVERED',
        'DELIVERY_KO',
        'WAREHOUSE_STORAGE',
        'PIN_CODE',
        'RETURN_REGISTERED',
        'RETURN_COLLECTED',
        'RETURN_DELIVERED',
        'CUSTOMER_SERVICE_NEW_RETURN',
        'OPERATIONS_ORDER_LOCK',
        'OPERATIONS_BOOKING_UPDATE',
        'OPERATIONS_NEW_SC',
        'PUDO_CHECK_IN',
        'PUDO_CHANGE',
        'PICKUP_REMAINDER',
        'SECOND_PICKUP_REMAINDER'
    ]

    let messageTypes = [
        'EMAIL', 'TEXT_MESSAGE'
    ]

    async function saveTemplate() {

        let newTemplate = {};

        if(null === language || '' === body || null === body || '' === notificationType || null === notificationType || '' === messageType || null === messageType){
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage("Valorizzare tutti i campi");
        } else {
            if(template?.contractId){
                newTemplate.contractId = template.contractId;
            } else if(contractId){
                newTemplate.contractId = contractId;
            }
            if(language){
                newTemplate.languageId = language.id;
            }
            newTemplate.bodyText = body;
            newTemplate.notificationType = notificationType;
            newTemplate.messageType = messageType;

            if(null !== template){

                await new MessageTemplateService().editTemplate(template.id, newTemplate)
                    .then(result => {
                        setNotifySeverity('success');
                        setNotificationMessage(t("successful"));
                        setOpenNotify(true);
                        setTimeout(()=>{
                            history.goBack();
                        }, 2000)
                    })
                    .catch(error => {
                        error.response.json().then((response) => {
                            setNotifySeverity('error');
                            setNotificationMessage(response.message);
                            setOpenNotify(true);
                        });
                    })
            } else {
                await new MessageTemplateService().saveTemplate(newTemplate)
                    .then(result => {
                        setNotifySeverity('success');
                        setNotificationMessage(t("successful"));
                        setOpenNotify(true);
                        setTimeout(()=>{
                            history.goBack();
                        }, 2000)
                    })
                    .catch(error => {
                        error.response.json().then((response) => {
                            setNotifySeverity('error');
                            setNotificationMessage(response.message);
                            setOpenNotify(true);
                        });
                    })
            }
        }




    }


    async function fetchTemplate() {

        setLoading(true);

        await new MessageTemplateService().getTemplateById(templateId)
            .then(result => {
                setTemplate(result);
                setBody(result.bodyText)
                setMessageType(result.messageType)
                setNotificationType(result.notificationType)
                setLanguage(languages.filter(value => value.iso639Alpha2 === result.language)[0])
                // setLoading(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

        setTimeout(()=>{
            setLoading(false)
        }, 1000)

    }

    async function getPreview() {
        await new MessageTemplateService().getPreview(body)
            .then(result => {
                setPreview(result);
                setOpenPreview(true);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }

    async function fetchLanguages() {

        let lang = JSON.parse(localStorage.getItem("languages"));

        if (lang === null) {
            lang = await new LanguageService().getLanguages()
                .catch(error => {

                });

            localStorage.setItem("languages", JSON.stringify(lang));
        }

        setLanguages(lang);
    }

    useEffect(() => {
        fetchLanguages();

        if(templateId !== 'new'){
            fetchTemplate();
        } else {
            setLoading(false);
        }

    }, [])


    return <Paper>

        {loading ?
            <LoadingComponent/>

            :

            <div style={{
                width: '100%',
                textAlign: 'center',
                justifyContent: "center",
                display: "block"
            }}>
                <div style={{display: "flex"}}>
                    <Button
                        style={{margin: "2%", background: "transparent"}}
                        onClick={() => history.goBack()}
                        startIcon={<ArrowBackRounded/>}
                        variant={"filled"}
                    >
                        {t("back")}
                    </Button>
                </div>
                <DialogTitle>{t("edit_template")}</DialogTitle>
                <div>
                    <IconButton onClick={() => setOpenLegend(true)}><Info color={"info"}/></IconButton>
                </div>
                <Autocomplete
                    size="small"
                    style={{width: "10%", margin: "auto", marginBlock: "1%"}}
                    options={languages}
                    getOptionLabel={option => option.name}
                    value={language}
                    defaultValue={language}
                    noOptionsText={t("no_options")}
                    onChange={(event, lang) => setLanguage(lang)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("language")}
                            size={"small"}
                            variant="outlined"
                            margin="dense"
                            value={language}
                            defaultValue={language}
                        />
                    )}
                />
                <Autocomplete
                    size="small"
                    style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                    options={notificationTypes}
                    getOptionLabel={option => t(option)}
                    value={notificationType}
                    defaultValue={notificationType}
                    noOptionsText={t("no_options")}
                    onChange={(event, type) => setNotificationType(type)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("notification_type")}
                            size={"small"}
                            variant="outlined"
                            margin="dense"
                            value={notificationType}
                            defaultValue={notificationType}
                        />
                    )}
                />
                <Autocomplete
                    size="small"
                    style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                    options={messageTypes}
                    getOptionLabel={option => t(option)}
                    value={messageType}
                    defaultValue={messageType}
                    noOptionsText={t("no_options")}
                    onChange={(event, messType) => setMessageType(messType)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("message_type")}
                            size={"small"}
                            variant="outlined"
                            margin="dense"
                            value={messageType}
                            defaultValue={messageType}
                        />
                    )}
                />
                <div style={{width: "100%", margin: "auto", marginBlock: "1%"}}>
                    <TextField
                        margin="dense"
                        variant="outlined"
                        size="small"
                        label={t("body_text")}
                        multiline
                        style={{
                            display: "flex",
                            margin: "auto",
                            width: "75%"
                        }}
                        value={body}
                        defaultValue={body}
                        onChange={event => setBody(event.target.value)}
                    />
                </div>
                <div>
                    <IconButton  onClick={getPreview}><Preview/></IconButton>
                </div>
                <StyledButton onClick={saveTemplate} endIcon={<Save/>}>
                    {t("save")}
                </StyledButton>



                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openPreview}
                    onClose={() => setOpenPreview(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openPreview}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: "50%",
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}>

                            {messageType === "EMAIL" ?
                                <div>
                                    <Typography variant={"subtitle2"}>
                                        {t("email_subject")}
                                    </Typography>
                                    <Typography style={{border: "solid 1px", marginBottom: "2%"}}>
                                        &nbsp;{preview.subject ? preview.subject : ""}
                                    </Typography>
                                </div>

                                :

                                null
                            }



                            <div align={"left"}
                                // dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(communication.message)}}/>
                                 dangerouslySetInnerHTML={{__html: preview.preview}}/>


                        </Box>
                    </Fade>
                </Modal>
                <Modal open={openLegend}
                    // className={classes.modal}
                       onClose={() => setOpenLegend(false)}
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{
                           timeout: 500,
                       }}
                >
                    <Fade in={openLegend}>
                        <Paper variant={"outlined"} elevation={2} style={{
                            width: "70%",
                            marginInline: "auto",
                            marginTop: "5%",
                            height: "70%",
                            overflow: "auto",
                            // border: `1px solid black`
                        }}>
                            <div style={{width: "100%", margin: "auto", marginTop: "10%", display: "block"}}>
                                {/*<WarningOutlined color={"warning"}/>*/}
                                <DialogTitle style={{width: "100%", margin:"auto"}}>{"Lista delle macro"}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"[subject] **text** [/subject]"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Utilizzato per il soggetto delle Email"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                {/*<div style={{display: "flex"}}>*/}
                                {/*    <Typography style={{margin:"auto"}}>{"[subject] **text** [/subject]"}</Typography><Typography style={{margin:"auto"}}>{"Utilizzato per il soggetto delle Email"}</Typography>*/}

                                {/*</div>*/}
                                <DialogTitle style={{margin:"auto"}}>{"Lista dei placeholder"}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{subject}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il soggetto delle email"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_number}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il numero di spedizione del TMS"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{order_number}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il numero ordine del TMS"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_reference}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il riferimento della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_customer_reference}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il riferimento cliente della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{tracking_URL}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"L'URL del tracking "}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{short_link}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Lo short link del tracking"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{provision_link}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Link per inviare le disposizioni della giacenza"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{authentication}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il codice di autenticazione della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{delivery_name}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il nominativo di consegna"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{sender_name}} "}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il nominativo mittente"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{booking_dalle}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Ora inizio dello slot di prenotazione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{booking_alle}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Ora fine dello slot di prenotazione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{booking_date}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Data di prenotazione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{booking}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Utilizzato nei comandi condizionali per indicare se booking è presente o meno"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_service}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Codice servizio della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_account_acc}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Codice cliente dell'account della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_account_company_name}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Ragione sociale dell'account della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_contract}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Codice del contratto della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{shipment_subcontract}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Codice del sotto-contratto della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{delivery_address}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Indirizzo di consegna della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{pickup_address}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Indirizzo di ritiro della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{sender_address}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Indirizzo di mittenza della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{end_user_address}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Indirizzo dell'utente finale della spedizione"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{end_user_name}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il nominativo del destinatario finale"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{merchant_notes}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Note del venditore indirizzate al cliente"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{warehouses:[warehouseCode1,warehouseCode2,...,warehouseCodeN]}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Inserito all'interno di un blocco condizionale, il testo viene inserito se la filiale di consegna della spedizione compare tra la lista di filiali fornita"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"es. {{warehouses:[DEV,TST,WC1]}}"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{!warehouses:[warehouseCode1,warehouseCode2,...,warehouseCodeN]}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Inserito all'interno di un blocco condizionale, il testo viene inserito se la filiale di consegna della spedizione NON compare tra la lista di filiali fornita"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"es. {{!warehouses:[DEV,TST,WC1]}}"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{rating_URL}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"L'URL della pagina di rating del servizio"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{cod}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Il valore del contrassegno della spedizione. Può essere inserito in un blocco condizionale"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{pudo_collection_expiry_date}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Termine ultimo per ritirare la spedizione presso il pudo"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"{{koCode:[code1,code2, ... ,codeN]}}"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Inserito all'interno di un blocco condizionale, il testo viene inserito se almeno un collo ha una consegna fallita con uno dei codici inseriti"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"es. {{koCode:[45,54]}}"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <DialogTitle style={{margin:"auto"}}>{"Lista dei comandi condizionali"}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"<<{{placeholder}}begin>>   <<{{placeholder}}end>>"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Blocco condizionale; viene inserito nel testo se la variabile di placeholder è presente"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <DialogTitle style={{margin:"auto"}}>{"Comando di annullamento"}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"<<{{placeholder}}begin>> _ABORT_ <<{{placeholder}}end>>"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"Se inserito (es. all'interno di un blocco condizionale) annulla l'invio dell'email/SMS"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <DialogTitle style={{margin:"auto"}}>{"Lista dei cid delle immagini"}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:parcel_delivery"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:booking_change"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:instagram-icon"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:linkedin-icon"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:facebook-icon"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:pattern"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:logo"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:logoArceseBw"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:booking_queue"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:delivery_failed"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:new_shipment"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:new_pickup"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:shipment_delivered"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:shipment_in_delivery"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:return_registration"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:shipment_registration"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:return_delivered"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:return_picked_up"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:typ_logo_white"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:delivered"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:contact_customer_service"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:in_transit"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:change_delivery_booking"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:taken_in_charge"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:reschedule_pickup"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:track_my_shipment"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:verify_shipment_status"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{margin:"auto"}}>{"cid:cid:inizia_ora"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </div>
                        </Paper>
                    </Fade>

                </Modal>
            </div>
        }
    </Paper>


}

export default MessageTemplateForm;