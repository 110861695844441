import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

const userService = new UserService();


export default class ContractService {

    getContractsByFilters(filters) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/contracts`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }


    getPagedContractsByFilters(filters) {

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/contracts/filters`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getContractById(id) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/contracts/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    saveContract(contract) {
        const apiKey = userService.getApiKey();

        let contractObj = {
            code: contract.code,
            name: contract.name,
            accountId: contract.accountId,
            companyId: contract.companyId,
            departureWarehouseId: contract.departureWarehouseId,
            validFrom: contract.validFrom,
            validUntil: contract.validUntil,
            addresses: contract.addresses,
            notifications: contract.notifications,
            whatsappStream: contract.whatsappStream,
            canReturnShipment: contract.canReturnShipment,
            pudoChangeRequest: contract.pudoChangeRequest,
            returnFeedbackText: contract.returnFeedbackText,
            rates: contract.rates,
            warehouseStorageNotificationEmail: contract.warehouseStorageNotificationEmail,
            dedicatedCustomerCareEmail: contract.dedicatedCustomerCareEmail,
            operatorEmail: contract.operatorEmail,
            numOfAllowedDeliveryAttempts: contract.numOfAllowedDeliveryAttempts,
            direction: contract.direction,
            bankAccounts : contract.bankAccounts,
            enabledCodModes: contract.enabledCodModes,
            codEmail: contract.codEmail,
            defaultServiceId: contract.defaultServiceId,
            partialDepartureDisabled: contract.partialDepartureDisabled,
            partialDeliveryDisabled: contract.partialDeliveryDisabled,
            blockDepartureWithoutMandatoryBooking: contract.blockDepartureWithoutMandatoryBooking,
            blockDeliveryWithoutMandatoryBooking: contract.blockDeliveryWithoutMandatoryBooking,
            abiConfiguration: contract.abiConfiguration,
            contractExchangeConfigurations: contract.contractExchangeConfigurations,
            taxReleaseWithoutProvision: contract.taxReleaseWithoutProvision,
        };

        let pathVariable = `/api/v1/contracts`;

        let requestOptions;
        if (contract.id) {
            pathVariable = `${pathVariable}/${contract.id}`
            requestOptions = {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(contractObj)
            };
        } else {
            requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(contractObj)
            };
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }

    uploadLogo(contractId, content, mimeType) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                content: content,
                mimeType: mimeType
            })
        };

        let pathVariable = `/api/v1/contracts/${contractId}/logo`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deleteLogo(contractId) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        };

        let pathVariable = `/api/v1/contracts/${contractId}/logo`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getLogo(contractId) {


        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/contracts/${contractId}/logo`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    getEnabledAbiEventCodes(){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/contracts/abi-event-codes`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

}

