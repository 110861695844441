import {Button, Menu, MenuItem, Table, TableCell} from "@material-ui/core";
import {CircularProgress, TableRow} from "@mui/material";
import React, {useState} from "react";
import PaymentSlipsService from "./PaymentSlipsService";
import MenuIcon from "@material-ui/icons/Menu";
import {Download} from "@mui/icons-material";
import {useTranslation} from "react-i18next";


function PaymentSlip(props) {

    let payment = props.payment;

    const [loadingCSV, setLoadingCSV] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const {t} = useTranslation();

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    async function getCsv() {

        setLoadingCSV(true);

        await new PaymentSlipsService().getCsv(payment.id)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })

        setLoadingCSV(false);
    }

    async function getPdf() {

        setLoadingPDF(true);

        await new PaymentSlipsService().getPdf(payment.id)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingPDF(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })

        setLoadingPDF(false);
    }

    function getToPayTotal() {

        return payment.transactionsTotal - payment.totalPaid;

        // for (let i = 0; i < payment.bankTransactions.length; i++) {
        //
        //     toPay -= payment.bankTransactions[i].amount;
        //
        // }
        //
        // return toPay;

    }

    return <TableRow>
        <TableCell align={"center"}>
            {payment.id}
        </TableCell>
        <TableCell align={"center"}>
            {payment.warehouseCode}
        </TableCell>
        <TableCell align={"center"}>
            {payment.supplier + " - " + payment.supplierCompanyName}
        </TableCell>
        <TableCell align={"center"}>
            {payment.totalPaid.toFixed(2)}
        </TableCell>
        <TableCell align={"center"}>
            {new Date(payment.dateTime).toLocaleDateString()}
        </TableCell>
        <TableCell align={"center"}>
            {getToPayTotal().toFixed(2)}
        </TableCell>
        <TableCell align={"center"}>
            {payment.transactionsTotal.toFixed(2)}
        </TableCell>
        <TableCell align={"center"}>
            <Table>
                {payment.bankTransactions?.map(transaction => {
                    return <TableRow style={{textAlign: 'center'}}>{transaction.transactionId}</TableRow>
                })}
            </Table>
        </TableCell>
        <TableCell align={"center"}>
            <Table>
                {payment.bankTransactions?.map(transaction => {
                    return <TableRow style={{textAlign: 'center'}}>{transaction.amount.toFixed(2)}</TableRow>
                })}
            </Table>
        </TableCell>
        <TableCell align={"center"}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={getCsv}>{loadingCSV ? <CircularProgress size={24}/> :
                    <Download/>}&nbsp;{t("download_csv")}</MenuItem>

                <MenuItem onClick={getPdf}>{loadingPDF ? <CircularProgress size={24}/> :
                    <Download/>}&nbsp;{t("download_pdf")}</MenuItem>

            </Menu>
        </TableCell>
    </TableRow>


}

export default PaymentSlip;