import React, {useEffect, useState} from "react";
import moment from "moment";
import MasterPalletService from "./MasterPalletService";
import {format} from "date-fns";
import DateField from "../components/fields/DateField";
import {useTranslation} from "react-i18next";
import {CircularProgress, IconButton, Paper, Table, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {SearchOutlined} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import InfoChip from "../components/chips/InfoChip";
import {Button, Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import DriverWorkListService from "../driver_work_list/DriverWorkListService";
import Notification from "../components/notifications/Notification";
import theme from "../theme/theme";
import {CloudDownload} from "@mui/icons-material";
import StyledButton from "../components/buttons/StyledButton";


export default function MasterPalletDashboard() {

    const {t} = useTranslation();

    const [dateFrom, setDateFrom] = useState(moment().subtract(1, "week"));
    const [dateTo, setDateTo] = useState(moment());
    const [eventCodes, setEventCodes] = useState([]);
    const [eventCode, setEventCode] = useState(null);
    const [warningOnly, setWarningOnly] = useState(false);
    const [masterPalletTypes, setMasterPalletTypes] = useState([]);
    const [masterPallets, setMasterPallets] = useState([]);
    const [barcode, setBarcode] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);

    const [mapByWarehouse, setMapByWarehouse] = useState(new Map());

    function mapByWarehouses(pallets) {

        let map = new Map();
        for (let i = 0; i < pallets.length; i++) {
            if (map.has(pallets[i].arrivalWarehouseCode)) {
                let value = map.get(pallets[i].arrivalWarehouseCode);
                value.push(pallets[i]);
                map.set(pallets[i].arrivalWarehouseCode, value);
            } else {
                let value = [];
                value.push(pallets[i]);
                map.set(pallets[i].arrivalWarehouseCode, value);
            }
        }

        return map;
    }

    async function getMasterPalletTypes() {

        await new MasterPalletService().getMasterPalletTypes()
            .then(result => {
                setMasterPalletTypes(result);
            })
            .catch(error => {

            })

    }

    async function getMasterPalletEventCodes(){

        await new MasterPalletService().getMasterPalletEventCodes()
            .then(result => {
                setEventCodes(result);
            })
            .catch(error => {

            })
    }

    async function getMasterPallets() {

        setLoading(true)

        let filters = {};
        filters.dateFrom = moment(dateFrom).format("yyyy-MM-DD");
        filters.dateTo = moment(dateTo).format("yyyy-MM-DD");

        if(warningOnly){
            filters.isWarning = true;
        }

        if(eventCode){
            filters.lastEventCodeId = eventCode.id;
        }

        if(barcode){
            filters.barcode = barcode;
        }

        await new MasterPalletService().getByFilters(filters)
            .then(result => {
                setMapByWarehouse(mapByWarehouses(result));
                setMasterPallets(result);
                setLoading(false)
            })
            .catch(error => {
                setLoading(false);
            })

    }

    async function downloadCsv() {

        setLoadingCsv(true);

        let filters = {};
        filters.dateFrom = moment(dateFrom).format("yyyy-MM-DD");
        filters.dateTo = moment(dateTo).format("yyyy-MM-DD");

        if(warningOnly){
            filters.isWarning = true;
        }

        if(eventCode){
            filters.lastEventCodeId = eventCode.id;
        }

        if(barcode){
            filters.barcode = barcode;
        }

        await new MasterPalletService().getCsv(filters)
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:text/csv;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
                setLoadingCsv(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCsv(false);
                })
            });


    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function isArrivedAtWarehouse(element) {
        return element.eventCodeId === 3;
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleEventCodeChange(event, value){
        setEventCode(value);
    }

    function handleWarningOnlyChange(event){
        setWarningOnly(event.target.checked);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value.trim());
    }

    useEffect(() => {
        getMasterPalletTypes();
        getMasterPalletEventCodes();
    }, []);

    return <div>
        <div style={{margin: "auto", width: "50%", display: "flex"}}>
            <DateField
                style={{width: "20%", margin: "auto"}}
                label={t("date_from")}
                value={dateFrom}
                onChange={handleDateFromChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
                clearable={false}
            />

            <DateField
                style={{width: "20%", margin: "auto"}}
                label={t("date_to")}
                value={dateTo}
                onChange={handleDateToChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
                clearable={false}
            />
        </div>
        <div style={{margin: "auto", width: "50%", display: "flex", marginBlock: "2%"}}>
            <TextField
                style={{width: "40%", margin: "auto", marginBlock: "2%"}}
                label={t("barcode")}
                variant="outlined"
                margin="dense"
                value={barcode}
                defaultValue={barcode}
                onChange={handleBarcodeChange}
            />
        </div>
        <div style={{margin: "auto", width: "50%", display: "flex"}}>
            <Autocomplete
                id="eventCodes"
                options={eventCodes}
                size={"small"}
                style={{width: "40%", margin: "auto"}}
                getOptionLabel={option => option.id + " - " + option.description}
                value={eventCode}
                defaultValue={eventCode}
                noOptionsText={t("no_options_available")}
                onChange={handleEventCodeChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("last_event_code")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        placeholder={t("any")}
                        value={eventCode}
                        defaultValue={eventCode}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{margin: "auto", width: "50%", display: "flex", marginBlock: "2%"}}>
            <FormControlLabel
                style={{margin: "auto"}}
                label={<Typography fontSize={12}
                                   variant={"button"}>{t("show_masterpallets_warning_only")}</Typography>}
                control={
                    <Checkbox
                        color={"primary"}
                        checked={warningOnly}
                        onChange={handleWarningOnlyChange}
                    />
                }
            />
        </div>
        <div style={{margin: "auto", width: "50%", display: "flex"}}>
            <IconButton style={{margin: "auto"}} onClick={getMasterPallets}><SearchOutlined/></IconButton>
        </div>


        <Paper elevation={0} style={{width: "70%", margin: "auto", marginTop: "5%", border: "1px solid"}}>
            {loading? <LoadingComponent/> :
                <>
                {masterPallets.length > 0?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                {masterPalletTypes.map(item => {
                                    return <TableCell align={"center"}>
                                        <Typography variant={"subtitle2"}>{item.name}</Typography>
                                        <Typography variant={"subtitle2"}>{item.code}</Typography>
                                    </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        {[...mapByWarehouse.entries()].map(item => {
                            return <TableRow>
                                <TableCell>
                                    <Typography>{item[0]}</Typography>
                                </TableCell>
                                {masterPalletTypes.map(type => {
                                    return <TableCell align={"center"}>
                                        {item[1]?.filter(pallet => pallet.type === type.code)?.length > 0?
                                            <Typography
                                                style={{width: "fit-content", padding: "4%", margin: "auto",borderRadius: "5px", border: "2px solid", borderColor: item[1]?.filter(pallet => pallet.type === type.code && pallet.events.some(isArrivedAtWarehouse))?.length === 0? "#428bff" :(item[1]?.filter(pallet => pallet.type === type.code && pallet.events.some(isArrivedAtWarehouse))?.length === item[1]?.filter(pallet => pallet.type === type.code)?.length? "#0cbb00" : "#ff2626")}}
                                                variant={"subtitle2"}>{item[1]?.filter(pallet => pallet.type === type.code && pallet.events.some(isArrivedAtWarehouse))?.length}{"/"}{item[1]?.filter(pallet => pallet.type === type.code)?.length}</Typography>
                                            :
                                            "--"
                                        }
                                    </TableCell>
                                })}
                            </TableRow>
                        })}
                    </Table>
                    :
                    <InfoChip>{t("no_records_found")}</InfoChip>
                }
                </>
            }
        </Paper>
        <div style={{margin: "auto", width: "fit-content", display: "flex", marginTop: "2%"}}>
            <Button color={"primary"} variant={"contained"} disabled={loadingCsv || masterPallets.length < 1} onClick={downloadCsv}
                    endIcon={loadingCsv ?
                        <CircularProgress size={24} style={{color: "white"}}/> :
                        <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_csv")}</Button>
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </div>

}