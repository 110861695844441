import React, {useEffect, useState} from "react";
import {Card, DialogTitle, IconButton, Table, TableHead, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {AppStyles} from "../../theme/AppStyles";
import {Button, Checkbox, Chip, FormControlLabel, TableCell, TableRow, TextField} from "@material-ui/core";
import {Autocomplete} from "@mui/material";
import WarehouseStorageService from "../../warehouse_storages/WarehouseStorageService";
import Notification from "../../components/notifications/Notification";
import DateField from "../../components/fields/DateField";
import WarehouseService from "../../warehouses/WarehouseService";
import ParcelService from "../services/ParcelService";
import ShipmentService from "../ShipmentService";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import theme from "../../theme/theme";
import {ArrowBackRounded, CancelRounded, CheckRounded, SaveOutlined} from "@material-ui/icons";
import InfoChip from "../../components/chips/InfoChip";
import WarehouseCollectionService from "./WarehouseCollectionService";
import moment from "moment";
import CodEventCodeService from "../services/CodEventCodeService";


function WarehouseCollectionPage(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [codEventCodes, setCodEventCodes] = useState([]);

    const [document, setDocument] = useState(null);

    const classes = AppStyles();

    const [greenBarcodes, setGreenBarcodes] = useState([]);
    const [unknownBarcodes, setUnknownBarcodes] = useState([]);
    const [parcelQueue, setParcelQueue] = useState([]);

    const [trigger, setTrigger] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [shipmentData, setShipmentData] = useState([]);

    const [barcode, setBarcode] = useState("");
    const [barcodes, setBarcodes] = useState([]);
    const [referenceName, setReferenceName] = useState("");
    const [documentType, setDocumentType] = useState(null);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documentNumber, setDocumentNumber] = useState("");
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [date, setDate] = useState(new Date());

    function handleReferenceNameChange(event) {
        setReferenceName(event.target.value);
    }

    function handleDocumentTypeChange(event, documentType) {
        setDocumentType(documentType);
    }

    function handleDocumentNumberChange(event) {
        setDocumentNumber(event.target.value);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value);
    }

    function handleDateChange(event) {
        setDate(event)
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function allCodsInCollection(){

        let bool = true;

        for (let i = 0; i < shipmentData.length; i++) {
            if(shipmentData[i].cod !== null && shipmentData[i].codMode === null){
                bool = false;
            }
        }

        return bool;
    }

    async function confirmWarehouseCollection() {

        if (!warehouse || !documentType || referenceName === '' || documentNumber === '' || barcodes.length < 1) {
            setNotifySeverity('error');
            setNotificationMessage(t("insert_one_of_fields"));
            setOpenNotify(true);
            return;
        }

        let warehouseCollection = {};

        if (warehouse) {
            warehouseCollection.warehouseId = warehouse.id;
        }

        if (documentType) {
            warehouseCollection.documentTypeId = documentType.id;
        }

        warehouseCollection.dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
        warehouseCollection.referenceName = referenceName;
        warehouseCollection.documentNumber = documentNumber;
        warehouseCollection.barcodes = barcodes;

        let codCollections = [];

        for (let i = 0; i < shipmentData.length; i++) {

            if(shipmentData[i].codMode !== null){
                let codCollection = {};

                codCollection.codId = shipmentData[i].cod.id;

                codCollection.collectData = {};

                codCollection.collectData.codEventCodeId = shipmentData[i].codMode.id;
                codCollection.collectData.verified = shipmentData[i].verified;
                codCollection.collectData.chequeNumber = shipmentData[i].cod?.cheque?.chequeNumber;
                codCollection.collectData.chequeDate = moment(shipmentData[i].cod?.cheque?.chequeDate).format("yyyy-MM-DD");
                codCollection.collectData.bankName = shipmentData[i].cod?.cheque?.bankName;
                codCollection.collectData.dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');

                codCollections.push(codCollection);
            }

        }

        warehouseCollection.codCollections = codCollections;

        await new WarehouseCollectionService().confirmWarehouseCollection(warehouseCollection)
            .then(async result => {
                history.push(`/warehouse-collection/${result.id}/document`)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })
    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })
    }

    function fetchDocumentTypes() {

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getDocumentTypes()
            .then(docTypes => {
                setDocumentTypes(docTypes)
                setDocumentType(docTypes[0]);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })
    }

    function handleKeyDown(event) {
        if (event.code === "Enter" && barcode !== '') {

            if (greenBarcodes.some(green => green.barcode?.toUpperCase() === barcode.toUpperCase() || green.customerBarcode?.toUpperCase() === barcode.toUpperCase())) {
                setNotificationMessage(t("parcel_already_in_list"));
                setNotifySeverity('warning');
                setOpenNotify(true);
                setBarcode('');
            } else {
                let queue = parcelQueue;
                queue.push(barcode)
                setParcelQueue(queue);
                setBarcode('');

                if (parcelQueue.length >= 1) {
                    let barcode = parcelQueue.pop();
                    fetchData(barcode);
                }
            }

        }
    }

    async function fetchData(barcode) {
        setLoading(true);
        let parcelService = new ParcelService();
        let parcel = null;
        parcel = await parcelService.getParcelByBarcode(barcode)
            .catch(error => {
                error.response.json().then(async response => {

                    let errbarcodes = unknownBarcodes;
                    if (!errbarcodes.some(brco => brco.toUpperCase() === barcode.toUpperCase())) {
                        errbarcodes.push(barcode);
                    }
                    setUnknownBarcodes(errbarcodes);

                    setFetched(true);
                })
            })

        if (shipmentData.some(data => {
            return data.parcels.some(parcel => {
                return (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase());
            })
        })) {
            shipmentData.map(data => {
                data.parcels.map(async parcel => {
                    if (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase()) {

                        parcel.checked = true;

                        let _barcodes = barcodes;
                        _barcodes.push(barcode);
                        setBarcodes(_barcodes);

                        let greens = greenBarcodes;
                        let greenData = {};
                        greenData.barcode = parcel.parcel.barcode;
                        greenData.customerBarcode = parcel.parcel.customerBarcode;
                        greens.push(greenData);
                        setGreenBarcodes(greens);
                        setNotificationMessage("parcel_successfully_loaded");
                        setNotifySeverity('success');
                        setOpenNotify(true);
                        setRefresh(!refresh);
                    }
                })
            })
        } else {
            let shipmentService = new ShipmentService();
            let shipmentId = parcel?.shipmentId;
            if (parcel) {
                let shipment = await shipmentService.getShipmentById(shipmentId, null)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let cod = null;

                if (shipment.codId) {
                    cod = await shipmentService.getCodById(shipment.codId)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })
                }



                let data = {};
                data.shipment = shipment;
                data.parcels = [];
                data.cod = cod;
                data.codMode = null;

                for (let i = 0; i < parcels?.length; i++) {
                    let parcelData = {};
                    parcelData.parcel = parcels[i];
                    if (parcels[i].barcode.toUpperCase() === barcode.toUpperCase() || parcels[i].customerBarcode.toUpperCase() === barcode.toUpperCase()) {

                        parcelData.checked = true;

                        let _barcodes = barcodes;
                        _barcodes.push(barcode);
                        setBarcodes(_barcodes);

                        let greens = greenBarcodes;
                        let greenData = {};
                        greenData.barcode = parcel.barcode;
                        greenData.customerBarcode = parcel.customerBarcode;
                        greens.push(greenData);
                        setGreenBarcodes(greens);
                        setNotificationMessage(t("parcel_successfully_loaded"));
                        setNotifySeverity('success');
                        setOpenNotify(true);
                        setRefresh(!refresh);

                    } else {
                        parcelData.checked = false;
                    }

                    data.parcels.push(parcelData);
                }

                setShipmentData([...shipmentData, data]);
            }

        }

        setRefresh(!refresh);
        setTrigger(!trigger);
        setFetched(true);
        setTimeout(() => {
            setLoading(false);
        }, 500)

    }

    function onCodStatusChange(shipmentState, index) {

        console.log(shipmentData);

        return (event, codMode) => {
            let newData = shipmentData;
            newData[index].codMode = codMode;
            setShipmentData(newData);

            setRefresh(!refresh);
            console.log(shipmentData);

        };
    }

    function onVerifiedStatusChange(data, index) {
        return (event) => {
            let newData = shipmentData;
            newData[index].verified = event.target.checked;
            setShipmentData(newData)

            setRefresh(!refresh);
        };
    }

    function onBankNameStatusChange(data, index) {

        if (shipmentData[index].cod.cheque === null) {
            shipmentData[index].cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            let newData = shipmentData;
            newData[index].cod.cheque.bankName = event.target.value;
            setShipmentData(newData);

            setRefresh(!refresh)
        };
    }

    function onChequeNumberStatusChange(data, index) {

        if (shipmentData[index].cod.cheque === null) {
            shipmentData[index].cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            let newData = shipmentData;
            newData[index].cod.cheque.chequeNumber = event.target.value;
            setShipmentData(newData);

            setRefresh(!refresh)
        };
    }

    function onDateStatusChange(data, index) {

        if (shipmentData[index].cod.cheque === null) {
            shipmentData[index].cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            let newData = shipmentData;
            newData[index].cod.cheque.chequeDate = event;
            setShipmentData(newData);

            setRefresh(!refresh)
        };
    }

    async function fetchCodEventCodes(id) {

        await new CodEventCodeService().getCodEventCodesForCodMode(id)
            .then(result => {
                setCodEventCodes(result);
            })
            .catch(error => {

            })

    }

    function renderCodEvent(shipment, index) {
        let shipmentState = shipment;

        return <div style={{display: "block"}}>
            <div>
                <div style={{width: "100%", margin: "auto", marginBottom: "5%"}}>
                        <Autocomplete
                            id="codMode"
                            size="small"
                            style={{
                                marginBottom: "2%",
                                width: "95%",
                                margin: "auto"
                            }}
                            options={codEventCodes}
                            getOptionLabel={option => option.id + " - " + option.name}
                            value={shipmentState?.codMode}
                            defaultValue={shipmentState?.codMode}
                            noOptionsText={t("no_options")}
                            onOpen={() => fetchCodEventCodes(shipmentState.cod.codModeId)}
                            onChange={onCodStatusChange(shipmentState, index)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("cod")}
                                    variant="outlined"
                                    multiline
                                    margin="dense"
                                    value={shipmentState?.codMode}
                                    defaultValue={shipmentState?.codMode}
                                />
                            )}
                        />

                </div>

                <div style={{margin: "auto", width: "fit-content"}}>
                    {shipmentState.cod.chequeForwardingId ?
                        <Chip style={{margin: "auto", width: "fit-content"}}
                              icon={<CheckRounded style={{color: "green"}}/>} label={t("verified")}/>
                        :
                        <FormControlLabel
                            label={<Typography fontSize={12}
                                               variant={"button"}>{t("verified")}</Typography>}
                            control={
                                <Checkbox
                                    color={"primary"}
                                    checked={shipmentState?.verified}
                                    onChange={onVerifiedStatusChange(shipmentState, index)}
                                />
                            }
                            labelPlacement={"right"}
                        />
                    }
                </div>

            </div>
            {shipmentState?.codMode?.id === 3 || shipmentState?.codMode?.id === 4 || shipmentState?.codMode?.id === 5 || shipmentState?.codMode?.id === 6?
                <div style={{display: "block", marginTop: "5%"}}>
                    <div style={{display: "block"}}>
                        <TextField
                            style={{
                                width: "100%",
                                margin: "auto",
                                marginBlock: "1%"
                            }}
                            label={t("bank_name")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            size={"small"}
                            disabled={shipmentState?.cod?.chequeForwardingId}
                            onChange={onBankNameStatusChange(shipmentState, index)}
                            value={shipmentState?.cod?.cheque?.bankName}
                        />
                        <TextField
                            style={{
                                width: "100%",
                                margin: "auto",
                                marginBlock: "1%"
                            }}
                            label={t("cheque_number")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            size={"small"}
                            disabled={shipmentState?.cod?.chequeForwardingId}
                            onChange={onChequeNumberStatusChange(shipmentState, index)}
                            value={shipmentState?.cod?.cheque?.chequeNumber}
                        />
                    </div>
                    <div>
                        <DateField
                            style={{width: "50%", margin: "auto"}}
                            label={t("date")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            format={"dd/MM/yyyy"}
                            clearable={true}
                            disableFuture={true}
                            disabled={shipmentState?.cod?.chequeForwardingId}
                            onChange={onDateStatusChange(shipmentState, index)}
                            value={shipmentState?.cod?.cheque?.chequeDate}
                        />
                    </div>
                </div>
                :
                null
            }

        </div>
    }


    function calcSumOfParcels() {

        let parNum = 0;

        shipmentData.map(shipmentData => {
            shipmentData.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum += 1;
                }
            })
        })

        return parNum;

    }

    async function unloadParcel(parcel) {
        setLoading(true)

        let greens = greenBarcodes;
        let green = greens.filter(green => green.barcode.toUpperCase() === parcel.parcel.barcode.toUpperCase())[0];
        greens.splice(greens.indexOf(green, 1));
        setGreenBarcodes(greens);
        parcel.checked = false;
        for (let i = 0; i < shipmentData.length; i++) {
            if (!shipmentData[i].parcels.some(parcelData =>
                parcelData.checked === true
            )) {
                shipmentData.splice(i, 1);
            }
        }
        setRefresh(!refresh);
        setLoading(false);
    }

    function goBack() {
        history.goBack();
    }


    useEffect(() => {
        fetchDocumentTypes();
    }, [])


    return <div className={classes.root}>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={goBack}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>
        <Card>
            <DialogTitle>{t("warehouse_collection")}</DialogTitle>
            <Autocomplete
                id="warehouse-select"
                size="small"
                options={warehouses}
                className={classes.select}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        required
                        error={!warehouse}
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <TextField
                style={{width: "20%", marginBlock: "2%"}}
                label={t("reference_name")}
                variant={"outlined"}
                size={"small"}
                value={referenceName}
                required
                error={!referenceName}
                onChange={handleReferenceNameChange}
            />
            <Autocomplete
                id="documentType"
                size="small"
                style={{
                    minWidth: "20%",
                    width: "fit-content",
                    margin: "auto"
                }}
                options={documentTypes}
                getOptionLabel={option => option.description}
                value={documentType}
                onChange={handleDocumentTypeChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                noOptionsText={t("no_options")}
                renderInput={(params) => <TextField
                    {...params}
                    label={t("document_type")}
                    variant="outlined"
                    margin="dense"
                    value={documentType}
                    defaultValue={documentType}
                    required
                    error={!documentType}
                    helperText={!documentType ? t("required") : null}
                />}
            />
            <TextField
                style={{width: "20%", marginBlock: "2%"}}
                label={t("document_number")}
                variant={"outlined"}
                size={"small"}
                value={documentNumber}
                required
                error={!documentNumber}
                onChange={handleDocumentNumberChange}
            />
            <DateField
                style={{margin: "auto", width: "20%"}}
                label={t("date")}
                value={date}
                onChange={handleDateChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
            />
            <div style={{width: "100%", marginTop: "2%", display: "flex"}}>
                <Typography align={"center"} style={{width: "50%", margin: "auto"}}
                            variant={"caption"}>{t("arrivals_instructions")}</Typography>
            </div>
            <div style={{width: "100%", marginBottom: "3%", display: "flex"}}>
                <TextField
                    style={{width: "20%", margin: "auto", marginBlock: "2%"}}
                    label={t("barcode")}
                    variant="outlined"
                    margin="dense"
                    value={barcode}
                    defaultValue={barcode}
                    onChange={handleBarcodeChange}
                    // onPaste={handleBarcodePaste}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </Card>
        {fetched ?
            <div style={{marginTop: "3%"}}>
                {loading ?

                    <LoadingComponent/>

                    :
                    <div>

                        {shipmentData.length > 0 || unknownBarcodes.length > 0 ?
                            <div>
                                <Table style={{width: "20%", margin: "auto"}}>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                                            {unknownBarcodes.length > 0 ?
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("unknown_parcels")}</Typography></TableCell>
                                                : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableRow>
                                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{shipmentData.length}</Typography></TableCell>
                                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{calcSumOfParcels()}</Typography></TableCell>
                                        {unknownBarcodes.length > 0 ?
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                    fontSize: 30
                                                }}>{unknownBarcodes.length}</Typography></TableCell>
                                            : null}
                                    </TableRow>
                                </Table>
                                <div style={{paddingBottom: "5%"}}>
                                    <Table style={{width: "100%", margin: "auto", marginBlock: "5%"}}>
                                        <TableHead>
                                            <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    <Typography variant={"button"} style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                    }}>{t("shipment_details")}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    <Typography variant={"button"} style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                    }}> {t("parcels")}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "40%"}}>
                                                    <Typography variant={"button"} style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                    }}> {t("cods")}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {shipmentData.map((data, index) => {
                                            return <TableRow>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("destination")} : {data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    {data.parcels.map(parcel => {
                                                        return <div style={{display: "flex", alignItems: "center"}}>
                                                            <Typography
                                                                color={parcel.checked ? "green" : "darkgrey"}>{parcel.parcel.barcode + (parcel.parcel.customerBarcode ? " - " + parcel.parcel.customerBarcode : null)}</Typography>
                                                            <IconButton
                                                                style={{display: parcel.checked ? null : "none"}}
                                                                color={'error'}
                                                                size={"small"}
                                                                onClick={() => unloadParcel(parcel)}><CancelRounded/></IconButton>
                                                        </div>
                                                    })}
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "40%"}}>
                                                    {data.cod ?
                                                        <div>
                                                            {renderCodEvent(data, index)}
                                                        </div>
                                                        :
                                                        <InfoChip>{t("no_cod")}</InfoChip>
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        })}
                                        {unknownBarcodes.length > 0 ?
                                            <TableRow>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("unknown_parcels")}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "30%"}}>
                                                    {unknownBarcodes.map(barcode => {
                                                        return <div style={{display: "flex", alignItems: "center"}}>
                                                            <Typography
                                                                color={"darkgrey"}>{barcode}</Typography>
                                                        </div>
                                                    })}
                                                </TableCell>

                                            </TableRow>
                                            : null}
                                    </Table>
                                    {/*<Table>*/}
                                    {/*    <TableRow>*/}
                                    {/*        <TableCell style={{border: "2px solid", width: "50%"}}>*/}
                                    {/*            <Typography*/}
                                    {/*                variant={"subtitle2"}>{t("unknown_parcels")}</Typography>*/}
                                    {/*        </TableCell>*/}
                                    {/*        <TableCell style={{border: "2px solid", width: "50%"}}>*/}
                                    {/*            {unknownBarcodes.map(barcode => {*/}
                                    {/*                return <div style={{display: "flex", alignItems: "center"}}>*/}
                                    {/*                    <Typography*/}
                                    {/*                        color={"darkgrey"}>{barcode}</Typography>*/}
                                    {/*                </div>*/}
                                    {/*            })}*/}
                                    {/*        </TableCell>*/}

                                    {/*    </TableRow>*/}
                                    {/*</Table>*/}
                                </div>
                            </div>
                            :
                            <InfoChip>{t("no_parcels")}</InfoChip>}

                    </div>
                }
                <div style={{width: "100%", display: "flex"}}>
                    <Button onClick={confirmWarehouseCollection}
                            disabled={!warehouse || !documentType || referenceName === '' || documentNumber === '' || barcodes.length < 1 || !allCodsInCollection()}
                            style={{
                                width: "fit-content",
                                margin: "auto",
                                marginBlock: "2%",
                                backgroundColor: !warehouse || !documentType || referenceName === '' || documentNumber === '' || barcodes.length < 1 ? "grey" : theme.palette.primary.main,
                                color: "white",
                            }} endIcon={<SaveOutlined/>}>{t("confirm")}</Button>
                </div>
            </div>
            :
            null
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>

}

export default WarehouseCollectionPage;