import React, {useEffect, useState} from "react";
import BookingQueueService from "./BookingQueueService";
import {Button, MenuItem, Select, Table, TablePagination, TextField, useTheme} from "@material-ui/core";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {
    Card,
    DialogTitle,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {PrivateRoute} from "../routes/PrivateRoute";
import {Switch} from "react-router-dom";
import BookingQueueRecord from "./BookingQueueRecord";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {compareAsc, format} from "date-fns";
import itLocale from "date-fns/locale/it";
import ContractService from "../accounts/contracts/ContractService";
import AccountService from "../accounts/AccountService";
import {Autocomplete} from "@material-ui/lab";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))


const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    container: {},
    button: {
        marginTop: "3%",
        marginBottom: "3%",

    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    }


});

function BookingQueueTable(props) {

    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [refresh, setRefresh] = useState(false);

    const [fetching, setFetching] = useState(true);

    //filters
    const [isInError, setInError] = useState(false);
    const [shipmentId, setShipmentId] = useState('');
    const [shipmentIds, setShipmentIds] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);


    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");


    const classes = useStyles();
    const {t} = useTranslation();

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {

            })
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = account.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }

    function handleFromDateChange(event) {
        if (compareAsc(event, toDate) === 1) {
            setFromDate(event);
            setToDate(null);
        } else {
            setFromDate(event)
        }
    }

    function handleToDateChange(event) {
        if (compareAsc(fromDate, event) === 1) {
            setFromDate(null);
            setToDate(event);
        } else {
            setToDate(event);
        }
    }

    function handleContractChange(event, contract){
        setContract(contract);
    }

    function handleAccountChange(event, account){
        setAccount(account);
        setContract(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function fetchRecords() {
        setFetching(true);
        let filters = {};


        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;
        filters.shipmentIds = [];

        filters.inError = isInError;
        if (shipmentId !== 0) {
            filters.shipmentIds.push(shipmentId);
        }
        if (shipmentIds.length > 0) {
            for (let i = 0; i < shipmentIds.length; i++) {
                const item = shipmentIds[i];
                filters.shipmentIds.push(item);
            }
        }
        if (fromDate !== null) {
            filters.fromDate = format(fromDate, "yyyy-MM-dd");

        }
        if (toDate !== null) {
            filters.toDate = format(toDate, "yyyy-MM-dd");
        }

        if(account){
            filters.accountId = account.id;
        }

        if(contract){
            filters.contractId = contract.id;
        }


        let bookingQueueService = new BookingQueueService();
        bookingQueueService.getBookingQueueRecords(filters)
            .then(data => {
                setRecords(data.records);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                    setFetching(false);
                }
            )
    }

    function handleShipmentIdChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);
    }

    function clearFilters() {
        setShipmentId('');
        setFromDate(null);
        setToDate(null);
        setInError(false);
        setShipmentIds([]);
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }

    function refreshCallback(){
        setRefresh(!refresh);
    }


    useEffect(() => {

        fetchRecords();

    }, [rowsPerPage, page, refresh])

    return <>

        <Switch>
            <PrivateRoute exact path={"/booking-queue"}>

                <Card elevation={1} defaultExpanded={true}
                      style={{width: "60%", margin: "auto", marginBottom: "2%"}}>
                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                        <Typography variant={"button"}>{t("filters")}</Typography>
                    </DialogTitle>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <TextField
                            className={classes.filter}
                            label={t("shipment_id")}
                            value={shipmentId}
                            onChange={handleShipmentIdChange}
                            margin={"dense"}
                            variant={"outlined"}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            id="account"
                            options={accounts}
                            // className={classes.filter}
                            style={{width: "35%", margin: "auto", marginBlock: "3%"}}
                            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                            value={account}
                            defaultValue={account}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchAccounts}
                            onChange={handleAccountChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("account")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={account}
                                    defaultValue={account}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="contract"
                            size="small"
                            style={{width: "35%", margin: "auto", marginBlock: "3%"}}
                            options={contracts}
                            disabled={!account}
                            getOptionLabel={option => option.name}
                            value={contract}
                            defaultValue={contract}
                            noOptionsText={t("no_options")}
                            onOpen={fetchContracts}
                            onChange={handleContractChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("contract")}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                            <Grid container justify="space-around">
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    inputVariant="outlined"
                                    label={t("from_date")}
                                    clearable
                                    disableFuture
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    label={t("to_date")}
                                    inputVariant="outlined"
                                    clearable
                                    disableFuture
                                    value={toDate}
                                    onChange={handleToDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                    </div>
                    <div style={{textAlign: 'center'}}>
                        <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
                    </div>
                </Card>

                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>

                        {(fetching) ?
                            <Table>
                                <LoadingComponent/>
                            </Table>

                            :
                            <>

                                {records.length > 0 ?
                                    <div>


                                        <Table stickyHeader aria-label="sticky table">

                                            <TableHead>
                                                <TableRow>

                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("shipment_id")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("booking_date")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("time_range")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={"inherit"}>  {t("last_try")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("error_count")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("last_error_message")} </Typography>
                                                    </TableCell>
                                                    <TableCell style={{width: "10%"}}>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {records.map((record, index) => {
                                                    return <BookingQueueRecord refreshCallback={refreshCallback} record={record}/>
                                                })}
                                            </TableBody>

                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={-1}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            // SelectProps={{
                                            //     inputProps: {'aria-label': 'rows per page'},
                                            //     variant: "outlined"
                                            //
                                            // }}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                variant: "outlined",
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                classes: {paper: classes.menuPaper}
                                            }}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return t("page")
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />

                                    </div>
                                    :
                                    <div style={{height: 100}}>
                                        <Typography style={{marginTop: "5%"}}>{t("no_records_found")}</Typography>
                                    </div>
                                }
                            </>
                        }

                    </TableContainer>

                </Paper>
            </PrivateRoute>
        </Switch>

    </>


}

export default BookingQueueTable;