import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Card,
    Checkbox,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Tab, TableCell, TableHead,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {Cancel} from "@material-ui/icons";
import {Button, Table, TableBody} from "@material-ui/core";
import RateServiceZoneForm from "./RateServiceZoneForm";
import RateServiceRouteForm from "./RateServiceRouteForm";
import RateServiceWeightModelSlot from "./RateServiceWeightModelSlot";
import RateServiceParcelCategoryModelSlot from "./RateServiceParcelCategoryModelSlot";
import {
    AddCircleOutlineOutlined,
    ArrowCircleDown,
    ArrowCircleUp,
    RemoveCircleOutlineOutlined
} from "@mui/icons-material";
import {makeStyles} from "@material-ui/core/styles";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import VehicleService from "../vehicles/VehicleService";
import RateServiceRouteModelVehicleType from "./RateServiceRouteModelVehicleType";
import RateServiceParcelWeightModelSlot from "./RateServiceParcelWeightModelSlot";
import theme from "../theme/theme";

const useStyle = makeStyles(() => ({
        cards: {
            margin: "auto",
            marginTop: "3%",
            width: "100%"
        },
        radioButtons: {
            marginLeft: "2%"
        },
        textField: {
            marginRight: "2%",
            marginBottom: "2%",
            width: "30%"
        }

    }
));

export default function RateServiceForm(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.rateService);
    const [tabValue, setTabValue] = useState(props.rateService.serviceCode === "HANDLING" ? "2" : "1");
    const [vehicleTypes, setVehicleTypes] = useState([]);

    const classes = useStyle();

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    function addZone() {
        let newZones;
        if (state.zones) {
            newZones = [...state.zones];
        } else {
            newZones = [];
        }

        const newZone = {};

        newZones.push(newZone);
        const newState = {...state, zones: newZones};
        setNewState(newState);
    }

    function addRoute() {
        let newRoutes;
        if (state.routes) {
            newRoutes = [...state.routes];
        } else {
            newRoutes = [];
        }

        const newRoute = {};
        newRoutes.push(newRoute);

        const newState = {...state, routes: newRoutes};
        setNewState(newState);
    }

    function initZonesAndRoutes() {
        if (!state?.zones?.length > 0) {
            addZone();
        }
        if (!state?.routes?.length > 0) {
            addRoute();
        }
    }

    async function fetchVehicleTypes() {
        const vehicleService = new VehicleService();
        const vehicleTypes = await vehicleService.getVehicleTypes();

        setVehicleTypes(vehicleTypes);
    }

    function removeZone() {
        if (state.zones?.length > 1) {
            let newZones = state.zones;
            newZones.splice(state.zones.length - 1, 1);

            const newState = {...state, zones: newZones};
            setNewState(newState);
        }
    }

    function removeRoute() {
        if (state.routes?.length > 1) {
            let newRoutes = state.routes;
            newRoutes.splice(state.routes.length - 1, 1);

            const newState = {...state, routes: newRoutes};
            setNewState(newState);
        }
    }

    function onRateServiceZoneChange(index, zone) {
        let newZones = [...state.zones];
        newZones[index] = zone;

        const newState = {...state, zones: newZones};
        setNewState(newState);
    }

    function onRateServiceRouteChange(index, route) {
        let newRoutes = [...state.routes];
        newRoutes[index] = route;

        const newState = {...state, routes: newRoutes};
        setNewState(newState);
    }

    function handleCodRadioChange(event, selectedValue) {
        const newState = {...state, codPercentage: null};
        if ("codPercentage" === selectedValue) {
            newState.codPercentage = {};
        }

        setNewState(newState);
    }

    function handleCodVariationRadioChange(event, selectedValue) {
        const newState = {...state, codVariationFee: null};
        if ("codVariationFee" === selectedValue) {
            newState.codVariationFee = {};
        }

        setNewState(newState);
    }

    function handleConsolidationRadioChange(event, selectedValue) {
        const newState = {...state, consolidation: "NO_CONSOLIDATION"};
        newState.consolidation = selectedValue;
        setNewState(newState);
    }

    function cancelCod() {
        setNewState({...state, codPercentage: null});
    }

    function cancelCodVariation() {
        setNewState({...state, codVariationFee: null});
    }

    function cancelInsurance() {
        //TODO quando aggiungiamo nuovi modelli toglierli tutti
        setNewState({...state, insurancePercentage: null});
    }

    function cancelWarehouseStorage() {
        setNewState({...state, warehouseStorageDaily: null});
    }

    function cancelBooking() {
        setNewState({...state, bookingChargeFixed: null});
    }

    function cancelFuelSurcharge() {
        setNewState({...state, fuelSurcharge: null})
    }

    function cancelModel() {
        setNewState({...state, weightModel: null})
    }

    function onChangeCodPercentagePercentage(event, value) {
        const newCodPercentage = {...state?.codPercentage, percentage: value};
        const newState = {...state, codPercentage: newCodPercentage};

        setNewState(newState);
    }

    function onChangeCodPercentageMinimumFee(event, value) {
        const newCodPercentage = {...state?.codPercentage, minimumFee: value};
        const newState = {...state, codPercentage: newCodPercentage};

        setNewState(newState);
    }

    function onChangeCodVariationFee(event, value) {
        const newCodVariation = {...state?.codVariationFee, fee: value};
        const newState = {...state, codVariationFee: newCodVariation};

        setNewState(newState);
    }


    function handleInsuranceRadioChange(event, selectedValue) {
        const newState = {...state, insurancePercentage: null};
        if ("insurancePercentage" === selectedValue) {
            newState.insurancePercentage = {};
        }

        setNewState(newState);
    }

    function onChangeInsurancePercentagePercentage(event, value) {
        const newInsurancePercentage = {...state?.insurancePercentage, percentage: value};
        const newState = {...state, insurancePercentage: newInsurancePercentage};

        setNewState(newState);
    }

    function onChangeInsurancePercentageMinimumFee(event) {
        const newInsurancePercentage = {...state?.insurancePercentage, minimumFee: event.target.value};
        const newState = {...state, insurancePercentage: newInsurancePercentage};

        setNewState(newState);
    }

    function handleWarehouseStorageRadioChange(event, selectedValue) {
        const newState = {...state, warehouseStorageDaily: null};
        if ("warehouseStorageDaily" === selectedValue) {
            newState.warehouseStorageDaily = {};
        }

        setNewState(newState);
    }

    function onChangeWarehouseStorageDailyDailyCost(event) {
        const newWarehouseStorage = {...state?.warehouseStorageDaily, dailyCost: event.target.value};
        const newState = {...state, warehouseStorageDaily: newWarehouseStorage};

        setNewState(newState);
    }

    function onChangeWarehouseStorageDailyFixedCost(event) {
        const newWarehouseStorage = {...state?.warehouseStorageDaily, fixedCost: event.target.value};
        const newState = {...state, warehouseStorageDaily: newWarehouseStorage};

        setNewState(newState);
    }

    function onChangeWarehouseStorageDailyFixedCostDaysNumber(event) {
        const newWarehouseStorage = {...state?.warehouseStorageDaily, fixedCostDaysNumber: event.target.value};
        const newState = {...state, warehouseStorageDaily: newWarehouseStorage};

        setNewState(newState);
    }

    function handleBookingRadioChange(event, selectedValue) {
        const newState = {...state, bookingChargeFixed: null};
        if ("bookingChargeFixed" === selectedValue) {
            newState.bookingChargeFixed = {};
        }

        setNewState(newState);
    }

    function onChangeBookingChargeFixedFixedFee(event) {
        const newBookingChargeFixed = {...state?.bookingChargeFixed, fixedFee: event.target.value};
        const newState = {...state, bookingChargeFixed: newBookingChargeFixed};

        setNewState(newState);
    }

    function handleFuelSurchargeRadioChange(event, selectedValue) {
        const newState = {...state, fuelSurcharge: null};
        if ("fuelSurcharge" === selectedValue) {
            newState.fuelSurcharge = {};
        }

        setNewState(newState);
    }

    function onChangeFuelSurchargePercentage(event) {
        const newFuelSurcharge = {...state?.fuelSurcharge, percentage: event.target.value};
        const newState = {...state, fuelSurcharge: newFuelSurcharge};

        setNewState(newState);
    }

    function onChangeFuelSurchargeFeePerKilos(event) {
        const newFuelSurcharge = {...state?.fuelSurcharge, feePerKilos: event.target.value};
        const newState = {...state, fuelSurcharge: newFuelSurcharge};

        setNewState(newState);
    }

    function handleModelRadioChange(event, selectedValue) {
        const newState = {...state};
        newState.weightModel = null;
        newState.parcelWeightModel = null;
        newState.parcelCategoryModel = null;
        newState.fixedAmountModel = null;
        newState.routeModel = null;

        if ("weightModel" === selectedValue) {
            newState.weightModel = {slots: [{}]};
        } else if ("parcelWeightModel" === selectedValue) {
            newState.parcelWeightModel = {slots: [{}]};
        } else if ("parcelCategoryModel" === selectedValue) {
            newState.parcelCategoryModel = {slots: [{}]};
        } else if ("fixedAmountModel" === selectedValue) {
            newState.fixedAmountModel = {};
        } else if ("routeModel" === selectedValue) {
            newState.routeModel = {};
        }

        setNewState(newState);
    }

    /** WeightModel **/


    /** ParcelWeightModel **/
    function onChangeParcelWeightModelFPNewAttempt(event, value){
        const newWeightModel = {...state?.parcelWeightModel, freightPercentageNewAttempt: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelFixedFeeNewAttempt(event, value){
        const newWeightModel = {...state?.parcelWeightModel, fixedFeeNewAttempt: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelFPAddressChange(event, value){
        const newWeightModel = {...state?.parcelWeightModel, freightPercentageAddressChange: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelFixedFeeAddressChange(event, value){
        const newWeightModel = {...state?.parcelWeightModel, fixedFeeAddressChange: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelFPReturnToSender(event, value){
        const newWeightModel = {...state?.parcelWeightModel, freightPercentageReturnToSender: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelFixedFeeReturnToSender(event, value){
        const newWeightModel = {...state?.parcelWeightModel, fixedFeeReturnToSender: value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    /** ParcelCategoryModel **/
    function onChangeParcelCategoryModelFPNewAttempt(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, freightPercentageNewAttempt: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    function onChangeParcelCategoryModelFixedFeeNewAttempt(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, fixedFeeNewAttempt: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    function onChangeParcelCategoryModelFPAddressChange(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, freightPercentageAddressChange: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    function onChangeParcelCategoryModelFixedFeeAddressChange(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, fixedFeeAddressChange: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    function onChangeParcelCategoryModelFPReturnToSender(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, freightPercentageReturnToSender: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    function onChangeParcelCategoryModelFixedFeeReturnToSender(event, value){
        const newCategoryModel = {...state?.parcelCategoryModel, fixedFeeReturnToSender: value};
        const newState = {...state, parcelCategoryModel: newCategoryModel};

        setNewState(newState);
    }

    /** FixedAmountModel **/
    function onChangeFixedAmountModelFPNewAttempt(event, value){
        const newModel = {...state?.fixedAmountModel, freightPercentageNewAttempt: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelFixedFeeNewAttempt(event, value){
        const newModel = {...state?.fixedAmountModel, fixedFeeNewAttempt: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelFPAddressChange(event, value){
        const newModel = {...state?.fixedAmountModel, freightPercentageAddressChange: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelFixedFeeAddressChange(event, value){
        const newModel = {...state?.fixedAmountModel, fixedFeeAddressChange: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelFPReturnToSender(event, value){
        const newModel = {...state?.fixedAmountModel, freightPercentageReturnToSender: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelFixedFeeReturnToSender(event, value){
        const newModel = {...state?.fixedAmountModel, fixedFeeReturnToSender: value};
        const newState = {...state, fixedAmountModel: newModel};

        setNewState(newState);
    }

    function onChangeWeightModelCpv(event) {
        const newWeightModel = {...state?.weightModel, cpv: event.target.value};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeWeightModelCpvMinimumRealWeight(event) {
        const newWeightModel = {...state?.weightModel, cpvMinimumRealWeight: event.target.value};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeWeightModelOverBoundWeightSize(event) {
        const newWeightModel = {...state?.weightModel, overBoundWeightSize: event.target.value};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeWeightModelOverBoundWeightFee(event) {
        const newWeightModel = {...state?.weightModel, overBoundWeightFee: event.target.value};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function addWeightModelSlot() {
        let newSlots;
        if (state.weightModel?.slots) {
            newSlots = [...state.weightModel.slots];
        } else {
            newSlots = [];
        }

        const newSlot = {};
        newSlots.push(newSlot);

        const newWeightModel = {...state?.weightModel, slots: newSlots};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function removeWeightModelSlot() {
        if (state.weightModel.slots.length > 1) {
            let newSlots = state.weightModel.slots;
            newSlots.splice(state.weightModel.slots.length - 1, 1);

            const newWeightModel = {...state?.weightModel, slots: newSlots};
            const newState = {...state, weightModel: newWeightModel};

            setNewState(newState);
        }
    }

    function onRateServiceWeightModelSlotChange(index, slot) {
        let newSlots = [...state.weightModel.slots];
        newSlots[index] = slot;

        const newWeightModel = {...state?.weightModel, slots: newSlots};
        const newState = {...state, weightModel: newWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelCpv(event) {
        const newParcelWeightModel = {...state?.parcelWeightModel, cpv: event.target.value};
        const newState = {...state, parcelWeightModel: newParcelWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelCpvMinimumRealWeight(event) {
        const newParcelWeightModel = {...state?.parcelWeightModel, cpvMinimumRealWeight: event.target.value};
        const newState = {...state, parcelWeightModel: newParcelWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelOverBoundWeightSize(event) {
        const newParcelWeightModel = {...state?.parcelWeightModel, overBoundWeightSize: event.target.value};
        const newState = {...state, parcelWeightModel: newParcelWeightModel};

        setNewState(newState);
    }

    function onChangeParcelWeightModelOverBoundWeightFee(event) {
        const newWeightModel = {...state?.parcelWeightModel, overBoundWeightFee: event.target.value};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function addParcelWeightModelSlot() {
        let newSlots;
        if (state.parcelWeightModel?.slots) {
            newSlots = [...state.parcelWeightModel.slots];
        } else {
            newSlots = [];
        }

        const newSlot = {};
        newSlots.push(newSlot);

        const newWeightModel = {...state?.parcelWeightModel, slots: newSlots};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function removeParcelWeightModelSlot() {
        if (state.parcelWeightModel.slots.length > 1) {
            let newSlots = state.parcelWeightModel.slots;
            newSlots.splice(state.parcelWeightModel.slots.length - 1, 1);

            const newWeightModel = {...state?.parcelWeightModel, slots: newSlots};
            const newState = {...state, parcelWeightModel: newWeightModel};

            setNewState(newState);
        }
    }

    function onRateServiceParcelWeightModelSlotChange(index, slot) {
        let newSlots = [...state.parcelWeightModel.slots];
        newSlots[index] = slot;

        const newWeightModel = {...state?.parcelWeightModel, slots: newSlots};
        const newState = {...state, parcelWeightModel: newWeightModel};

        setNewState(newState);
    }

    function addParcelCategoryModelSlot() {
        let newSlots;
        if (state.parcelCategoryModel?.slots) {
            newSlots = [...state.parcelCategoryModel.slots];
        } else {
            newSlots = [];
        }

        const newSlot = {};
        newSlots.push(newSlot);

        const newParcelCategoryModel = {...state?.parcelCategoryModel, slots: newSlots};
        const newState = {...state, parcelCategoryModel: newParcelCategoryModel};

        setNewState(newState);
    }

    function removeParcelCategoryModelSlot() {
        if (state.parcelCategoryModel.slots.length > 1) {
            let newSlots = state.parcelCategoryModel.slots;
            newSlots.splice(state.parcelCategoryModel.slots.length - 1, 1);

            const newWeightModel = {...state?.parcelCategoryModel, slots: newSlots};
            const newState = {...state, parcelCategoryModel: newWeightModel};

            setNewState(newState);
        }
    }

    function onRateServiceParcelCategoryModelSlotChange(index, slot) {
        let newSlots = [...state.parcelCategoryModel.slots];
        newSlots[index] = slot;

        const newParcelCategoryModel = {...state?.parcelCategoryModel, slots: newSlots};
        const newState = {...state, parcelCategoryModel: newParcelCategoryModel};

        setNewState(newState);
    }

    useEffect(() => {
        initZonesAndRoutes();
        fetchVehicleTypes();
    }, [])

    function renderFormWeightModel() {
        return <div style={{margin: "2%", border: "1px solid", borderRadius: "15px",}}>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="cpv"
                    label={t("cpv")}
                    type="text"
                    value={state.weightModel.cpv}
                    onChange={onChangeWeightModelCpv}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="cpvMinimumRealWeight"
                    label={t("cpv_minimum_real_weight")}
                    type="text"
                    value={state.weightModel.cpvMinimumRealWeight}
                    onChange={onChangeWeightModelCpvMinimumRealWeight}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="overBoundWeightSize"
                    label={t("over_bound_weight_size")}
                    type="text"
                    value={state.weightModel.overBoundWeightSize}
                    onChange={onChangeWeightModelOverBoundWeightSize}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="overBoundWeightFee"
                    label={t("over_bound_weight_fee")}
                    type="text"
                    value={state.weightModel.overBoundWeightFee}
                    onChange={onChangeWeightModelOverBoundWeightFee}
                    style={{margin: "auto"}}
                />


                {/*<Table stickyHeader className={classes.table}>*/}

            </div>
            <div style={{display: "flex", width: "90%", margin: "auto"}}>
                <Button
                    startIcon={<RemoveCircleOutlineOutlined/>}
                    disabled={state?.weightModel?.slots?.length < 2}
                    onClick={removeWeightModelSlot}>
                    {t("remove_slot")}
                </Button>

                <Button
                    startIcon={<AddCircleOutlineOutlined/>}
                    onClick={addWeightModelSlot}>
                    {t("add_slot")}
                </Button>
            </div>
            <div>
                <Table stickyHeader>
                    {/*<TableHead>*/}
                    {/*    <TableCell>{t("toWeigth")}</TableCell>*/}
                    {/*    <TableCell>{t("fee")}</TableCell>*/}
                    {/*    <TableCell/>*/}
                    {/*</TableHead>*/}

                    <TableBody>
                        {state.weightModel.slots.map(function (slot, index) {
                            return <div>
                                <DialogTitle>{t("weight_slot") + " #" + (index + 1)}</DialogTitle>
                                <RateServiceWeightModelSlot
                                    key={index}
                                    slot={slot}
                                    onStateChange={(newSlot) => onRateServiceWeightModelSlotChange(index, newSlot)}/>
                            </div>

                        })}
                    </TableBody>
                </Table>
            </div>
        </div>;
    }

    function renderFormParcelWeightModel() {
        return <div style={{margin: "2%", border: "1px solid", borderRadius: "15px",}}>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="cpv"
                    label={t("cpv")}
                    type="text"
                    value={state.parcelWeightModel.cpv}
                    onChange={onChangeParcelWeightModelCpv}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="cpvMinimumRealWeight"
                    label={t("cpv_minimum_real_weight")}
                    type="text"
                    value={state.parcelWeightModel.cpvMinimumRealWeight}
                    onChange={onChangeParcelWeightModelCpvMinimumRealWeight}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="overBoundWeightSize"
                    label={t("over_bound_weight_size")}
                    type="text"
                    value={state.parcelWeightModel.overBoundWeightSize}
                    onChange={onChangeParcelWeightModelOverBoundWeightSize}
                    style={{margin: "auto"}}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="overBoundWeightFee"
                    label={t("over_bound_weight_fee")}
                    type="text"
                    value={state.parcelWeightModel.overBoundWeightFee}
                    onChange={onChangeParcelWeightModelOverBoundWeightFee}
                    style={{margin: "auto"}}
                />


                {/*<Table stickyHeader className={classes.table}>*/}

            </div>
            <div style={{display: "flex", width: "90%", margin: "auto"}}>
                <Button
                    startIcon={<RemoveCircleOutlineOutlined/>}
                    disabled={state?.parcelWeightModel?.slots?.length < 2}
                    onClick={removeParcelWeightModelSlot}>
                    {t("remove_slot")}
                </Button>

                <Button
                    startIcon={<AddCircleOutlineOutlined/>}
                    onClick={addParcelWeightModelSlot}>
                    {t("add_slot")}
                </Button>
            </div>
            <div>
                <Table stickyHeader>
                    {/*<TableHead>*/}
                    {/*    <TableCell>{t("toWeigth")}</TableCell>*/}
                    {/*    <TableCell>{t("fee")}</TableCell>*/}
                    {/*    <TableCell/>*/}
                    {/*</TableHead>*/}

                    <TableBody>
                        {state.parcelWeightModel.slots.map(function (slot, index) {
                            return <div>
                                <DialogTitle>{t("weight_slot") + " #" + (index + 1)}</DialogTitle>
                                <RateServiceParcelWeightModelSlot
                                    key={index}
                                    slot={slot}
                                    onStateChange={(newSlot) => onRateServiceParcelWeightModelSlotChange(index, newSlot)}/>
                            </div>

                        })}
                    </TableBody>
                </Table>
            </div>
        </div>;
    }

    function renderFormParcelCategoryModel() {
        return <div style={{margin: "2%", border: "1px solid", borderRadius: "15px",}}>
            <div style={{display: "flex", width: "90%", margin: "auto", marginBlock: "2%"}}>
                <Button
                    startIcon={<RemoveCircleOutlineOutlined/>}
                    disabled={state?.parcelCategoryModel?.slots?.length < 2}
                    onClick={removeParcelCategoryModelSlot}>
                    {t("remove_slot")}
                </Button>

                <Button
                    startIcon={<AddCircleOutlineOutlined/>}
                    onClick={addParcelCategoryModelSlot}>
                    {t("add_slot")}
                </Button>
            </div>
            <div>
                <Table stickyHeader>
                    <TableBody>
                        {state.parcelCategoryModel.slots.map(function (slot, index) {
                            return <div>
                                <DialogTitle>{t("parcel_category") + " #" + (index + 1)}</DialogTitle>
                                <RateServiceParcelCategoryModelSlot
                                    key={index}
                                    slot={slot}
                                    onStateChange={(newSlot) => onRateServiceParcelCategoryModelSlotChange(index, newSlot)}/>
                            </div>
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>;
    }

    function onChangeFixedAmountModelAmount(event) {
        const newFixedAmountModel = {...state?.fixedAmountModel, amount: event.target.value};
        const newState = {...state, fixedAmountModel: newFixedAmountModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelGroupEntities(event) {
        const newFixedAmountModel = {...state?.fixedAmountModel, groupEntities: event.target.checked};
        const newState = {...state, fixedAmountModel: newFixedAmountModel};

        setNewState(newState);
    }

    function onChangeFixedAmountModelZeroPickupIfDeliverySameDay(event) {
        const newFixedAmountModel = {...state?.fixedAmountModel, zeroPickupIfDeliverySameDay: event.target.checked};
        const newState = {...state, fixedAmountModel: newFixedAmountModel};

        setNewState(newState);
    }

    function renderFormFixedAmountModel() {
        return <div style={{margin: "2%", border: "1px solid", borderRadius: "15px"}}>
            <div style={{margin: "2%"}}>
                <div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        name="fixedAmountModelAmount"
                        label={t("amount")}
                        type="text"
                        value={state?.fixedAmountModel?.amount}
                        onChange={onChangeFixedAmountModelAmount}
                        autoComplete={t("amount")}/>
                </div>
                <div>
                    <FormControlLabel control={<Checkbox checked={state?.fixedAmountModel?.groupEntities}
                                                         onChange={onChangeFixedAmountModelGroupEntities}/>}
                                      label={t("group_entities_or_tax_shipment_by_shipment")}/>
                </div>
                <div>
                    <FormControlLabel control={<Checkbox checked={state?.fixedAmountModel?.zeroPickupIfDeliverySameDay}
                                                         onChange={onChangeFixedAmountModelZeroPickupIfDeliverySameDay}/>}
                                      label={t("no_pickup_if_delivery_same_day")}/>
                </div>
            </div>
        </div>;
    }

    function addRouteModelVehicleType() {
        let newVehicleTypes;
        if (state?.routeModel?.vehicleTypes) {
            newVehicleTypes = [...state.routeModel.vehicleTypes];
        } else {
            newVehicleTypes = [];
        }

        const newVehicleType = {};
        newVehicleTypes.push(newVehicleType);

        const newRouteModel = {...state?.routeModel, vehicleTypes: newVehicleTypes};
        const newState = {...state, routeModel: newRouteModel};
        setNewState(newState);
    }

    function removeRouteModelVehicleTypes() {
        if (state?.routeModel?.vehicleTypes?.length > 1) {
            let newVehicleTypes = state.routeModel.vehicleTypes;
            newVehicleTypes.splice(state.routeModel.vehicleTypes.length - 1, 1);

            const newRouteModel = {...state?.routeModel, vehicleTypes: newVehicleTypes};
            const newState = {...state, routeModel: newRouteModel};
            setNewState(newState);
        }
    }

    function onRateServiceRouteModelVehicleTypeChange(index, vehicleType) {
        let newVehicleTypes = [...state.routeModel.vehicleTypes];
        newVehicleTypes[index] = vehicleType;

        const newRouteModel = {...state?.routeModel, vehicleTypes: newVehicleTypes};
        const newState = {...state, routeModel: newRouteModel};
        setNewState(newState);
    }

    function renderFormRouteModel() {
        return <div>
            <IconButton disabled={state?.routeModel?.vehicleTypes?.length < 2} onClick={removeRouteModelVehicleTypes}>
                <RemoveCircleOutlineOutlined/>
            </IconButton>

            <IconButton onClick={addRouteModelVehicleType}>
                <AddCircleOutlineOutlined/>
            </IconButton>
            <Table stickyHeader>
                <TableBody>
                    {state?.routeModel?.vehicleTypes?.map(function (vehicleType, index) {
                        return <RateServiceRouteModelVehicleType key={index} vehicleTypes={vehicleTypes}
                                                                 vehicleType={vehicleType}
                                                                 onStateChange={(newVehicleType) => onRateServiceRouteModelVehicleTypeChange(index, newVehicleType)}/>
                    })}
                </TableBody>
            </Table>
        </div>
    }

    return <div style={{
        display: "flex",
        border: "solid 2px",
        borderRadius: "15px",
        borderColor: theme.palette.primary.main,
        marginBottom: "2%"
    }}>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "3%"
        }}>
            <div style={{display: 'block', textAlign: "center"}}>
                <IconButton style={{margin: "auto"}} disabled={props.upDisabled}
                            onClick={() => props.swapIndexOrder(props.index, props.index - 1)}><ArrowCircleUp/></IconButton>
                <Typography variant={"button"}>{"#" + state.indexOrder}</Typography>
                <IconButton style={{margin: "auto"}} disabled={props.downDisabled}
                            onClick={() => props.swapIndexOrder(props.index, props.index + 1)}><ArrowCircleDown/></IconButton>
            </div>

        </div>
        <TabContext value={tabValue}>
            <Box sx={{width: '100%'}}>
                <Tabs onChange={(event, newValue) => {
                    setTabValue(newValue)
                }}>
                    {"HANDLING" !== state.serviceCode ?
                        <Tab value="1"
                             label={"HAULING" === state.serviceCode ? t("routes") : t("areas")}/>
                        :
                        null
                    }
                    <Tab value="2" label={t("values")}/>
                    {"HANDLING" !== state.serviceCode ?
                        <Tab value="3" label={t("options")}/>
                        :
                        null
                    }
                </Tabs>
                <TabPanel value="1">
                    {
                        "HAULING" === state.serviceCode ?
                            <div>
                                <IconButton disabled={state?.routes?.length < 2} onClick={removeRoute}>
                                    <RemoveCircleOutlineOutlined/>
                                </IconButton>

                                <IconButton onClick={addRoute}>
                                    <AddCircleOutlineOutlined/>
                                </IconButton>
                                {/*<Table stickyHeader className={classes.table}>*/}
                                <Table stickyHeader>
                                    {/*<TableHead>*/}
                                    {/*    <TableCell>{t("country")}</TableCell>*/}
                                    {/*    <TableCell>{t("administrative1")}</TableCell>*/}
                                    {/*    <TableCell>{t("administrative2")}</TableCell>*/}
                                    {/*    <TableCell>{t("administrative3")}</TableCell>*/}
                                    {/*    <TableCell>{t("city")}</TableCell>*/}
                                    {/*    <TableCell>{t("postalCode")}</TableCell>*/}
                                    {/*    <TableCell/>*/}
                                    {/*</TableHead>*/}

                                    <TableBody>
                                        {state?.routes?.map(function (route, index) {
                                            return <RateServiceRouteForm key={index} route={route}
                                                                         onStateChange={(newRoute) => onRateServiceRouteChange(index, newRoute)}/>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                            :
                            <div>
                                <IconButton disabled={!state.zones || state?.zones?.length < 2} onClick={removeZone}>
                                    <RemoveCircleOutlineOutlined/>
                                </IconButton>

                                <IconButton onClick={addZone}>
                                    <AddCircleOutlineOutlined/>
                                </IconButton>
                                {/*<Table stickyHeader className={classes.table}>*/}
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableCell>{t("contract")}</TableCell>
                                        <TableCell>{t("country")}</TableCell>
                                        <TableCell>{t("administrative1")}</TableCell>
                                        <TableCell>{t("administrative2")}</TableCell>
                                        <TableCell>{t("administrative3")}</TableCell>
                                        <TableCell>{t("city")}</TableCell>
                                        <TableCell>{t("postalCode")}</TableCell>
                                        <TableCell/>
                                    </TableHead>

                                    <TableBody>
                                        {state?.zones?.map(function (zone, index) {
                                            return <RateServiceZoneForm key={index} zone={zone}
                                                                        onStateChange={(newZone) => onRateServiceZoneChange(index, newZone)}/>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                    }

                </TabPanel>
                <TabPanel value="2">
                    {"HANDLING" !== state.serviceCode ?
                        <>
                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"}
                                                    style={{alignSelf: "center", margin: "2%"}}>{t("cod")}</Typography>
                                        <IconButton style={{display: !state.codPercentage ? "none" : ""}}
                                                    onClick={cancelCod}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons} onChange={handleCodRadioChange}
                                                defaultValue="codPercentage">
                                        <FormControlLabel checked={!!state.codPercentage}
                                                          value="codPercentage"
                                                          control={<Radio/>}
                                                          label={t("percentage")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.codPercentage ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>
                                            <CurrencyTextField
                                                style={{marginInline: "2%"}}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={t("percentage")}
                                                value={state.codPercentage.percentage}
                                                error={!state.codPercentage.percentage}
                                                textAlign={"left"}
                                                currencySymbol={"%"}
                                                minimumValue={0}
                                                outputFormat="string"
                                                digitGroupSeparator={"."}
                                                decimalCharacter={","}
                                                onChange={onChangeCodPercentagePercentage}
                                            />
                                            {/*<TextField*/}
                                            {/*    style={{marginInline: "2%"}}*/}
                                            {/*    variant="outlined"*/}
                                            {/*    margin="normal"*/}
                                            {/*    required*/}
                                            {/*    name="percentage"*/}
                                            {/*    label={t("percentage")}*/}
                                            {/*    type="text"*/}
                                            {/*    value={state.codPercentage.percentage}*/}
                                            {/*    error={!state.codPercentage.percentage}*/}
                                            {/*    onChange={onChangeCodPercentagePercentage}*/}
                                            {/*    autoComplete={t("percentage")}*/}
                                            {/*/>*/}
                                            <CurrencyTextField
                                                style={{marginInline: "2%"}}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={t("minimum_fee")}
                                                value={state.codPercentage.minimumFee}
                                                error={!state.codPercentage.minimumFee}
                                                textAlign={"left"}
                                                currencySymbol={"€"}
                                                decimalPlaces={2}
                                                minimumValue={0}
                                                outputFormat="string"
                                                digitGroupSeparator={"."}
                                                decimalCharacter={","}
                                                onChange={onChangeCodPercentageMinimumFee}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>

                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"}
                                                    style={{alignSelf: "center", margin: "2%"}}>{t("cod_variation_fee")}</Typography>
                                        <IconButton style={{display: !state.codVariationFee ? "none" : ""}}
                                                    onClick={cancelCodVariation}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons} onChange={handleCodVariationRadioChange}
                                                defaultValue="codVariationFee">
                                        <FormControlLabel checked={!!state.codVariationFee}
                                                          value="codVariationFee"
                                                          control={<Radio/>}
                                                          label={t("fee")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.codVariationFee ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>
                                            <CurrencyTextField
                                                style={{marginInline: "2%"}}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={t("fee")}
                                                value={state.codVariationFee.fee}
                                                error={!state.codVariationFee.fee}
                                                textAlign={"left"}
                                                currencySymbol={"€"}
                                                decimalPlaces={2}
                                                minimumValue={0}
                                                outputFormat="string"
                                                digitGroupSeparator={"."}
                                                decimalCharacter={","}
                                                onChange={onChangeCodVariationFee}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>

                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"}
                                                    style={{
                                                        alignSelf: "center",
                                                        margin: "2%"
                                                    }}>{t("insurance")}</Typography>
                                        <IconButton style={{display: !state.insurancePercentage ? "none" : ""}}
                                                    onClick={cancelInsurance}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons} onChange={handleInsuranceRadioChange}
                                                defaultValue="insurancePercentage">
                                        <FormControlLabel checked={!!state.insurancePercentage}
                                                          value="insurancePercentage"
                                                          control={<Radio/>}
                                                          label={t("percentage")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.insurancePercentage ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>

                                            <CurrencyTextField
                                                style={{marginInline: "2%"}}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={t("percentage")}
                                                value={state.insurancePercentage.percentage}
                                                error={!state.insurancePercentage.percentage}
                                                textAlign={"left"}
                                                currencySymbol={"%"}
                                                minimumValue={0}
                                                outputFormat="string"
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                onChange={onChangeInsurancePercentagePercentage}
                                            />
                                            {/*<TextField*/}
                                            {/*    variant="outlined"*/}
                                            {/*    margin="normal"*/}
                                            {/*    required*/}
                                            {/*    fullWidth*/}
                                            {/*    name="percentage"*/}
                                            {/*    label={t("percentage")}*/}
                                            {/*    type="text"*/}
                                            {/*    value={state.insurancePercentage.percentage}*/}
                                            {/*    onChange={onChangeInsurancePercentagePercentage}*/}
                                            {/*    autoComplete={t("percentage")}*/}
                                            {/*/>*/}
                                            <TextField
                                                style={{marginInline: "2%"}}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="minimumFee"
                                                label={t("minimum_fee")}
                                                type="text"
                                                value={state.insurancePercentage.minimumFee}
                                                error={!state.insurancePercentage.minimumFee}
                                                onChange={onChangeInsurancePercentageMinimumFee}
                                                autoComplete={t("minimum_fee")}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>

                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"} style={{
                                            alignSelf: "center",
                                            margin: "2%"
                                        }}>{t("warehouse_storage")}</Typography>
                                        <IconButton style={{display: !state.warehouseStorageDaily ? "none" : ""}}
                                                    onClick={cancelWarehouseStorage}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons}
                                                onChange={handleWarehouseStorageRadioChange}
                                                defaultValue="warehouseStorageDaily">
                                        <FormControlLabel checked={!!state.warehouseStorageDaily}
                                                          value="warehouseStorageDaily"
                                                          control={<Radio/>}
                                                          label={t("warehouse_storage_daily")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.warehouseStorageDaily ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>

                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="fixedCost"
                                                label={t("fixed_cost")}
                                                type="text"
                                                value={state.warehouseStorageDaily.fixedCost}
                                                onChange={onChangeWarehouseStorageDailyFixedCost}
                                                style={{marginInline: "2%"}}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="dailyCost"
                                                label={t("daily_cost")}
                                                type="text"
                                                value={state.warehouseStorageDaily.dailyCost}
                                                onChange={onChangeWarehouseStorageDailyDailyCost}
                                                style={{marginInline: "2%"}}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="fixedCostDaysNumber"
                                                label={t("fixed_cost_days_number")}
                                                type="text"
                                                value={state.warehouseStorageDaily.fixedCostDaysNumber}
                                                onChange={onChangeWarehouseStorageDailyFixedCostDaysNumber}
                                                style={{marginInline: "2%", width: "40%"}}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>

                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"}
                                                    style={{
                                                        alignSelf: "center",
                                                        margin: "2%"
                                                    }}>{t("booking")}</Typography>
                                        <IconButton style={{display: !state.bookingChargeFixed ? "none" : ""}}
                                                    onClick={cancelBooking}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons} onChange={handleBookingRadioChange}
                                                defaultValue="bookingChargeFixed">
                                        <FormControlLabel checked={!!state.bookingChargeFixed}
                                                          value="bookingChargeFixed"
                                                          control={<Radio/>}
                                                          label={t("booking_charge_fixed")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.bookingChargeFixed ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>

                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="fixedFee"
                                                label={t("fixed_fee")}
                                                type="text"
                                                value={state.bookingChargeFixed.fixedFee}
                                                onChange={onChangeBookingChargeFixedFixedFee}
                                                style={{marginInline: "2%"}}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>


                            <Card className={classes.cards}>
                                <FormControl style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <Typography variant={"button"} style={{
                                            alignSelf: "center",
                                            margin: "2%"
                                        }}>{t("fuel_surcharge")}</Typography>
                                        <IconButton style={{display: !state.fuelSurcharge ? "none" : ""}}
                                                    onClick={cancelFuelSurcharge}><Cancel/></IconButton>
                                    </div>
                                    <RadioGroup className={classes.radioButtons}
                                                onChange={handleFuelSurchargeRadioChange}
                                                defaultValue="fuelSurcharge">
                                        <FormControlLabel checked={!!state.fuelSurcharge}
                                                          value="fuelSurcharge"
                                                          control={<Radio/>}
                                                          label={t("fuel_surcharge")}/>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    !!state.fuelSurcharge ?
                                        <div style={{display: "flex", paddingBottom: "2%"}}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="percentage"
                                                label={t("percentage")}
                                                type="text"
                                                value={state.fuelSurcharge.percentage}
                                                onChange={onChangeFuelSurchargePercentage}
                                                style={{marginInline: "2%"}}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                name="feePerKilos"
                                                label={t("fee_per_kilos")}
                                                type="text"
                                                value={state.fuelSurcharge.feePerKilos}
                                                onChange={onChangeFuelSurchargeFeePerKilos}
                                                style={{marginInline: "2%"}}
                                            />
                                        </div>
                                        :
                                        <Paper/>
                                }
                            </Card>
                        </>
                        :
                        null
                    }
                    <Card className={classes.cards}>
                        <FormControl style={{width: "100%"}}>
                            <div style={{display: "flex"}}>
                                <Typography variant={"button"}
                                            style={{alignSelf: "center", margin: "2%"}}>{t("model")}</Typography>
                                <IconButton style={{display: !state.weightModel ? "none" : ""}}
                                            onClick={cancelModel}><Cancel/></IconButton>
                            </div>
                            <RadioGroup className={classes.radioButtons} onChange={handleModelRadioChange}
                                        defaultValue="parcelCategoryModel">
                                <FormControlLabel checked={!!state.parcelCategoryModel}
                                                  value="parcelCategoryModel"
                                                  control={<Radio/>}
                                                  label={t("parcel_category_model")}/>
                                {"HANDLING" !== state.serviceCode ?
                                    <>
                                        <FormControlLabel checked={!!state.weightModel}
                                                          value="weightModel"
                                                          control={<Radio/>}
                                                          label={t("weight_model")}/>
                                        <FormControlLabel
                                            checked={!!state.parcelWeightModel}
                                            value="parcelWeightModel"
                                            control={<Radio/>}
                                            label={t("parcel_weight_model")}/>
                                        <FormControlLabel checked={!!state.fixedAmountModel}
                                                          value="fixedAmountModel"
                                                          control={<Radio/>}
                                                          label={t("fixed_amount_model")}/>
                                        <FormControlLabel checked={!!state.routeModel}
                                                          value="routeModel"
                                                          control={<Radio/>}
                                                          label={t("route_model")}/>
                                    </>
                                    :
                                    null
                                }
                            </RadioGroup>
                        </FormControl>
                        {
                            !!state.weightModel ?
                                renderFormWeightModel()
                                : !!state.parcelWeightModel ?
                                    renderFormParcelWeightModel()
                                    :
                                    !!state.parcelCategoryModel ?
                                        renderFormParcelCategoryModel()
                                        :
                                        !!state.fixedAmountModel ?
                                            renderFormFixedAmountModel()
                                            :
                                            !!state.routeModel ?
                                                renderFormRouteModel()
                                                :
                                                <Paper/>
                        }
                    </Card>
                </TabPanel>
                <TabPanel value="3">
                    <FormControl>
                        <div style={{display: "flex"}}>
                            <Typography variant={"button"}
                                        style={{alignSelf: "center"}}>{t("consolidation")}</Typography>
                        </div>
                        <RadioGroup className={classes.radioButtons} onChange={handleConsolidationRadioChange}
                                    defaultValue="none">
                            <FormControlLabel checked={state.consolidation === "NO_CONSOLIDATION"}
                                              value={"NO_CONSOLIDATION"}
                                              control={<Radio/>}
                                              label={t("no_consolidation")}/>
                            <FormControlLabel
                                checked={state.consolidation === "TAKEN_IN_CHARGE_AND_DELIVERY_WAREHOUSE"}
                                value={"TAKEN_IN_CHARGE_AND_DELIVERY_WAREHOUSE"}
                                control={<Radio/>}
                                label={t("taken_in_charge_consolidation")}/>
                            <FormControlLabel
                                checked={state.consolidation === "TAKEN_IN_CHARGE_AND_DELIVERY_ADDRESS"}
                                value={"TAKEN_IN_CHARGE_AND_DELIVERY_ADDRESS"}
                                control={<Radio/>}
                                label={t("taken_in_charge_and_delivery_address_consolidation")}/>
                            <FormControlLabel checked={state.consolidation === "FINAL_EVENT_AND_DELIVERY_ADDRESS"}
                                              value={"FINAL_EVENT_AND_DELIVERY_ADDRESS"}
                                              control={<Radio/>}
                                              label={t("final_event_consolidation")}/>
                        </RadioGroup>
                    </FormControl>
                </TabPanel>
            </Box>
        </TabContext>
    </div>
}