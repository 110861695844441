import {Button, Paper, Table, TableBody, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import DriverWorkListService from "../driver_work_list/DriverWorkListService";
import {
    Autocomplete,
    Card,
    Checkbox,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import DateField from "../components/fields/DateField";
import WarehouseService from "../warehouses/WarehouseService";
import {PhoneAndroid, Search} from "@material-ui/icons";
import InfoChip from "../components/chips/InfoChip";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import theme from "../theme/theme";
import {GoogleMap, InfoWindow, Marker, useLoadScript} from '@react-google-maps/api'
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import RoundDetails from "./RoundDetails";
import {makeStyles} from "@material-ui/core/styles";
import {formatDateTime} from "../utils/utils";
import variables from "../configuration/variables.json";


const useStyles = makeStyles({
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }
});

function PlanningAndControl(props) {

    const classes = useStyles();

    const {t} = useTranslation();

    const history = useHistory();

    const [refresh, setRefresh] = useState(false);

    const [drivers, setDrivers] = useState([]);
    const [waypoints, setWaypoints] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [date, setDate] = useState(new Date());

    const [appOnly, setAppOnly] = useState(false);

    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    const [activeMarker, setActiveMarker] = useState(null);

    const center = {lat: waypoints[0]?.waypoint?.latitude, lng: waypoints[0]?.waypoint?.longitude}

    useLoadScript({
        googleMapsApiKey: variables.googleAPIKey // ,
        // ...otherOptions
    })

    // function openDriverInfo(i){
    //
    //     setActiveMarker(i);
    //
    //     setRefresh(!refresh);
    // }


    const renderMap = () => {

        return <GoogleMap
            zoom={10}
            center={center}
            mapContainerStyle={{width: "100%", height: 800}}
            onClick={()=>setActiveMarker(null)}
        >
            {waypoints?.map((driver, i) => {
                return <Marker key={i}
                               position={{lat: driver.waypoint?.latitude, lng: driver.waypoint?.longitude}}
                               icon={{url : '/icons/truckMarker.png', scale: 7}}
                               onClick={()=>setActiveMarker(i)}
                >
                    {activeMarker === i ? <InfoWindow onCloseClick={()=>setActiveMarker(null)}>
                        <div>
                            <Typography variant={"subtitle2"}>
                                {driver.driver?.name + " " + driver.driver?.surname}
                            </Typography>
                            <Typography fontStyle={"italic"}>
                                {t("position_updated_at")}
                            </Typography>
                            <Typography variant={"subtitle2"}>
                                {formatDateTime(driver.waypoint?.dateTime)}
                            </Typography>
                        </div>
                    </InfoWindow>

                        :
                        null
                    }
                </Marker>
            })}


        </GoogleMap>

    }


    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }


    function handleDateChange(event) {
        setDate(event);
    }

    function handleAppOnlyChange(event) {
        setAppOnly(event.target.checked);
    }


    async function fetchDrivers() {

        setLoading(true);

        let filters = {};

        if (warehouse) {
            filters.warehouseCode = warehouse.code;
        }

        filters.appOnly = appOnly;


        filters.date = format(date, "yyyy-MM-dd");


        await new DriverWorkListService().getDetailedWorkLists(filters)
            .then(results => {

                let drivers = [];

                for (let i = 0; i < results.length; i++) {
                    let driverData = results[i];
                    driverData.isOpen = false;

                    drivers.push(driverData);
                }

                setDrivers(drivers);
                setWaypoints(drivers.filter(value => null !== value.waypoint));

                setLoading(false);
                setFetched(true);

            })
            .catch(error => {
                setLoading(false);
            })

    }

    function calculateOkShipments(driver) {

        let totalOK = 0;


        for (let j = 0; j < driver.shipments.length; j++) {
            if (driver.shipments[j].lastEvent.eventCodeId === 1 ||
                driver.shipments[j].lastEvent.eventCodeId === 81
            ) {
                totalOK++;
            }
        }


        return totalOK;
    }

    function calculateOkPickups(driver) {

        let totalOK = 0;


        for (let j = 0; j < driver.pickups.length; j++) {
            if (driver.pickups[j].lastEvent.pickupEventCode.id === 37) {
                totalOK++;
            }
        }


        return totalOK;
    }

    function calculateKoShipments(driver) {

        let totalKO = 0;


        for (let j = 0; j < driver.shipments.length; j++) {
            if (driver.shipments[j].lastEvent.eventCodeId === 11) {
                totalKO++;
            }
        }


        return totalKO;
    }

    function calculateKoPickups(driver) {

        let totalKO = 0;


        for (let j = 0; j < driver.pickups.length; j++) {
            if (
                driver.pickups[j].lastEvent.pickupEventCode.id === 16 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 14 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 13 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 12 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 9 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 8 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 7 ||
                driver.pickups[j].lastEvent.pickupEventCode.id === 5
            ) {
                totalKO++;
            }
        }

        return totalKO;
    }

    function goToRoundDetails(id){
        history.push(`/planning-and-control/${id}/round-details`)
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    useEffect(() => {

    }, [fetched, refresh])


    return <div>
        <Switch>
            <PrivateRoute exact path={"/planning-and-control"}>
                <Paper style={{paddingBottom: "3%"}}>
                    <div style={{display: "flex"}}>
                        <DialogTitle style={{margin: "auto"}}>{t("planning_and_control")}</DialogTitle>
                    </div>
                    <Card style={{width: "35%", margin: "auto"}}>
                        <Autocomplete
                            id="warehouse-select"
                            size="small"
                            style={{marginBlock: "2%", width: "fit-content", minWidth: "35%", margin: "auto"}}
                            options={warehouses}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={warehouse}
                            defaultValue={warehouse}
                            onOpen={fetchWarehouses}
                            noOptionsText={t("no_options")}
                            onChange={handleWarehouseChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                />
                            )}
                        />

                        <DateField
                            style={{margin: "auto", width: "35%", marginBlock: "2%"}}
                            label={t("date")}
                            value={date}
                            onChange={handleDateChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                        <div style={{display: 'flex'}}>
                            <FormControlLabel
                                label={<Typography fontSize={12} variant={"button"}>{t("app_only")}</Typography>}
                                control={
                                    <Checkbox
                                        style={{color: theme.palette.primary.main}}
                                        checked={appOnly}
                                        onChange={handleAppOnlyChange}
                                    />
                                }
                                labelPlacement={"right"}
                                style={{margin: "auto"}}
                            />
                        </div>

                    </Card>
                    <div style={{display: "flex"}}>
                        <Button disabled={!warehouse || !date} endIcon={loading ? <CircularProgress size={22}/> : <Search/>}
                                style={{margin: "auto", marginBlock: "2%"}}
                                onClick={fetchDrivers}>{t("search")}</Button>
                    </div>

                    {loading ?
                        <LoadingComponent/>

                        :

                        <>
                            {drivers.length > 0 ?
                                <Table style={{width: "60%", margin: "auto", border: "2px solid", marginBottom: "2%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell style={{display: "flex"}}>
                                                <div style={{margin: "auto", width: "50%"}}>{t("pickups")}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{margin: "auto", width: "50%"}}>{t("deliveries")}</div>
                                            </TableCell>
                                            <TableCell/>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                {t("driver_work_list")}
                                            </TableCell>
                                            <TableCell>
                                                {t("driver")}
                                            </TableCell>
                                            <TableCell>
                                                {t("phone")}
                                            </TableCell>
                                            <TableCell>
                                                <TableRow style={{borderBottomStyle: "hidden"}}>
                                                    <TableCell>
                                                        {t("total")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("OK")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("KO")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableCell>
                                            <TableCell>
                                                <TableRow style={{borderBottomStyle: "hidden"}}>
                                                    <TableCell>
                                                        {t("total")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("OK")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("KO")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableCell>
                                            <TableCell/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {drivers.map(driver => {
                                            return <TableRow>
                                                <TableCell>
                                                    <Typography className={classes.link} onClick={() => goToRoundDetails(driver.lastWorkListId)}>{driver.lastWorkListId}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {driver.driver.name + " " + driver.driver.surname}
                                                </TableCell>
                                                <TableCell>
                                                    {driver.driver.mobileNumber}
                                                </TableCell>
                                                <TableCell>
                                                    <TableRow style={{borderBottomStyle: "hidden"}}>
                                                        <TableCell style={{width: "50%"}}>
                                                            <Typography
                                                                variant={"button"}>{(calculateKoPickups(driver) + calculateOkPickups(driver)) + "/" + driver.pickups.length}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant={"button"}
                                                                        style={{color: "green"}}>{calculateOkPickups(driver)}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant={"button"}
                                                                        style={{color: "red"}}>{calculateKoPickups(driver)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableCell>
                                                <TableCell>
                                                    <TableRow style={{borderBottomStyle: "hidden"}}>
                                                        <TableCell style={{width: "50%"}}>
                                                            <Typography
                                                                variant={"button"}> {(calculateKoShipments(driver) + calculateOkShipments(driver)) + "/" + driver.shipments.length}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant={"button"}
                                                                        style={{color: "green"}}> {calculateOkShipments(driver)}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant={"button"}
                                                                        style={{color: "red"}}>  {calculateKoShipments(driver)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableCell>
                                                <TableCell>
                                                    {driver.handheldId? <PhoneAndroid/> : null}
                                                </TableCell>
                                            </TableRow>

                                        })}
                                    </TableBody>
                                </Table>
                                :

                                <>
                                    {fetched ?
                                        <InfoChip>{t("no_data")}</InfoChip>
                                        :
                                        null
                                    }
                                </>

                            }
                        </>
                    }

                    {fetched && drivers.length > 0?

                        <div>
                            {renderMap()}
                        </div>

                        :
                        null
                    }
                </Paper>
            </PrivateRoute>
            <PrivateRoute exact path={"/planning-and-control/:id/round-details"} children={<RoundDetails/>}/>
        </Switch>
    </div>




}

export default PlanningAndControl;