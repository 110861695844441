import React, {useEffect, useState} from "react";
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import RateService from "./RateService";
import Rate from "./Rate";
import RateForm from "./RateForm";
import {Autocomplete, Card, Paper, TableRow} from "@mui/material";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import ServiceService from "../shipments/services/ServiceService";
import InfoChip from "../components/chips/InfoChip";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        width: "fit-content",
        margin: "auto",
        marginBlock: "2%"
    },

    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function RatesTable(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [rates, setRates] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState([]);


    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [duplicateRate, setDuplicateRate] = useState(null);

    const classes = useStyles();

    function handleAccountChange(event, account) {
        setAccount(account);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(response => {
                setAccounts(response)
            })
            .catch(error => {

            })

    }


    async function fetchContracts() {

        let filters = {};
        if (account) {
            filters.accountId = account.id;
        }

        await new ContractService().getContractsByFilters(filters)
            .then(response => {
                setContracts(response);
            })
            .catch(error => {

            })
    }


    async function fetchServices() {

        await new ServiceService().getServicesByFilters()
            .then(response => {
                setServices(response);
            })
            .catch(error => {

            })
    }


    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function fetchRates() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (service) {
            filters.serviceCode = service.code;
        }

        let rateService = new RateService();
        rateService.getByFilters(filters)
            .then(data => {
                setRates(data.rates);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setLoading(false)
            })
            .catch(error => {
                setLoading(false);
            })
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function goToNewRateForm() {
        history.push("/rates/new")
    }

    function deleteCallback() {
        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");

        setRefreshTable(!refreshTable);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    useEffect(() => {
        fetchRates();
    }, [location, page, rowsPerPage, refreshTable])

    return <div>
        <Switch>
            <PrivateRoute exact path={`/rates`}>
                    <div>

                        <Card>
                            <Autocomplete
                                id="account"
                                style={{width: "30%", margin: "auto", marginBlock: "2%"}}
                                options={accounts}
                                getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                value={account}
                                defaultValue={account}
                                noOptionsText={t("no_options")}
                                onOpen={fetchAccounts}
                                onChange={handleAccountChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("account")}
                                        variant="outlined"
                                        size={"small"}
                                        margin="dense"
                                        value={account}
                                        defaultValue={account}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="contract"
                                size="small"
                                style={{width: "30%", margin: "auto", marginBottom: "2%"}}
                                options={contracts}
                                disabled={!account}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={contract}
                                defaultValue={contract}
                                noOptionsText={t("no_options")}
                                onOpen={fetchContracts}
                                onChange={handleContractChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("contract")}
                                        variant="outlined"
                                        margin="dense"
                                        value={contract}
                                        defaultValue={contract}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="service"
                                size="small"
                                style={{width: "30%", margin: "auto", marginBottom: "2%"}}
                                options={services}
                                getOptionLabel={option => option.name}
                                value={service}
                                defaultValue={service}
                                noOptionsText={t("no_options")}
                                onOpen={fetchServices}
                                onChange={handleServiceChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("service")}
                                        variant="outlined"
                                        margin="dense"
                                        value={service}
                                        defaultValue={service}
                                    />
                                )}
                            />
                            <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                                <Button startIcon={<SearchOutlined/>} variant="contained"
                                        color="primary" type="button" onClick={fetchRates}>
                                    {t("search")}
                                </Button>
                            </div>
                        </Card>
                        <div style={{width: "fit-content", margin: "auto", marginBlock: "4%"}}>
                            <Button startIcon={<AddCircleIcon/>} variant="contained"
                                    color="primary" type="button" onClick={goToNewRateForm}>
                                {t("create_new_rate")}
                            </Button>
                        </div>

                        {(loading) ?
                            <LoadingComponent/>

                            :
                            <Paper>

                                {rates.length > 0 ?
                                    <div>
                                        <TableContainer className={classes.container}>
                                            <Table stickyHeader className={classes.container}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t("ID")}</TableCell>
                                                        <TableCell>{t("code")}</TableCell>
                                                        <TableCell>{t("currency")}</TableCell>
                                                        <TableCell/>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {rates.map((rate, index) => {
                                                        return <Rate deleteCallback={deleteCallback} rate={rate} setDuplicate={setDuplicateRate}/>
                                                    })}
                                                </TableBody>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 100]}
                                                    count={-1}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {'aria-label': 'rows per page'},
                                                        native: false,
                                                    }}
                                                    labelRowsPerPage={t("rows_per_page")}
                                                    labelDisplayedRows={() => {
                                                        return t("page")
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    :
                                    <div style={{paddingBlock: "1%"}}>
                                        <InfoChip>{t("no_data")}</InfoChip>
                                    </div>

                                }
                                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                              onClose={closeNotification}>{notificationMessage}</Notification>
                            </Paper>
                        }
                    </div>


            </PrivateRoute>

            <PrivateRoute strict path={`/rates/:id/edit`} children={<RateForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/rates/new`}>
                <RateForm rate={duplicateRate}/>
            </PrivateRoute>

        </Switch>
    </div>
}

export default RatesTable;