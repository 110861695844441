import {Switch, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import RatingOptionService from "./RatingOptionService";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import InfoChip from "../../components/chips/InfoChip";
import RatingOption from "./RatingOption";
import {PrivateRoute} from "../../routes/PrivateRoute";
import RatingOptionForm from "./RatingOptionForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@mui/material";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import {Card, CircularProgress, DialogTitle} from "@mui/material";
import theme from "../../theme/theme";
import Notification from "../../components/notifications/Notification";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function RatingOptionTable(props) {


    const history = useHistory();
    const {t} = useTranslation();
    const location = useLocation();

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [options, setOptions] = useState([]);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pages, setPages] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        setContracts([]);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isCustomer: true})
            .then(data => {
                setAccounts(data)
            })
            .catch((err) => {

            })
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = account.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }

    function applyFilters() {
        setRefresh(!refresh);
    }

    function deleteCallback(){
        setNotificationMessage(t("successful"));
        setNotifySeverity("success");
        setOpenNotify(true);
        setRefresh(!refresh);
    }

    async function fetchOptions() {

        setLoading(true);

        let filters = {};

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        if(account){
            filters.accountId = account.id;
        }

        if(contract){
            filters.contractId = contract.id
        }

        await new RatingOptionService().getOptions(filters)
            .then(result => {
                setOptions(result.ratingOptions)
                setPages(Array.from(Array(Math.ceil(result.count / rowsPerPage)).keys()));
                setLoading(false);
                setFetched(true);
            })
            .catch(error => {
                setLoading(false);
            })

    }

    function goToRatingOptionForm() {
        history.push("/rating-options/new")
    }

    useEffect(() => {
        fetchOptions()
    }, [page, rowsPerPage, refresh, location])

    return <div>
        <Switch>
            <PrivateRoute exact path={`/rating-options`}>
                <Card style={{display: "block"}}>
                    <div style={{display: "flex"}}>
                        <DialogTitle style={{margin: "auto"}}>{t("rating_options")}</DialogTitle>
                    </div>
                    <div style={{display: "flex"}}>
                        <Autocomplete
                            id="accounts"
                            size="small"
                            style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                            options={accounts}
                            getOptionLabel={account => account.accountCorporateCode + " - " + account.companyName}
                            value={account}
                            defaultValue={account}
                            onOpen={fetchAccounts}
                            onChange={handleAccountChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            noOptionsText={t("no_options")}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t("account")}
                                variant="outlined"
                                margin="dense"
                                value={account}
                                defaultValue={account}
                            />}
                        />
                        <Autocomplete
                            id="contract"
                            size="small"
                            style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                            options={contracts}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={contract}
                            disabled={!account}
                            defaultValue={contract}
                            onOpen={fetchContracts}
                            noOptionsText={t("no_options")}
                            onChange={handleContractChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("contract")}
                                    size={"small"}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                />
                            )}
                        />
                    </div>
                    <div style={{display: "flex"}}>
                        <IconButton onClick={applyFilters}
                                    disabled={loading}
                                    style={{backgroundColor: 'transparent', margin: "auto", marginBottom: "2%"}}>{loading ?
                            <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                            <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                    </div>
                </Card>
                <div style={{display: "flex"}}>
                    <Button style={{margin: "auto", marginBlock: "2%", width: "20%"}} startIcon={<AddCircleIcon/>}
                            variant="contained" color="primary" onClick={goToRatingOptionForm}
                    >
                        {t("create_rating_option")}
                    </Button>
                </div>

                <Paper>
                    {options.length > 0 ?
                        <TableContainer>
                            {loading ?
                                <LoadingComponent/>
                                :
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableCell>{t("contract")}</TableCell>
                                        <TableCell>{t("min_score")}</TableCell>
                                        <TableCell>{t("max_score")}</TableCell>
                                        <TableCell>{t("locales")}</TableCell>
                                        <TableCell/>
                                    </TableHead>
                                    <TableBody>
                                        {options.map((option, index) => {

                                            return <RatingOption deleteCallback={deleteCallback} option={option}/>

                                        })}
                                    </TableBody>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={-1}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: false,
                                        }}
                                        labelRowsPerPage={t("rows_per_page")}
                                        labelDisplayedRows={() => {
                                            return t("page")
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        onPageChange={handleChangePage}/>
                                </Table>
                            }
                        </TableContainer>
                        :
                        <>
                            {fetched ?
                                <InfoChip>{t("no_data")}</InfoChip>
                                :
                                null
                            }
                        </>
                    }
                </Paper>
                <Notification open={openNotify} severity={notifySeverity} duration={100000}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            </PrivateRoute>
            <PrivateRoute exact path={`/rating-options/new`}>
                <RatingOptionForm/>
            </PrivateRoute>
            <PrivateRoute exact path={`/rating-options/:id/edit`}>
                <RatingOptionForm/>
            </PrivateRoute>
        </Switch>
    </div>


}

export default RatingOptionTable;