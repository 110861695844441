import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


export default class MasterPalletService{

    getByFilters(filters){
        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/master-pallets`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getCsv(filters){
        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/master-pallets/csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getMasterPalletTypes() {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/master-pallets/types`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getMasterPalletEventCodes() {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/master-pallets/event-codes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getMasterPalletLabels(filters, type) {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/master-pallets/labels/` + type;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getMasterPalletsIdsBySummaryOutId(summaryOutId) {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/master-pallets/summary-out/` + summaryOutId;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

}