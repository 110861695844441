import fetch from 'unfetch';
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";
import CurrencyService from "../currencies/CurrencyService";

const userService = new UserService();


export default class ShipmentService {


    getAllShipment(filters) {

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/shipments`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getShipmentById(id, guestKey) {

        const apiKey = userService.getApiKey();

        let queryString = "";
        if(null !== guestKey){
            queryString += "?key=" + guestKey;
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/shipments/${id}` + queryString, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getParcelsOfShipment(id) {

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/shipments/${id}/parcels`, requestOption)
            .then(checkStatus)
            .then(response => response.json())

    }

    getEventsOfParcel(id) {

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/parcels/${id}/events`, requestOption)
            .then(checkStatus)
            .then(response => response.json())

    }

    getEventsOfShipment(id) {

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/shipments/${id}/events`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }

    createNewShipment(shipment) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                shipment
            )


        };

        return fetch('/api/v1/shipments', requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getParcelEventCodes() {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };

        return fetch('/api/v1/parcels/events/codes', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }


    getShipmentEventCodes() {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };

        return fetch('/api/v1/shipments/events/codes', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    getCurrencies(filters) {
        const currencyService = new CurrencyService();
        return currencyService.getAll(filters);
    }

    getCodModes(filters) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/cods/modes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getBookings(shipmentId) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/shipments/${shipmentId}/bookings`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    updateAddress(addressId, shipmentId, address) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                    country: address.country,
                    administrativeLevel3: address.administrativeLevel3,
                    administrativeLevel2: address.administrativeLevel2,
                    administrativeLevel1: address.administrativeLevel1,
                    city: address.city,
                    postalCode: address.postalCode,
                    hamlet: address.hamlet,
                    street: address.street,
                    buildingNr: address.buildingNr,
                    companyName: address.companyName,
                    referencePerson: address.referencePerson,
                    phone: address.phone,
                    mobile: address.mobile,
                    email: address.email

                }
            )
        };

        return fetch(`/api/v1/shipments/${shipmentId}/address/${addressId}`, requestOptions)
            .then(checkStatus)
    }


    updateService(serviceId, shipmentId) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                    serviceId
            )
        };

        return fetch(`/api/v1/shipments/${shipmentId}/service`, requestOptions)
            .then(checkStatus)
    }

    updateCod(shipmentId, input) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                input
            )
        };

        return fetch(`/api/v1/shipments/${shipmentId}/update-cod`, requestOptions)
            .then(checkStatus)
    }

    getCSV(filters) {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/shipments/csv`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getReducedCSV(filters) {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/shipments/csv/reduced`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getLabel(shipmentId, labelType) {

        const apiKey = userService.getApiKey();


        // http://doc.tms.aurobica.com/api/v1/shipments/{shipmentId}/labels/{labelType}


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/labels/${labelType}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getShipmentLabelsByFilters(filters, labelType) {

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/shipments/labels/${labelType}`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    sendSMS(shipmentId) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                id: shipmentId
            })
        };

        let pathVariable = `/api/v1/shipments/auth`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getCodById(codId) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/cods/${codId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getShipmentCommunications(shipmentId) {


        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/communications`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    deleteShipment(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }

    cancelShipment(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/cancel`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }


    deleteShipmentSU(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/delete`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }

    getAccountingRows(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/accounting-rows`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getActivePreInvoiceRows(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/active-pre-invoice-rows`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getPassivePreInvoiceRows(shipmentId){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/passive-pre-invoice-rows`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    suspendShipment(shipmentId){


        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/suspend`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    saveExtra(shipmentId, extra, type){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(extra)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/${type}/extra`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    saveNote(shipmentId, note){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(note)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/notes`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getSuggestedDeliveryZone(id){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${id}/delivery-zone`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    assignNewDeliveryZone(shipmentId, input){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/routing`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }


    deleteParcels(input, shipmentId){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/delete-parcels`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    addParcel(input, shipmentId){
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/parcels`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deliverToPudo(input){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/to-pudo`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }



    confirmDeliveryToPudo(input){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/confirm-to-pudo`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }

    getShipmentLogs(id){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${id}/logs`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    refreshEstimatedDeliveryDate(shipmentId, type){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/${type}/refresh`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }

    updateBlocks(shipmentId, input){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/blocks`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }

    updateUrbaneToStandard(shipmentId, filters){

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/to-standard`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }


    importFromCsv(file){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(file)
        };

        let pathVariable = `/api/v1/shipments/import`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getTemplate(){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/shipments/import-template`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }


    changePudo(shipmentId, body){

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(body)
        };

        let pathVariable = `/api/v1/shipments/${shipmentId}/change-pudo`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }



}
