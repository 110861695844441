import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {Circle, Download} from "@mui/icons-material";
import {Delete, Replay, Send} from "@material-ui/icons";
import DocumentService from "../documents/DocumentService";
import Notification from "../../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {formatDate} from "../../utils/utils";


function CustomerPreInvoice(props) {

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [confirmRetransmissionDialogOpen, setConfirmRetransmissionDialogOpen] = useState(false);
    const [addPurchaseOrderDialogOpen, setAddPurchaseOrderDialogOpen] = useState(false);

    const [purchaseOrder, setPurchaseOrder] = useState(null);

    const [loadingCSV, setLoadingCSV] = useState(false);


    const {t} = useTranslation();

    const preInvoice = props.preInvoice;

    function handlePurchaseOrderChange(e){
        setPurchaseOrder(e.target.value)
    }

    async function downloadCSV() {

        setLoadingCSV(true);

        await new DocumentService().getCSV(props.preInvoice.documentId)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCSV(false);
                })
            });
    }

    async function deleteDocument() {

        await new DocumentService().deleteDocument(props.preInvoice.documentId)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setConfirmDeleteDialogOpen(false);
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function transmitDocument(){

        let body = {};

        if(purchaseOrder){
            body.purchaseOrder = purchaseOrder;
        }

        await new DocumentService().addToQueue(props.preInvoice.documentId, body)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.refresh();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function retransmitDocument(){

        await new DocumentService().retransmitDocument(props.preInvoice.documentId)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.refresh();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }


    useEffect(() => {

    }, [])

    return <React.Fragment>
        <TableRow>
            <TableCell component="th" scope="row">
                {preInvoice.documentNumber}
            </TableCell>
            {/*<TableCell align="left">{preInvoice.contractCode} </TableCell>*/}
            <TableCell align="left">{preInvoice.contractName}</TableCell>
            <TableCell align="left">{formatDate(preInvoice.date)}</TableCell>
            <TableCell align="left">{preInvoice.totalAmount.toFixed(2)}</TableCell>
            <TableCell align="left">{preInvoice.purchaseOrder}</TableCell>
            <TableCell align="left">{preInvoice.invoiceNumber}</TableCell>
            <TableCell align="left">{formatDate(preInvoice.invoiceDate)}</TableCell>
            <TableCell align="left"><Circle style={{color: preInvoice.transmitted? "green" : (preInvoice.inQueue? "gold" : "grey")}}/></TableCell>
            <TableCell align="left">
                <IconButton style={{marginInline: "2%"}} onClick={downloadCSV}>
                    {loadingCSV?
                        <CircularProgress size={24}/>
                        :
                        <Download/>
                    }
                </IconButton>
                <IconButton style={{marginInline: "2%"}} onClick={()=>setConfirmDeleteDialogOpen(true)}><Delete/></IconButton>
                <IconButton style={{marginInline: "2%"}} disabled={props.preInvoice.transmitted || props.preInvoice.inQueue} onClick={()=>setAddPurchaseOrderDialogOpen(true)}><Send/></IconButton>
                <IconButton style={{marginInline: "2%"}} disabled={!props.preInvoice.transmitted && !props.preInvoice.inQueue} onClick={()=>setConfirmRetransmissionDialogOpen(true)}><Replay/></IconButton>
            </TableCell>
        </TableRow>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteDocument}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
        <ConfirmDialog open={addPurchaseOrderDialogOpen}
                       title={"Aggiungi eventuale ordine d'acquisto"}
                       confirmText={t("send")}
                       cancelText={t("cancel")}
                       onConfirm={transmitDocument}
                       onCancel={() => setAddPurchaseOrderDialogOpen(false)}>
            <TextField
                label={t("purchase_order")}
                size={"small"}
                variant="outlined"
                margin="dense"
                value={purchaseOrder}
                defaultValue={purchaseOrder}
                onChange={handlePurchaseOrderChange}
            />
        </ConfirmDialog>
        <ConfirmDialog open={confirmRetransmissionDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={retransmitDocument}
                       onCancel={() => setConfirmRetransmissionDialogOpen(false)}>
            {t("confirm_retransmit")}
        </ConfirmDialog>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>
    </React.Fragment>
}

export default CustomerPreInvoice;