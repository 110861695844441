import React, {useEffect, useRef, useState} from "react";
import {
    Autocomplete,
    Avatar,
    Button,
    Card,
    CircularProgress, Dialog,
    DialogTitle,
    Divider, IconButton,
    ListItemAvatar,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import DriverWorkListService from "./DriverWorkListService";
import DateField from "../components/fields/DateField";
import {useTranslation} from "react-i18next";
import WarehouseService from "../warehouses/WarehouseService";
import {format} from "date-fns";
import DriverService from "../drivers/DriverService";
import {ShareLocationOutlined} from "@mui/icons-material";
import theme from "../theme/theme";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatAddressFirstLine, formatAddressSecondLine} from "../utils/utils";
import {GoogleMap, InfoBox, InfoWindow, Marker, Polyline, useLoadScript} from "@react-google-maps/api";
import variables from "../configuration/variables.json";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Edit} from "@material-ui/icons";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {MinimalLoadingComponent} from "../components/loading/MinimalLoadingComponent";
import ShipmentService from "../shipments/ShipmentService";
import Notification from "../components/notifications/Notification";


function OptimizeDriverWorkList(props) {

    const {t} = useTranslation();

    const [coordinates, setCoordinates] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [date, setDate] = useState(new Date());

    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);

    const [driverWorkList, setDriverWorkList] = useState(null);
    const [driverWorkLists, setDriverWorkLists] = useState([]);

    const [address, setAddress] = useState(null);

    const [countries, setCountries] = useState([]);
    const [administrativeLevel3, setAdministrativeLevel3] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [optimizedWorkList, setOptimizedWorkList] = useState(null);

    const [loadingOptimize, setLoadingOptimize] = useState(false);

    const [openUpdateAddress, setOpenUpdateAddress] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [updatingShipmentId, setUpdatingShipmentId] = useState(null);
    const [updatingIndex, setUpdatingIndex] = useState(null);

    const [freeDeliveryCity, setFreeDeliveryCity] = useState('');
    const [freeDeliveryAdministrativeLevel1, setFreeDeliveryAdministrativeLevel1] = useState('');
    const [freeDeliveryAdministrativeLevel2, setFreeDeliveryAdministrativeLevel2] = useState('');
    const [freeDeliveryAdministrativeLevel3, setFreeDeliveryAdministrativeLevel3] = useState('');
    const [freeDeliveryPostalCode, setFreeDeliveryPostalCode] = useState('');

    const [deliveryCountry, setDeliveryCountry] = useState({});
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryBuildingNr, setDeliveryBuildingNr] = useState('');
    const [deliveryPostalCode, setDeliveryPostalCode] = useState({});
    const [deliveryCity, setDeliveryCity] = useState({});
    const [deliveryAdministrativeLevel3, setDeliveryAdministrativeLevel3] = useState({});

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    const [polyline, setPolyline] = useState(null);

    const [activeIndex, setActiveIndex] = useState(null);

    const apiKey = variables.googleAPIKey;

    const mapRef = useRef(null);

    const [zoom, setZoom] = useState(5); // Initial zoom level

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    function goToPickupDetails(id) {
        window.open(`/pickups/${id}/details`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    function clearAddress() {
        setAddress(false);
        setDeliveryCountry(null);
        setDeliveryCity(null);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryPostalCode(null);
        setDeliveryStreet(null);
        setDeliveryBuildingNr(null);
        setFreeDeliveryCity('');
        setFreeDeliveryPostalCode('');
        setFreeDeliveryAdministrativeLevel3('');
        setFreeDeliveryAdministrativeLevel2('');
        setFreeDeliveryAdministrativeLevel1('');
    }

    function getScoreTooltip(score) {
        if (score && score > 90) {
            return "Geolocalizzazione accurata";
        }
        if (score && score <= 90) {
            return "Verificare indirizzo";
        }

        return "Accuratezza o geolocalizzazione non disponibile";
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    function fetchAdministrativeLevel3(country) {

        let filters = {}

        if (country) {
            filters.countryId = country.id;
        }
        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdministrativeLevel3(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    async function fetchAndSetAddress(address) {

        setLoadingAddress(true);

        let country = {};
        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null)
            .then(data => {
                if (address.country.length === 2) {
                    country = data.countries.filter(country => country.iso3166Alpha2 === address.country)[0];
                } else {
                    country = data.countries.filter(country => country.iso3166Alpha3 === address.country)[0]
                }

            })

        setDeliveryCountry(country);

        let city = {}
        let administrative3 = {}
        let postalCode = {}


        if (address.country === "IT" || address.country === "ITA" || address.country === "ES" || address.country === "ESP" || address.country === "DE" || address.country === "DEU") {

            if (null !== address.administrative_level_3) {
                let admin3Service = new AdministrativeLevel3Service();
                await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                    .then(data => {
                        if (address.administrative_level_3 !== null) {
                            administrative3 = data.admins3.filter(admin3 => admin3.code === address.administrative_level_3)[0]
                        } else {
                            administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                        }
                    })
            }


            if (null !== address.city) {
                let cityService = new CityService();
                await cityService.getCities(null, null, null, null)
                    .then(data => {
                        city = data.cities.filter(city => city.name.toLowerCase() === address.city.toLowerCase())[0]
                    })
            }

            if (null !== address.postalCode) {
                let postalCodeService = new PostalCodeService();
                await postalCodeService.getAllPostalCodes(null, null, null, null)
                    .then(data => {
                        postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === address.postalCode)[0];
                    })
            }


            setDeliveryCity(city);
            setDeliveryAdministrativeLevel3(administrative3);
            setDeliveryPostalCode(postalCode);
        } else {
            setFreeDeliveryAdministrativeLevel1(address.administrative_level_1);
            setFreeDeliveryAdministrativeLevel2(address.administrative_level_2);
            setFreeDeliveryAdministrativeLevel3(address.administrative_level_3);
            setFreeDeliveryCity(address.city);
            setFreeDeliveryPostalCode(address.postalCode);
        }

        setDeliveryStreet(address.street);
        setDeliveryBuildingNr(address.buildingNr);
        setLoadingAddress(false);

    }

    function handleDeliveryCountryChange(event, country) {

        setLoadingAddress(true);
        setDeliveryCountry(country);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);
        setDeliveryStreet('');
        setDeliveryBuildingNr('');
        setTimeout(() => {
            setLoadingAddress(false);
        }, 1000)

    }

    function handleDeliveryAdministrativeLevel3Change(event, adminl3) {
        setDeliveryAdministrativeLevel3(adminl3);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);
    }

    function handleDeliveryCityChange(event, city) {
        setDeliveryCity(city);
        setDeliveryPostalCode(null);
    }

    function handleDeliveryPostalCodeChange(event, postalCode) {
        setDeliveryPostalCode(postalCode);
    }

    function handleDeliveryStreetChange(event, street) {
        setDeliveryStreet(event.target.value);
    }

    function handleDeliveryBuildingNrChange(event, buildingNr) {
        setDeliveryBuildingNr(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel1Change(event) {
        setFreeDeliveryAdministrativeLevel1(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel2Change(event) {
        setFreeDeliveryAdministrativeLevel2(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel3Change(event) {
        setFreeDeliveryAdministrativeLevel3(event.target.value);
    }

    function handleFreeDeliveryCityChange(event) {
        setFreeDeliveryCity(event.target.value);
    }

    function handleFreeDeliveryPostalCodeChange(event) {
        setFreeDeliveryPostalCode(event.target.value);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        if (null == warehouse) {
            setDriverWorkList(null);
            setDriver(null);
            setDate(null)
        }
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
        if (null == driver) {
            setDriverWorkList(null);
        }
    }

    function handleDateChange(event) {
        setDate(event);
        setDriver(null)
        setDriverWorkList(null)
    }

    async function handleWorkListChange(event, workList) {
        setDriverWorkList(workList);

        if (workList == null) {

        } else {
            await getOptimizedDriverWorkList(workList.id);
        }
    }

    async function getOptimizedDriverWorkList(id) {

        setLoadingOptimize(true);

        setCoordinates([]);

        await getPolyline(id);

        await new DriverWorkListService().getOptimizedWorkList(id)
            .then(result => {
                setOptimizedWorkList(result);

                let newCoordinates = [];

                for (let i = 0; i < result.optimizedTour.length; i++) {
                    newCoordinates.push({
                        lat: result.optimizedTour[i].address.latitude,
                        lng: result.optimizedTour[i].address.longitude
                    })
                }

                setCoordinates(newCoordinates);
                setZoom(5);

                setLoadingOptimize(false);

            })
            .catch(error => {

                setLoadingOptimize(false);
            })


    }

    async function getPolyline(id) {

        await new DriverWorkListService().getPolyline(id)
            .then(result => {

                let newPolyline = [];

                for (let i = 0; i < result?.coordinates.length; i++) {
                    let coord = {};
                    coord.lat = result?.coordinates[i][1];
                    coord.lng = result?.coordinates[i][0];

                    newPolyline.push(coord);
                }

                setPolyline(newPolyline);

            })
            .catch(error => {
                setPolyline(null);
            })


    }

    async function optimizeDriverWorkList() {

        setLoadingOptimize(true);

        if (driverWorkList) {

            await new DriverWorkListService().optimizeDriverWorkList(driverWorkList.id)
                .then(async result => {
                    await getOptimizedDriverWorkList(driverWorkList.id);
                    setLoadingOptimize(false);
                })
                .catch(error => {
                    setLoadingOptimize(false);
                })

        }

    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
        if (wares?.warehouses?.length === 1) {
            setWarehouse(wares.warehouses[0]);
        }
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }

    async function fetchDriverWorkLists() {
        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (driver) {
            filters.driverId = driver.id;
        }
        filters.date = format(date, "yyyy-MM-dd");

        let worklists = await new DriverWorkListService().getDriverWorkListsForClosure(filters);
        setDriverWorkLists(worklists.driverWorkLists);
    }

    function closeUpdate() {
        setOpenUpdateAddress(false);
        setLoadingAddress(false);
        setAddress(null);
        clearAddress();
    }

    async function confirmUpdateAddress() {

        let newAddress = address;
        if (deliveryCountry != null) {
            newAddress.country = deliveryCountry.iso3166Alpha2;
        }
        if (newAddress.country === "IT" || newAddress.country === "ES" || newAddress.country === "FR" || newAddress.country === "DE") {
            if (deliveryAdministrativeLevel3 !== null) {
                newAddress.administrativeLevel3 = deliveryAdministrativeLevel3.code;
            }
            if (deliveryCity !== null) {
                newAddress.city = deliveryCity.name;
            }
            if (deliveryPostalCode !== null) {
                newAddress.postalCode = deliveryPostalCode.postalCode
            }
        } else {
            newAddress.administrativeLevel3 = freeDeliveryAdministrativeLevel3;
            newAddress.administrativeLevel2 = freeDeliveryAdministrativeLevel2;
            newAddress.administrativeLevel1 = freeDeliveryAdministrativeLevel1;
            newAddress.city = freeDeliveryCity;
            newAddress.postalCode = freeDeliveryPostalCode;
        }

        newAddress.street = deliveryStreet;
        newAddress.buildingNr = deliveryBuildingNr;

        let shipmentService = new ShipmentService();
        shipmentService.updateAddress(address?.id, updatingShipmentId, newAddress)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                getOptimizedDriverWorkList(driverWorkList.id)
                setOpenUpdateAddress(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }

    function generateDeliveryAddress() {

        if (deliveryCountry && (deliveryCountry.id === 106 || deliveryCountry.id === 67 || deliveryCountry.id === 56)) {
            return <>
                {loadingAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    /> :
                    <>
                        <Autocomplete
                            id="administrativeLevel3"
                            size="small"
                            style={{width: "80%", margin: "auto"}}
                            options={administrativeLevel3}
                            disabled={!deliveryCountry}
                            getOptionLabel={option => option.name}
                            value={deliveryAdministrativeLevel3}
                            defaultValue={deliveryAdministrativeLevel3}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryAdministrativeLevel3Change}
                            onOpen={() => fetchAdministrativeLevel3(deliveryCountry)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrative3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryAdministrativeLevel3}
                                    defaultValue={deliveryAdministrativeLevel3}
                                />
                            )}
                        />
                        <Autocomplete
                            id="city"
                            size="small"
                            options={cities}
                            style={{width: "80%", margin: "auto"}}
                            disabled={!deliveryAdministrativeLevel3}
                            getOptionLabel={option => option.name}
                            value={deliveryCity}
                            defaultValue={deliveryCity}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryCityChange}
                            onOpen={() => fetchCities(deliveryAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryCity}
                                    defaultValue={deliveryCity}
                                    helperText={!deliveryAdministrativeLevel3 ? t("select_admin3_first") : null}
                                />
                            )}
                        />
                        <Autocomplete
                            id="postalCode"
                            size="small"
                            style={{marginBottom: "5%", width: "80%", margin: "auto"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            value={deliveryPostalCode}
                            defaultValue={deliveryPostalCode}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryPostalCodeChange}
                            onOpen={() => fetchPostalCodes(deliveryCity, deliveryAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryPostalCode}
                                    defaultValue={deliveryPostalCode}
                                    required
                                    error={!deliveryPostalCode}
                                    helperText={!deliveryPostalCode ? t("required") : ""}
                                />
                            )}
                        />
                        <div style={{marginTop: "2%"}}>
                            <TextField
                                style={{marginInline: "2%", marginBottom: "5%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryStreet}
                                onChange={handleDeliveryStreetChange}
                            />
                            <TextField
                                style={{marginInline: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryBuildingNr}
                                onChange={handleDeliveryBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        } else {
            return <>
                {loadingAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    />
                    :
                    <>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative1")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel1}
                                onChange={handleFreeDeliveryAdministrativeLevel1Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative2")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel2}
                                onChange={handleFreeDeliveryAdministrativeLevel2Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative3")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel3}
                                onChange={handleFreeDeliveryAdministrativeLevel3Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("city")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryCity}
                                onChange={handleFreeDeliveryCityChange}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("postalCode")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryPostalCode}
                                onChange={handleFreeDeliveryPostalCodeChange}
                                required
                                error={!freeDeliveryPostalCode}
                                helperText={!freeDeliveryPostalCode ? t("required") : null}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginRight: "2%", marginBottom: "2%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryStreet}
                                onChange={handleDeliveryStreetChange}
                            />
                            <TextField
                                style={{marginLeft: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryBuildingNr}
                                onChange={handleDeliveryBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        }
    }

    useLoadScript({
        googleMapsApiKey: variables.googleAPIKey // ,
        // ...otherOptions
    })


    // function renderMap(){
    //     return <LoadScript googleMapsApiKey={apiKey}>
    //         <GoogleMap
    //             mapContainerStyle={{ width: '100%', height: '400px' }}
    //             zoom={zoom}
    //             defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
    //             ref={mapRef}
    //         >
    //             {coordinates.map((coord, index) => (
    //                 <Marker key={index} position={coord} />
    //             ))}
    //             {coordinates.length >= 2 && (
    //                 <Polyline
    //                     path={coordinates}
    //                     options={{
    //                         strokeColor: '#FF0000',
    //                         strokeOpacity: 1.0,
    //                         strokeWeight: 2,
    //                     }}
    //                 />
    //             )}
    //
    //         </GoogleMap>
    //     </LoadScript>
    // }

    const renderMap = () => {

        const svgMarker = (index) => `
  <svg width="48" height="48" viewBox="0 0 64 55" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 4C22.058 4 14 12.058 14 22c0 11.046 14.702 32.43 16.027 34.306a2 2 0 0 0 3.946 0C35.298 54.43 50 33.046 50 22c0-9.942-8.058-18-18-18z" fill="#ff1224"/>
            <text x="32" y="25" font-size="18" font-weight="bold" fill="#fff" font-family="Arial" text-anchor="middle" dominant-baseline="middle">
                ${index + 1}
            </text>
        </svg>
`;


        const svgToBase64 = (svg) => `data:image/svg+xml;base64,${btoa(svg)}`;

        return <GoogleMap
            zoom={10}
            center={coordinates[0]}
            mapContainerStyle={{width: "100%", height: 800}}
            // ref={mapRef}
        >
            {coordinates.map((tourItem, i) => {
                return <Marker key={i}
                               animation={activeIndex === i ? window.google.maps.Animation.BOUNCE : null}
                               position={tourItem}
                               icon={{
                                   url: i !== 0 && i !== coordinates.length - 1 ?
                                       svgToBase64(svgMarker(i))
                                       : '/icons/warehousemarker.png', scale: 7
                               }}
                >

                </Marker>
            })}
            {polyline?.length >= 2 ?
                <Polyline
                    path={polyline}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    }}
                />
                :
                <>
                    {coordinates?.length >= 2 &&
                        <Polyline
                            path={coordinates}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                            }}
                        />
                    }
                </>
            }

        </GoogleMap>

    }

    useEffect(() => {
        if (mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            mapRef.current.fitBounds(bounds);
        }
    }, [coordinates, polyline]);


    // useEffect(() => {
    //     fetchWarehouses();
    // }, []);

    return (
        <>
            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                          onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            <Card>
                <DialogTitle
                    style={{width: "fit-content", margin: "auto"}}>{t("optimize_driver_work_list")}</DialogTitle>
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={handleWarehouseChange}
                    onOpen={fetchWarehouses}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("date")}
                    value={date}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                />
                <Autocomplete
                    id="driver-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={drivers}
                    disabled={!warehouse || !date} F
                    getOptionLabel={option => option.name + " " + option.surname}
                    value={driver}
                    defaultValue={driver}
                    noOptionsText={t("no_options")}
                    onChange={handleDriverChange}
                    onOpen={fetchDrivers}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver")}
                            variant="outlined"
                            margin="dense"
                            value={driver}
                            defaultValue={driver}
                        />
                    )}
                />
                <Autocomplete
                    id="wrkList"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={driverWorkLists}
                    disabled={!driver}
                    getOptionLabel={option => (option.delayed ? option.id + "/D" : option.id) + " del " + new Date(option.dateTime).toLocaleDateString() + " ore " + new Date(option.dateTime).toLocaleTimeString()}
                    value={driverWorkList}
                    defaultValue={driverWorkList}
                    noOptionsText={t("no_options")}
                    onChange={handleWorkListChange}
                    onOpen={fetchDriverWorkLists}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver_work_list")}
                            variant="outlined"
                            margin="dense"
                            value={driverWorkList}
                            defaultValue={driverWorkList}
                        />
                    )}
                />

                <div style={{display: "block", marginBlock: "3%"}}>
                    <div style={{display: "flex"}}>
                        <Button disabled={!driverWorkList || loadingOptimize}
                                style={{
                                    margin: "auto",
                                    backgroundColor: !driverWorkList ? "grey" : theme.palette.primary.main
                                }}
                                variant={"contained"}
                                startIcon={loadingOptimize ? <CircularProgress style={{color: "white"}} size={20}/> :
                                    <ShareLocationOutlined/>}
                                onClick={optimizeDriverWorkList}
                        >{t("optimize")}</Button>
                    </div>
                    {variables.isPtvOptimized === "true" && <div style={{margin: "auto", width: "fit-content"}}>
                        <Typography variant={"caption"}
                                    style={{width: "fit-content", margin: "auto"}}>{"© 2024 PTV GmbH"}</Typography>
                    </div>}

                </div>
                <div style={{display: "flex", width: "100%"}}>
                    <div style={{width: "50%"}}>
                        {loadingOptimize ?

                            <LoadingComponent/>

                            :

                            <div style={{margin: "auto"}}>
                                <Divider/>
                                <List>
                                    {optimizedWorkList?.optimizedTour?.map((item, index) => {
                                        return <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            if (activeIndex === index) {
                                                                setActiveIndex(null);
                                                            } else {
                                                                setActiveIndex(index)
                                                            }
                                                        }}
                                                        alt={index + 1}>{index + 1}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={item.shipmentReference ?
                                                        <div>
                                                            <Typography
                                                                onClick={() => goToAboutMe(item.shipmentId)}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    transition: "all 0.2s",
                                                                    color: "#144dff",
                                                                    '& > *': {
                                                                        borderBottom: 'unset',
                                                                    }
                                                                }}>
                                                                {item.shipmentId}
                                                            </Typography>
                                                            <Typography>
                                                                {item.shipmentReference}
                                                            </Typography>
                                                        </div>
                                                        : (item.pickupReference ?

                                                            <Typography
                                                                onClick={() => goToPickupDetails(item.pickupReference)}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    transition: "all 0.2s",
                                                                    color: "#144dff",
                                                                    '& > *': {
                                                                        borderBottom: 'unset',
                                                                    }
                                                                }}>
                                                                {item.pickupReference}
                                                            </Typography>

                                                            : t("warehouse") + " " + optimizedWorkList.warehouseCode)}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{display: 'inline'}}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {formatAddressFirstLine(item.address)}
                                                            </Typography>
                                                            {" - " + formatAddressSecondLine(item.address)}
                                                        </React.Fragment>
                                                    }
                                                />
                                                {(index !== 0 && index !== optimizedWorkList?.optimizedTour.length - 1) &&
                                                    <IconButton onClick={async () => {
                                                        setOpenUpdateAddress(true);
                                                        await fetchAndSetAddress(item.address);
                                                        setAddress(item.address);
                                                        setUpdatingShipmentId(item.shipmentId);
                                                        setUpdatingIndex(index);
                                                    }}><Edit/></IconButton>}
                                                <Tooltip
                                                    title={getScoreTooltip(item.address.score)}>
                                                    <Avatar
                                                        style={{
                                                            marginLeft: "3%",
                                                            backgroundColor: item.address.score > 90 ? '#4ade80' : (item.address.score ? "#facc15" : "#f87171")
                                                        }}>{item.address.score || ""}</Avatar>
                                                </Tooltip>
                                            </ListItem>
                                            <Divider variant="inset" component="li"/>
                                        </>
                                    })}
                                </List>
                                <Dialog maxWidth={false} open={openUpdateAddress} onClose={() => {
                                    clearAddress();
                                    setOpenUpdateAddress(false)
                                }}>
                                    <DialogTitle align={"center"}>{"Aggiorna Indirizzo"}</DialogTitle>
                                    <div style={{width: "100%"}}>
                                        {loadingAddress ?
                                            <MinimalLoadingComponent/>
                                            :
                                            <div>
                                                {address && <div>
                                                    <Avatar style={{margin: "auto"}}
                                                            alt={updatingIndex + 1}>{updatingIndex + 1}</Avatar>
                                                    <Typography style={{
                                                        margin: "auto",
                                                        width: "fit-content",
                                                        marginBlock: "2%"
                                                    }}
                                                                variant={"subtitle2"}>{"Spedizione: " + updatingShipmentId}</Typography>
                                                    <Autocomplete
                                                        id="country"
                                                        size="small"
                                                        // className={classes.field}
                                                        style={{marginBottom: "5%", width: "80%", margin: "auto"}}
                                                        options={countries}
                                                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                        value={deliveryCountry}
                                                        defaultValue={deliveryCountry}
                                                        onChange={handleDeliveryCountryChange}
                                                        onSelect={fetchCountries}
                                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                                        noOptionsText={t("no_options")}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            label={t("country")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={deliveryCountry}
                                                            defaultValue={deliveryCountry}
                                                            required
                                                            error={!deliveryCountry}
                                                            helperText={!deliveryCountry ? t("required") : ""}
                                                        />}
                                                    />
                                                    {generateDeliveryAddress()}
                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                        <Button onClick={closeUpdate}
                                                                color={"inherit"}
                                                                variant={"contained"}
                                                                style={{margin: "auto"}}>{t("cancel")}</Button>
                                                        <Button onClick={confirmUpdateAddress}
                                                                variant={"contained"}
                                                                style={{
                                                                    margin: "auto",
                                                                    backgroundColor: theme.palette.primary.main
                                                                }}>{t("confirm")}</Button>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </Dialog>
                            </div>}
                    </div>
                    <div style={{width: "50%"}}>
                        {loadingOptimize ?
                            <div>
                                <LoadingComponent/>
                            </div>
                            :
                            <>
                                {coordinates.length > 2 ?
                                    <div>
                                        {renderMap()}
                                    </div>
                                    :
                                    null
                                }
                            </>
                        }
                    </div>
                </div>
            </Card>
        </>
    )


}

export default OptimizeDriverWorkList;