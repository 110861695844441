import React, {useEffect, useState} from "react";
import PrimaEdicolaQueueService from "../shipments/queues/PrimaEdicolaQueueService";
import ContractService from "../accounts/contracts/ContractService";
import {Paper, TextField} from "@material-ui/core";
import {Autocomplete} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Button, DialogTitle} from "@mui/material";
import {AppStyles} from "../theme/AppStyles";
import theme from "../theme/theme";
import {SaveOutlined} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";


function PrimaedicolaSettings(props) {


    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [loading, setLoading] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = AppStyles();

    const {t} = useTranslation();

    async function getContracts() {

        setLoading(true);

        await new ContractService().getContractsByFilters()
            .then(async result => {
                setContracts(result);

                await new PrimaEdicolaQueueService().getPrimaEdicolaContract()
                    .then(data => {
                        setContract(result.find(contract => contract.id === data.id));
                        setLoading(false)
                    })
                    .catch(error => {
                        error.response.json().then((response) => {
                            setNotifySeverity('error');
                            setNotificationMessage(response.message);
                            setOpenNotify(true);
                        });
                        setLoading(false);
                    })
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
                setLoading(false);
            })

    }

    async function setPrimaEdicolaContract() {

        setLoading(true);

        await new PrimaEdicolaQueueService().setPrimaEdicolaContract(contract.id)
            .then(result => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);
                setRefresh(!refresh);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
                setLoading(false);
            })

    }

    useEffect(() => {
        getContracts();
    }, [refresh])

    return <Paper className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

        {loading ?
            <LoadingComponent/>
            :
            <div>
                <DialogTitle className={classes.centered_title}>{t("select_contract")}</DialogTitle>
                <Autocomplete
                    id="contract"
                    size="small"
                    style={{width: "30%", margin: "auto", marginBottom: "2%"}}
                    options={contracts}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={contract}
                    defaultValue={contract}
                    noOptionsText={t("no_options")}
                    onChange={(event, contract) => setContract(contract)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contract")}
                            variant="outlined"
                            margin="dense"
                            value={contract}
                            defaultValue={contract}
                            required
                            error={!contract}
                        />
                    )}
                />
                <div style={{display: "flex"}}>
                    <Button
                        style={{
                            margin: "auto",
                            marginBlock: "2%",
                            backgroundColor: theme.palette.primary.main
                        }}
                        startIcon={<SaveOutlined/>} variant="contained" onClick={setPrimaEdicolaContract}
                        color="primary">
                        {t("save")}
                    </Button>
                </div>
            </div>
        }


    </Paper>


}

export default PrimaedicolaSettings;