import React, {useEffect, useState} from "react";
import {Card, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TextField} from "@material-ui/core";
import WarehouseService from "../../warehouses/WarehouseService";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import TripService from "../trips/TripService";
import IconButton from "@material-ui/core/IconButton";
import {SearchOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import theme from "../../theme/theme";
import DateField from "../../components/fields/DateField";
import {Autocomplete} from "@material-ui/lab";
import moment from "moment";
import SummaryItem from "./SummaryItem";
import InfoChip from "../../components/chips/InfoChip";
import UserService from "../../users/UserService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: '100%',
        minWidth: "40%",
        margin: "auto",
        marginBottom: "5%"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function SummaryOutPage(props) {

    const [trips, setTrips] = useState([]);

    const [allWarehouses, setAllWarehouses] = useState(false);

    const [dateFilter, setDateFilter] = useState(new Date());
    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [arrivalWarehouse, setArrivalWarehouse] = useState(null);
    const [departureWarehouses, setDepartureWarehouses] = useState([]);
    const [arrivalWarehouses, setArrivalWarehouses] = useState([]);

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);


    // const [pages, setPages] = useState([]);
    //
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const {t} = useTranslation();

    const history = useHistory();

    const classes = useStyles();

    async function getAllWarehouses() {
        await new UserService().me()
            .then(data => {
                setAllWarehouses(data.allWarehouses);
            })
    }

    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
        setTrips([]);
    }

    function handleArrivalWarehouseChange(event, warehouse) {
        setArrivalWarehouse(warehouse);
        setTrips([]);
    }

    function handleDateFilterChange(event) {
        setDateFilter(event);
    }

    async function fetchWarehouses() {
        let filters = {}

        filters.showAll = true;

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(response => {
                setArrivalWarehouses(response.warehouses)
            })
            .catch(error => {

            })
    }

    async function fetchAllWarehouses() {

        let filters = {}

        filters.showAll = true;

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(response => {
                setDepartureWarehouses(response.warehouses)
            })
            .catch(error => {

            })
    }

    async function search() {

        setFetching(true);

        let filters = {};

        // filters.pageNumber = page;
        // filters.pageSize = rowsPerPage;
        if (departureWarehouse) {
            filters.departureWarehouseId = departureWarehouse.id;
        }
        if (arrivalWarehouse) {
            filters.arrivalWarehouseId = arrivalWarehouse.id;
        }
        if (dateFilter) {
            filters.departureDate = moment(dateFilter).format("yyyy-MM-DD")
        }


        await new TripService().getTripsForSummary(filters)
            .then(response => {
                setTrips(response);
                setFetching(false);
                setFetched(true);
            })
            .catch(error => {
                setFetching(false);
            })

    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(event.target.value);
    // };
    //
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    //
    // };
    //
    //
    // function TablePaginationActions(props) {
    //     const classes = usePaginationStyles();
    //     const theme = useTheme();
    //     const {page} = props;
    //
    //     const handleFirstPageButtonClick = (event) => {
    //         setPage(0)
    //     };
    //
    //     const handleBackButtonClick = (event) => {
    //         setPage(page - 1)
    //     };
    //
    //     const handleNextButtonClick = (event) => {
    //         setPage(page + 1)
    //     };
    //
    //     const handleLastPageButtonClick = (event) => {
    //         setPage(pages.length - 1);
    //     };
    //
    //     return (
    //         <div className={classes.root}>
    //             <Select style={{minWidth: 50}}
    //                     labelId="page-select"
    //                     value={page}
    //                     onChange={handleChangePage}
    //                     options={pages}
    //                     defaultValue={page}
    //                     MenuProps={{
    //                         anchorOrigin: {
    //                             vertical: "bottom",
    //                             horizontal: "left"
    //                         },
    //                         transformOrigin: {
    //                             vertical: "top",
    //                             horizontal: "left"
    //                         },
    //                         getContentAnchorEl: null,
    //                         classes: {paper: classes.menuPaper}
    //                     }}
    //             >
    //                 {pages.map((pg, index) => {
    //                         return (
    //                             <MenuItem
    //                                 key={index}
    //                                 value={pg}
    //                             >
    //                                 {pg + 1}
    //                             </MenuItem>
    //                         )
    //                     }
    //                 )}
    //             </Select>
    //             <IconButton
    //                 onClick={handleFirstPageButtonClick}
    //                 disabled={page === 0 || fetching}
    //                 aria-label="first page"
    //             >
    //                 {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
    //             </IconButton>
    //             <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
    //                         aria-label="previous page">
    //                 {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
    //             </IconButton>
    //             <IconButton
    //                 onClick={handleNextButtonClick}
    //                 disabled={fetching || page >= pages.length - 1}
    //                 aria-label="next page"
    //             >
    //                 {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
    //             </IconButton>
    //             <IconButton
    //                 onClick={handleLastPageButtonClick}
    //                 disabled={fetching || page >= pages.length - 1}
    //                 aria-label="last page"
    //             >
    //                 {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
    //             </IconButton>
    //         </div>
    //     );
    // }

    function refresh() {
        setRefreshTable(!refreshTable);
        search();
    }

    useEffect(() => {
        getAllWarehouses();
    }, [])


    useEffect(() => {

    }, [refreshTable])

    useEffect(() => {
        if(departureWarehouse || arrivalWarehouse){
            search();
        }
    }, [departureWarehouse, arrivalWarehouse]);

    return <div className={classes.root}>
        <Card style={{marginBottom: "2%"}}>
            <div style={{display: "flex"}}>
                <Autocomplete
                    id="warehouse"
                    options={departureWarehouses}
                    size={"small"}
                    style={{width: "20%", margin: "auto", marginTop: "5%"}}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={departureWarehouse}
                    defaultValue={departureWarehouse}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchAllWarehouses}
                    onChange={handleDepartureWarehouseChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("departure_warehouse")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={departureWarehouse}
                            defaultValue={departureWarehouse}
                            helperText={t("no_summary_generation_without_warehouse_selected_warning")}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="warehouse"
                    options={arrivalWarehouses}
                    size={"small"}
                    style={{width: "20%", margin: "auto", marginTop: "5%"}}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={arrivalWarehouse}
                    defaultValue={arrivalWarehouse}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchWarehouses}
                    onChange={handleArrivalWarehouseChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("destination_warehouse")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={arrivalWarehouse}
                            defaultValue={arrivalWarehouse}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex"}}>
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("trip_date")}
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                    format={"dd/MM/yyyy"}
                    clearable={false}
                />
            </div>
            <div>
                <IconButton onClick={search}
                            disabled={fetching || !dateFilter}
                            style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{fetching ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>

        {fetching ?

            <LoadingComponent/>

            :

            <Paper>
                {trips.length > 0 ?
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableCell>{t("ID")}</TableCell>
                            <TableCell>{t("route")}</TableCell>
                            <TableCell>{t("warehouses")}</TableCell>
                            <TableCell>{t("destination_warehouse")}</TableCell>
                            <TableCell>{t("parcel_number")}</TableCell>
                            <TableCell>{t("departure_date")}</TableCell>
                            <TableCell>{t("supplier")}</TableCell>
                            <TableCell>{t("plate_number")}</TableCell>
                            <TableCell>{t("driver_name")}</TableCell>
                            <TableCell/>
                        </TableHead>

                        <TableBody>


                            {trips.map((trip, index) => {

                                return <SummaryItem trip={trip} warehouseCode={departureWarehouse?.code}
                                                    refresh={refresh}/>

                            })}
                        </TableBody>

                        {/*<Notification open={openNotify} severity={notifySeverity} duration={3500}*/}
                        {/*              onClose={closeNotification}>{notificationMessage}</Notification>*/}
                        {/*<TablePagination*/}
                        {/*    rowsPerPageOptions={[10, 25, 100]}*/}
                        {/*    count={-1}*/}
                        {/*    rowsPerPage={rowsPerPage}*/}
                        {/*    page={page}*/}
                        {/*    SelectProps={{*/}
                        {/*        inputProps: {'aria-label': 'rows per page'},*/}
                        {/*        native: false,*/}
                        {/*    }}*/}
                        {/*    labelRowsPerPage={t("rows_per_page")}*/}
                        {/*    labelDisplayedRows={() => {*/}
                        {/*        return t("page")*/}
                        {/*    }}*/}
                        {/*    onChangePage={handleChangePage}*/}
                        {/*    onChangeRowsPerPage={handleChangeRowsPerPage}*/}
                        {/*    ActionsComponent={TablePaginationActions}*/}
                        {/*/>*/}
                    </Table>

                    :
                    <>
                        {fetched ?
                            <div style={{paddingBlock: "1%"}}>
                                <InfoChip>{t("no_data")}</InfoChip>
                            </div>

                            :
                            null
                        }
                    </>
                }
            </Paper>

        }
    </div>


}

export default SummaryOutPage;