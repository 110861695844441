import ShipmentService from "./ShipmentService";
import React, {useState} from "react";
import {formatDate, get_file_array} from "../utils/utils";
import Button from "@material-ui/core/Button";
import {CircularProgress, Paper, Table, TableCell, TableRow, Typography} from "@mui/material";
import {Download, UploadFile} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {SaveOutlined} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";


export default function ImportShipmentFromCsvPage(props) {


    const [importedShipments, setImportedShipments] = useState([]);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingTemplateDownload, setLoadingTemplateDownload] = useState(false);

    const [labels, setLabels] = useState(null);

    const [file, setFile] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const {t} = useTranslation();


    async function postShipments(){



        setLoadingImport(true);

        let service = new ShipmentService();

        for (let i = 0; i < importedShipments.length; i++) {

            importedShipments[i].channel = "CSV WEB IMPORT";

            await service.createNewShipment(importedShipments[i])
                .then(result => {
                    let shipments = [...importedShipments];
                    shipments[i].status = t("shipment_successfully_imported");
                    shipments[i].id = result.shipmentId;
                    setImportedShipments(shipments);
                })
                .catch(error => {
                    error.response.json().then(response => {
                        let shipments = [...importedShipments];
                        shipments[i].status = response.message
                        setImportedShipments(shipments);
                    })
                })

        }

        await getLabels(importedShipments);

        setLoadingImport(false);

    }


    async function downloadTemplate() {

        setLoadingTemplateDownload(true);

        await new ShipmentService().getTemplate()
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingTemplateDownload(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingTemplateDownload(false);
                })
            })

    }

    async function getLabels(shipments){

        let shipmentIds = [];

        for (let i = 0; i < shipments.length; i++) {
            if(shipments[i].id){
                shipmentIds.push(shipments[i].id);
            }
        }

        if(shipmentIds.length === 0){
            return;
        }

        let filters = {};

        filters.idList = shipmentIds;

        await new ShipmentService().getShipmentLabelsByFilters(filters, "PDF")
            .then(result => {
                setLabels(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }


    async function importFromCsv(event) {

        setLoadingImport(true);

        let _file = event.target.files[0];

        if (_file) {
            let dataURI = await get_file_array(_file);

            let indexOfComma = dataURI.indexOf(',');

            let base64;

            if (indexOfComma === -1) {
                //TODO gestire errore
            } else {
                base64 = dataURI.substring(indexOfComma + 1);
            }

            let file = {};

            file.content = base64;

            await new ShipmentService().importFromCsv(file)
                .then(result => {
                    setImportedShipments(result);
                    setLoadingImport(false);
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setLoadingImport(false);
                    })
                })
        }
    }

    return <Paper>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <div style={{
            width: "100%",
            textAlign: 'center',
            justifyContent: "center",
            display: "block"
        }}>
            <Button
                disabled={loadingTemplateDownload}
                style={{margin: "auto", width: "fit-content", marginBlock: "5%"}}
                variant={"contained"}
                color={"primary"}
                onClick={downloadTemplate}
                startIcon={loadingTemplateDownload ? <CircularProgress size={24}/> :
                    <Download/>}>{t("download_csv_template")}
            </Button>
        </div>
        <div style={{
            width: "100%",
            textAlign: 'center',
            justifyContent: "center",
            display: "block"
        }}>
            <input
                accept="text/csv"
                id="contained-button-file"
                type="file"
                value={file}
                onChange={event => setFile(event.target.value)}
                onInput={importFromCsv}
                style={{display: "none"}}
            />
            <label htmlFor="contained-button-file">
                <Button disabled={loadingImport}
                        style={{margin: "auto", width: "fit-content", marginBlock: "5%"}}
                        variant={"contained"}
                        color={"primary"}
                        component={"span"}
                        startIcon={loadingImport ? <CircularProgress size={24}/> :
                            <UploadFile/>}>{t("upload_file")}
                </Button>
            </label>
        </div>
        {labels?.length > 0 &&  <div style={{width: "fit-content", margin: "auto"}}>
            <object style={{margin: "auto", marginBlock: "5%"}} width={400} height={400} type={'application/pdf'} data={"data:application/pdf;base64," + labels[0].content}>";
                html += "</object>
        </div>}


        {loadingImport ?
            <LoadingComponent/>
            :
            <div>
                    <Table>
                        <TableRow>
                            <TableCell >
                                <Typography variant={"subtitle2"}>{t("service")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("reference")}</Typography>
                                <Typography variant={"subtitle2"}>{t("customerReference")}</Typography>
                                <Typography variant={"subtitle2"}>{t("orderNumber")}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant={"subtitle2"}>{t("departure_warehouse")}</Typography>
                                <Typography variant={"subtitle2"}>{t("pickup_date")}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant={"subtitle2"}>{t("account")}</Typography>
                                <Typography variant={"subtitle2"}>{t("contract")}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant={"subtitle2"}>{t("sender")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("pickup")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("delivery")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("booking")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("parcels")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("notes")}</Typography>
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                        {importedShipments.map((item, index) => {
                           return <TableRow>
                               <TableCell >
                                   <Typography variant={"subtitle2"}>{item.serviceCode} </Typography>
                               </TableCell>
                               <TableCell>
                                   <Typography>{item.reference ? item.reference : "---"} </Typography>
                                   <Typography>{item.customerReference ? item.customerReference : "---"} </Typography>
                                   <Typography>{item.orderNumber ? item.orderNumber : "---"} </Typography>
                               </TableCell>
                               <TableCell >
                                   <Typography>{item.departureWarehouseCode ? item.departureWarehouseCode : "---"} </Typography>
                                   <Typography>{formatDate(item.pickupDate)}</Typography>
                               </TableCell>
                               <TableCell >
                                   <Typography>{item.accountCorporateCode} </Typography>
                                   <Typography>{item.contractCode} </Typography>
                               </TableCell>
                               <TableCell >
                                   <Typography>{item.senderAddress ? item.senderAddress.referencePerson : "---"} </Typography>
                                   <Typography>{item.senderAddress ? item.senderAddress.companyName : "---"} </Typography>
                               </TableCell>
                               <TableCell>
                                   {item.pickupAddress ?
                                       <>
                                           <Typography>{(item.pickupAddress.companyName ? item.pickupAddress.companyName : "") + (item.pickupAddress.referencePerson ? " " + item.pickupAddress.referencePerson : "")} </Typography>
                                           <Typography>{(item.pickupAddress.street ? item.pickupAddress.street : "") + (item.pickupAddress.buildingNr ? ", " + item.pickupAddress.buildingNr : "")} </Typography>
                                           <Typography>{(item.pickupAddress.postalCode ? item.pickupAddress.postalCode : "") + (item.pickupAddress.city ? " " + item.pickupAddress.city : "") + (item.pickupAddress.administrativeLevel3 ? " (" + item.pickupAddress.administrativeLevel3 + ")" : "")}</Typography>
                                       </>
                                       :
                                       null
                                   }
                               </TableCell>
                               <TableCell>
                                   {item.deliveryAddress ?
                                       <>
                                           <Typography>{(item.deliveryAddress.companyName ? item.deliveryAddress.companyName : "") + (item.deliveryAddress.referencePerson ? " " + item.deliveryAddress.referencePerson : "")} </Typography>
                                           <Typography>{(item.deliveryAddress.street ? item.deliveryAddress.street : "") + (item.deliveryAddress.buildingNr ? ", " + item.deliveryAddress.buildingNr : "")} </Typography>
                                           <Typography>{(item.deliveryAddress.postalCode ? item.deliveryAddress.postalCode : "") + (item.deliveryAddress.city ? " " + item.deliveryAddress.city : "") + (item.deliveryAddress.administrativeLevel3 ? " (" + item.deliveryAddress.administrativeLevel3 + ")" : "")}</Typography>
                                       </>
                                       :
                                       null
                                   }
                               </TableCell>
                               <TableCell>
                                       <div>
                                           {item.booking ?
                                               <>
                                                   <Typography>{new Date(item.booking.date).toLocaleDateString()}</Typography>
                                                   <Typography>{item.booking?.startTimeRange + " - " +item.booking?.endTimeRange}</Typography>
                                               </>


                                               :

                                               t("no_booking")
                                           }
                                       </div>
                               </TableCell>
                               <TableCell>
                                   {item.parcels?.map(parcel => {
                                       return <Typography>{parcel.customerBarcode}</Typography>
                                   })}
                               </TableCell>
                               <TableCell>
                                   {item.notes?.map(note => {
                                       return <Typography>{note.note}</Typography>
                                   })}
                               </TableCell>
                               <TableCell>
                                  <Typography color={item.status !== t("shipment_successfully_imported") ? "red" : "green"}>{item.status}</Typography>
                               </TableCell>
                           </TableRow>
                        })}
                    </Table>
            </div>
        }
        <div style={{ textAlign: 'center',
            justifyContent: "center"}}>
            <Button
                disabled={importedShipments.length < 1 || loadingImport}
                style={{margin: "auto", width: "fit-content", marginBlock: "5%"}}
                variant={"contained"}
                color={"primary"}
                onClick={postShipments}
                startIcon={loadingTemplateDownload ? <CircularProgress size={24}/> :
                    <SaveOutlined/>}>{t("import")}
            </Button>
        </div>


    </Paper>


}