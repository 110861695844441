import {useParams} from "react-router-dom";
import DriverWorkListService from "../driver_work_list/DriverWorkListService";
import PickupService from "../pickups/PickupService";
import ParcelService from "../shipments/services/ParcelService";
import ShipmentService from "../shipments/ShipmentService";
import CodService from "../shipments/services/CodService";
import React, {useEffect, useState} from "react";
import {Paper, Table} from "@material-ui/core";
import WarehouseService from "../warehouses/WarehouseService";
import {
    Autocomplete,
    CircularProgress,
    DialogTitle,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import GoogleApiService from "./GoogleApiService";
import DateField from "../components/fields/DateField";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import DriverService from "../drivers/DriverService";
import theme from "../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";


function RoundDetails(props) {

    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [shipmentsData, setShipmentsData] = useState([]);


    const [warehouse, setWarehouse] = useState(props.warehouse ? props.warehouse : null);
    const [warehouses, setWarehouses] = useState([]);

    const [date, setDate] = useState(new Date());

    const [driver, setDriver] = useState(props.driver ? props.driver : null);
    const [drivers, setDrivers] = useState([]);

    const {id} = useParams();

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        if (null == warehouse) {
            setDriver(null);
        }
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
        if (null == driver) {
            setShipmentsData(null);
        }
    }

    function handleDateChange(event) {
        setDate(event);
        setDriver(null)

    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }


    async function getDistanceMatrix(origin, destination) {

        return await new GoogleApiService().getDistanceMatrix(origin, destination)
            .catch(error => {
                // error.response.json().then(response => {
                //     setNotificationMessage(response.status + "-" + response.message);
                //     setNotifySeverity('error');
                //     setOpenNotify(true);
                // })
            });
    }

    async function fetchWorkList() {

        let driverWorkListId = null;

        if (id) {
            driverWorkListId = id;
        } else {
            let filters = {};

            if (warehouse) {
                filters.warehouseId = warehouse.id;
            }
            if (driver) {
                filters.driverId = driver.id;
            }
            filters.date = format(date, "yyyy-MM-dd");

            let worklists = await new DriverWorkListService().getDriverWorkLists(filters);

            driverWorkListId = worklists.driverWorkLists[0].id;
        }

        if (driverWorkListId) {

            let dwl = await new DriverWorkListService().getDriverWorkListByID(driverWorkListId)
                .catch(error => {

                })

            let warehouse = await new WarehouseService().getWarehouseById(dwl.warehouseId)
                .catch(error => {

                })

            fetchData(dwl.pickupIds, dwl.parcels, warehouse, dwl.dateTime);
        }


    }


    async function fetchData(pickupIds, driverWorkListParcels, warehouse, dateTime) {
        setLoading(true);

        let wrklst = [];
        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del ritiro: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            let data = {};
            data.shipment = {};
            data.shipment.parcels = [];
            data.shipment.deliveryAddress = pickup.address;
            data.shipment.lastEvent = {}
            data.shipment.lastEvent.dateTime = pickup.events[pickup.events.length - 1].dateTime;
            data.shipment.lastEvent.eventCode = pickup.events[pickup.events.length - 1].pickupEventCode.id;
            data.shipment.lastEvent.eventName = pickup.events[pickup.events.length - 1].pickupEventCode.name;
            data.shipment.reference = pickup.barcode;
            data.shipment.id = pickup.id;

            //data.time = new Date(now());
            //data.time.setHours(19, 0, 0, 0);

            wrklst.push(data);
        }

        for (let i = 0; i < driverWorkListParcels?.length; i++) {
            let parcelService = new ParcelService();
            let parcel = await parcelService.getParcelById(driverWorkListParcels[i].id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            const parcelIsInSomeShipment = wrklst.some(data => {
                return data.shipment.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode === parcel.barcode);
                })
            });
            if (parcelIsInSomeShipment) {
                wrklst.forEach(data => {
                    data.shipment.parcels.forEach(async _parcel => {
                        if (_parcel.parcel.barcode === parcel.barcode) {
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                });
            } else {
                if (parcel) {
                    let shipmentId = parcel.shipmentId;

                    let shipmentService = new ShipmentService();
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        });

                    let shipmentParcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        });

                    let cod = null;
                    if (shipment.codId) {
                        const codService = new CodService();
                        cod = await codService.getCodById(shipment.codId)
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage("Si è verificato un errore nel recupero del contrassegno: " + response.status + "-" + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })
                            });
                    }

                    // let warehouseStorage = null;
                    // const parcelThatCauseWarehouseStorage = shipmentParcels.find(tmpParcel => !!deliveryEventCodesThatOpensWarehouseStorages[tmpParcel.lastEvent.eventCodeId]);
                    // if (parcelThatCauseWarehouseStorage) {
                    //     let needToOpenWarehouseStorage = true;
                    //     let warehouseStorageReasonId = deliveryEventCodesThatOpensWarehouseStorages[parcelThatCauseWarehouseStorage.lastEvent.eventCodeId].openingReasonCodeId;
                    //     if (deliveryEventCodesWithMultipleAttempts.some(value => value === parcelThatCauseWarehouseStorage.lastEvent.eventCodeId)) {
                    //         // got an event code with multiple attempts
                    //         warehouseStorageReasonId = 2; // Done multiple attempts
                    //         needToOpenWarehouseStorage = driverWorkListParcels.some(workListParcel => {
                    //             return workListParcel.numOfDeliveryAttempts >= workListParcel.numOfAllowedDeliveryAttempts;
                    //         });
                    //     }
                    //
                    //     if (needToOpenWarehouseStorage) {
                    //         warehouseStorage = {
                    //             checked: true,
                    //             reasonId: warehouseStorageReasonId
                    //         };
                    //
                    //         const warehouseStorageService = new WarehouseStorageService();
                    //         const restWarehouseStorages = await warehouseStorageService.getFilteredWarehouseStorages({
                    //             shipmentId: shipment.id
                    //         })
                    //             .catch(error => {
                    //                 error.response.json().then(response => {
                    //                     setNotificationMessage("Si è verificato un errore nel recupero delle giacenze : " + response.status + "-" + response.message);
                    //                     setNotifySeverity('error');
                    //                     setOpenNotify(true);
                    //                 })
                    //             });
                    //
                    //         if (restWarehouseStorages && 0 < restWarehouseStorages.warehouseStorages.length) {
                    //             warehouseStorage = restWarehouseStorages.warehouseStorages[0];
                    //         }
                    //     }
                    // }

                    let data = {};
                    data.shipment = shipment;
                    data.shipment.cod = cod;
                    // data.warehouseStorage = warehouseStorage;

                    data.shipment.parcels = [];
                    data.driverWorkListParcels = driverWorkListParcels;
                    for (let i = 0; i < shipmentParcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = shipmentParcels[i];
                        if (shipmentParcels[i].barcode === parcel.barcode) {
                            parcelData.checked = true;
                            setRefresh(!refresh);
                        }

                        data.shipment.parcels.push(parcelData);
                    }

                    wrklst.push(data);
                }
            }
            setRefresh(!refresh);
        }

        let newaa = wrklst.filter(data => data.shipment.lastEvent.eventCode === 52 || data.shipment.lastEvent.eventCode === 10);
        let newaa2 = wrklst.filter(data => data.shipment.lastEvent.eventCode !== 52 && data.shipment.lastEvent.eventCode !== 10).sort((ship1, ship2) => new Date(ship1.shipment.lastEvent.dateTime) - new Date(ship2.shipment.lastEvent.dateTime));

        let ordered = newaa2.concat(newaa);

        for (let i = 0; i < ordered.length; i++) {

            let origin;

            let destination = ordered[i].shipment.deliveryAddress.street + " " + ordered[i].shipment.deliveryAddress.buildingNr + " " + ordered[i].shipment.deliveryAddress.postalCode + " " + ordered[i].shipment.deliveryAddress.city + " " + ordered[i].shipment.deliveryAddress.administrative_level_3;

            if (i === 0) {
                origin = warehouse.address.street + " " + warehouse.address.buildingNr + " " + warehouse.address.postalCode + " " + warehouse.address.city + " " + warehouse.address.administrative_level_3;
            } else {
                origin = ordered[i - 1].shipment.deliveryAddress.street + " " + ordered[i - 1].shipment.deliveryAddress.buildingNr + " " + ordered[i - 1].shipment.deliveryAddress.postalCode + " " + ordered[i - 1].shipment.deliveryAddress.city + " " + ordered[i - 1].shipment.deliveryAddress.administrative_level_3;
            }

            destination = destination.replace("#", "");
            origin = origin.replace("#", "");

            let response = await getDistanceMatrix(origin, destination);

            if (response.rows[0]?.elements[0]?.status !== "OK") {
                setNotificationMessage(t("an_error_has_occurred") + ordered[i].shipment.reference);
                setNotifySeverity("error");
                setOpenNotify(true);
                continue;
            }

            ordered[i].distance = response.rows[0].elements[0].distance.text + " - " + response.rows[0].elements[0].duration.text;


            if (i === 0) {
                let date = new Date(dateTime);
                ordered[i].eta = new Date(date.getTime() + (response.rows[0].elements[0].duration.value * 1000)).toISOString();
            } else {
                let date;
                if (ordered[i - 1].shipment.lastEvent.eventCode !== 52 && ordered[i - 1].shipment.lastEvent.eventCode !== 10) {
                    date = new Date(ordered[i - 1].shipment.lastEvent.dateTime);
                } else {
                    date = new Date(ordered[i - 1].eta);
                }

                ordered[i].eta = new Date(date.getTime() + (response.rows[0].elements[0].duration.value * 1000)).toISOString();
            }

        }

        setShipmentsData(ordered);


        setLoading(false);
    }


    useEffect(() => {
        if (id) {
            fetchWorkList();
        }
    }, [])

    useEffect(() => {

    }, [refresh]);


    return <Paper>
        <div>
            <div>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("round_details")}</DialogTitle>
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={handleWarehouseChange}
                    onOpen={fetchWarehouses}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("date")}
                    value={date}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                />
                <Autocomplete
                    id="driver-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={drivers}
                    disabled={!warehouse || !date} F
                    getOptionLabel={option => option.name + " " + option.surname}
                    value={driver}
                    defaultValue={driver}
                    noOptionsText={t("no_options")}
                    onChange={handleDriverChange}
                    onOpen={fetchDrivers}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver")}
                            variant="outlined"
                            margin="dense"
                            value={driver}
                            defaultValue={driver}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex"}}>
                <IconButton onClick={fetchWorkList}
                            disabled={loading || !warehouse || !date || !driver}
                            style={{backgroundColor: 'transparent', margin: "auto", marginBlock: "3%"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined
                        style={{color: loading || !warehouse || !date || !driver ? "grey" : theme.palette.primary.main}}/>}</IconButton>
            </div>
            {loading ?

                <LoadingComponent/>

                :
                <Table style={{border: "solid 2px", width: "80%", margin: "auto"}}>
                    {shipmentsData?.map(shipment => {
                        return <TableRow>
                            <TableCell>
                                {shipment.shipment.id}
                            </TableCell>
                            <TableCell>
                                {shipment.shipment.reference}
                            </TableCell>
                            <TableCell>
                                <Typography>{shipment.shipment.deliveryAddress.street + " " + shipment.shipment.deliveryAddress.buildingNr}</Typography>
                                <Typography>{shipment.shipment.deliveryAddress.postalCode + " " + shipment.shipment.deliveryAddress.city + " " + shipment.shipment.deliveryAddress.administrative_level_3}</Typography>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <Typography>{("Distanza: ")}{shipment.distance}</Typography>
                                    <Typography
                                        fontStyle={"oblique"}>{("ETA: ")}{new Date(shipment.eta).toLocaleDateString() + " - " + new Date(shipment.eta).toLocaleTimeString()}</Typography>
                                </div>
                            </TableCell>
                            <TableCell>
                                {shipment.shipment.lastEvent.eventCode !== 52 && shipment.shipment.lastEvent.eventCode !== 10 ?
                                    <div>
                                        <Typography>{shipment.shipment.lastEvent.eventName}</Typography>
                                        <Typography>{new Date(shipment.shipment.lastEvent.dateTime).toLocaleDateString() + " " + new Date(shipment.shipment.lastEvent.dateTime).toLocaleTimeString()}</Typography>
                                    </div>
                                    :
                                    null
                                }
                            </TableCell>

                        </TableRow>
                    })}
                </Table>
            }
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </Paper>


}

export default RoundDetails;