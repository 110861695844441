import {Backdrop, Button, Fade, Menu, MenuItem, Paper, TableCell, TableRow} from "@material-ui/core";
import PaymentToCustomerService from "./PaymentToCustomerService";
import MenuIcon from "@material-ui/icons/Menu";
import {CheckCircleOutlined} from "@material-ui/icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Download} from "@mui/icons-material";
import {CircularProgress, Modal} from "@mui/material";
import {useHistory} from "react-router-dom";


function PaymentToCustomer(props){


    let payment = props.payment;

    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);

    const [pdf, setPdf] = useState(null);
    const [openPdf, setOpenPdf] = useState(false);

    const [loadingCSV, setLoadingCSV] = useState(false);

    const {t} = useTranslation();

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    async function goToConfirmPaymentPage() {

        history.push(`/payments/customer/${payment.id}/confirm`);

    }

    async function getPdf() {

        await new PaymentToCustomerService().getPdf(payment.id)
            .then(data => {
                setPdf(data.content);
                setOpenPdf(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })

    }

    async function getCsv() {

        setLoadingCSV(true);

        await new PaymentToCustomerService().getCsv(payment.id)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })
        setLoadingCSV(false);

    }

    return <TableRow>
        <TableCell>
            {payment.id}
        </TableCell>
        <TableCell>
            {payment.contract.code + " - " + payment.contract.name}
        </TableCell>
        <TableCell>
            {new Date(payment.dateTime).toLocaleDateString() + " - " + new Date(payment.dateTime).toLocaleTimeString()}
        </TableCell>
        <TableCell>
            {payment.currency + " " + payment.totalAmount?.toFixed(2)}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={goToConfirmPaymentPage}><CheckCircleOutlined/>&nbsp;{t("confirm_payment")}</MenuItem>

                <MenuItem onClick={getPdf}><Download/>&nbsp;{t("download_pdf")}</MenuItem>
                <MenuItem onClick={getCsv}>{loadingCSV? <CircularProgress size={24}/> : <Download/>}&nbsp;{t("download_csv")}</MenuItem>

            </Menu>
        </TableCell>
        <Modal open={openPdf}
               onClose={()=>setOpenPdf(false)}
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={openPdf}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "fit-content",
                    marginInline: "auto",
                    marginTop: "5%",
                    height: "fit-content",
                    minHeight: "80%",
                    minWidth: "80%",
                    display: "flex"
                }}>
                    <div style={{margin: "auto", marginTop: "5%"}}>
                        <object style={{margin: "auto"}} width={800} height={800} type={'application/pdf'} data={"data:application/pdf;base64," + pdf}>";
                            html += "</object>
                    </div>
                </Paper>
            </Fade>
        </Modal>
    </TableRow>


} export default PaymentToCustomer;