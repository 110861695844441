import {CircularProgress, MenuItem, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TripService from "../trips/TripService";
import moment from "moment/moment";
import {Badge, Button, Dialog, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {Autorenew} from "@material-ui/icons";
import {Download} from "@mui/icons-material";
import Notification from "../../components/notifications/Notification";
import MasterPalletDetails from "./MasterPalletDetails";
import theme from "../../theme/theme";
import MasterPalletService from "../../masterpallets/MasterPalletService";


function SummaryItem(props) {

    let trip = props.trip;
    let warehouseCode = props.warehouseCode;

    const [summaryDate, setSummaryDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState(null);

    const [generatingSummary, setGeneratingSummary] = useState(false);
    const [openMasterPallet, setOpenMasterPallet] = useState(false);

    const [masterPallets, setMasterPallets] = useState({});

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const {t} = useTranslation();

    async function generateSummaryOut() {
        setOpenMasterPallet(false);
        setGeneratingSummary(true);

        let input = {};

        input.dateTime = moment(summaryDate).format('YYYY-MM-DDTHH:mm:ssZ');
        input.warehouseCode = warehouseCode;
        input.tripId = trip.id;
        input.masterPalletDefinitions = [];

        let warehouses = masterPallets.warehouses;

        for (let i = 0; i < warehouses?.length; i++) {
            for (let j = 0; j < warehouses[i].masterPallets.length; j++) {

                if (warehouses[i].masterPallets[j].quantity > 0) {
                    let masterPalletDefinition = {};
                    masterPalletDefinition.arrivalWarehouseId = warehouses[i].warehouse.id;
                    masterPalletDefinition.masterPalletTypeId = warehouses[i].masterPallets[j].type;
                    masterPalletDefinition.quantity = warehouses[i].masterPallets[j].quantity;

                    input.masterPalletDefinitions.push(masterPalletDefinition);
                }
            }
        }

        await new TripService().generateSummaryOut(input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setTimeout(() => {
                    setGeneratingSummary(false);
                    props.refresh();
                }, 1500)
            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setGeneratingSummary(false);
                    }
                )
            })
    }

    async function downloadSummary(id, reduced) {

        await new TripService().downloadSummary(id, reduced)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function downloadMasterPalletLabels(ids, type) {

        let filters = {};

        filters.ids = ids;

        await new MasterPalletService().getMasterPalletLabels(filters, type)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    useEffect(() => {

    })


    return <TableRow>
        <TableCell>{trip.id}</TableCell>
        <TableCell>{trip.routeName}</TableCell>
        <TableCell>{trip.warehouses.map(value => {
            return <Typography variant={"inherit"}>{value.code}</Typography>
        })}</TableCell>
        <TableCell>{trip.arrivalWarehouseCode}</TableCell>
        <TableCell>{trip.parcels?.length}</TableCell>
        <TableCell>{new Date(trip.departureDateTime).toLocaleDateString()}</TableCell>
        <TableCell>
            <Typography variant={"inherit"}>{trip.supplierAcc}</Typography>
            <Typography variant={"inherit"}>{trip.supplierName}</Typography>
        </TableCell>
        <TableCell>{trip.plateNumber}</TableCell>
        <TableCell>{trip.driverName}</TableCell>
        <TableCell align={"center"}>
            {trip.summaries.filter(summary => summary.warehouseCode === warehouseCode).map(summary => {
                return <TableRow>
                        <TableCell style={{margin: "auto"}}>
                            <Typography>{"N."}&nbsp;{summary.number}</Typography>
                        </TableCell>
                        <TableCell style={{margin: "auto"}}>
                            <Tooltip title={t("download_reduced_summary")}><Button
                                onClick={() => downloadSummary(summary.id, true)}
                                variant={"contained"}
                                endIcon={<Download/>}
                                style={{
                                    width: "fit-content",
                                    backgroundColor: props.disabled ? "grey" : theme.palette.primary.main,
                                    color: "white"
                                }}>{"R"}</Button></Tooltip>
                        </TableCell>
                        <TableCell style={{margin: "auto"}}>
                            <Tooltip title={t("download_summary")}><Button
                                variant={"contained"}
                                onClick={() => downloadSummary(summary.id, false)}
                                endIcon={<Download/>}
                                style={{
                                    width: "fit-content",
                                    backgroundColor: props.disabled ? "grey" : theme.palette.primary.main,
                                    color: "white"
                                }}>{"C"}</Button></Tooltip>
                        </TableCell>
                        <TableCell style={{margin: "auto"}}>
                        {summary?.masterPallets?.length > 0 &&

                                <Tooltip title={t("download_master_pallet_labels")}><Button
                                    variant={"contained"}
                                    onClick={() => downloadMasterPalletLabels(summary.masterPallets, "PDF")}
                                    endIcon={<Download/>}
                                    style={{
                                        width: "fit-content",
                                        backgroundColor: props.disabled ? "grey" : theme.palette.primary.main,
                                        color: "white"
                                    }}>{"MP"}</Button></Tooltip>
                        }
                        </TableCell>
                    </TableRow>
            })}
            {trip.canGenerateSummary === true ?
                <Button
                    variant={"contained"}
                    style={{
                        width: "fit-content",
                        margin: "auto",
                        marginTop: "5%",
                        backgroundColor: props.disabled ? "grey" : theme.palette.primary.main
                    }}
                    disabled={generatingSummary}
                    onClick={() => setOpenMasterPallet(true)}>{generatingSummary ?
                    <CircularProgress style={{color: "black"}} size={20}/> :
                    <Autorenew/>}&nbsp;{t("generate_summary_out")}</Button>
                :
                null
            }
        </TableCell>
        <Dialog open={openMasterPallet} onClose={() => setOpenMasterPallet(false)}>
            <MasterPalletDetails setOpen={setOpenMasterPallet}
                                 warehouseCode={warehouseCode}
                                 setNotificationMessage={setNotificationMessage}
                                 setOpenNotify={setOpenNotify}
                                 setNotifySeverity={setNotifySeverity}
                                 setMasterPallets={setMasterPallets}
                                 trip={trip}
                                 confirm={generateSummaryOut}
            />
        </Dialog>
        <Notification open={openNotify} severity={notifySeverity} duration={15000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </TableRow>


}

export default SummaryItem;