import {CircularProgress, DialogTitle, IconButton, TableBody, TableCell, TableHead, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Button, Paper, Table, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import theme from "../../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import Notification from "../../components/notifications/Notification";
import CodService from "../../shipments/services/CodService";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import DateField from "../../components/fields/DateField";
import moment from "moment";
import ReportSupplier from "./ReportSupplier";
import {CloudDownload} from "@mui/icons-material";

export default function SupplierReportPage(props) {
    const {t} = useTranslation();

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [situationAtDate, setSituationAtDate] = useState(new Date());

    const [shipments, setShipments] = useState([]);

    const [report, setReport] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingCSV, setLoadingCSV] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    async function search() {

        setLoading(true);

        let filters = {};

        if (selectedAccount) {
            filters.accountCorporateCode = selectedAccount.accountCorporateCode;
        }
        if (selectedContract) {
            filters.contractCode = selectedContract.code;
        }

        if (fromDate) {
            filters.eventsFromDate = moment(fromDate).format('yyyy-MM-DD');
        }
        if (toDate) {
            filters.eventsToDate = moment(toDate).format('yyyy-MM-DD');
        }
        if (situationAtDate) {
            filters.situationAtDate = moment(situationAtDate).format('yyyy-MM-DD');
        }

        await new CodService().getReportBySupplier(filters)
            .then(result => {
                setReport(result);

                const columns = ["toCollectWithoutCheckin", "toCollectWithCheckin", "collected", "verifiedInWarehouse", "paidOnBankAccount"];

                let shipmentIds = [];

                for (let i = 0; i < result.suppliers.length; i++) {

                    for (let j = 0; j < result.suppliers[i].warehouses.length; j++) {

                        for (let k = 0; k < columns.length; k++) {

                            result.suppliers[i].warehouses[j][columns[k]].map(item => {
                                shipmentIds.push(item.shipmentId);
                            })
                        }

                    }
                }

                setShipments(shipmentIds);
            })
            .catch(error => {
                setReport(null);

                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                });
            })

        setLoading(false);
    }

    function handleAccountChange(event, account) {
        setSelectedAccount(account);
        setSelectedContract(null);
        setContracts([]);
    }

    function handleContractChange(event, contract) {
        setSelectedContract(contract);
    }

    function fetchAccounts() {
        setLoading(true);

        let filters = {};
        filters.isCustomer = true;

        const accountService = new AccountService();
        accountService.getAccounts(filters)
            .then(data => {
                setAccounts(data);
                setLoading(false);
            })
            .catch((error) => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                });

                setLoading(false);
            });
    }

    function fetchContracts() {
        setLoading(true);

        let filters = {};
        filters.accountId = selectedAccount.id;

        const contractService = new ContractService();
        contractService.getContractsByFilters(filters)
            .then(data => {
                setContracts(data);
                setLoading(false);
            })
            .catch((error) => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                });

                setLoading(false);
            });
    }

    async function downloadCSV() {

        setLoadingCSV(true);

        let input = {};
        input.shipmentIds = []

        for (let i = 0; i < shipments.length; i++) {
            input.shipmentIds.push(shipments[i]);
        }


        await new CodService().getReportCsv(input)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch(error => {

            })

        setLoadingCSV(false);

    }

    useEffect(() => {
        fetchAccounts();
    }, [refresh]);

    return <Paper>
        <DialogTitle>{t("supplier_report")}</DialogTitle>

        <Autocomplete
            id="accountId"
            size="small"
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            options={accounts}
            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
            value={selectedAccount}
            defaultValue={selectedAccount}
            onOpen={fetchAccounts}
            noOptionsText={t("no_options")}
            onChange={handleAccountChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("customer")}
                    InputLabelProps={{shrink: true}}
                    placeholder={t("any")}
                    variant="outlined"
                    margin="dense"
                    value={selectedAccount}
                    defaultValue={selectedAccount}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <Autocomplete
            id="contractId"
            size="small"
            disabled={!selectedAccount}
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            options={contracts}
            getOptionLabel={option => option.code + " - " + option.name}
            value={selectedContract}
            defaultValue={selectedContract}
            onOpen={fetchContracts}
            noOptionsText={t("no_options")}
            onChange={handleContractChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("contract")}
                    InputLabelProps={{shrink: true}}
                    placeholder={t("any")}
                    variant="outlined"
                    margin="dense"
                    value={selectedContract}
                    defaultValue={selectedContract}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />

        <div style={{display: "flex"}}>
            <DateField
                style={{margin: "auto", width: "20%"}}
                label={t("from_date")}
                value={fromDate}
                onChange={(event) => setFromDate(event)}
                format={"dd/MM/yyyy"}
                disableFuture={true}
            />
            <DateField
                style={{margin: "auto", width: "20%"}}
                label={t("to_date")}
                value={toDate}
                onChange={(event) => setToDate(event)}
                format={"dd/MM/yyyy"}
                disableFuture={true}
            />
        </div>

        <div style={{display: "flex"}}>
            <DateField
                style={{margin: "auto", width: "20%"}}
                label={t("situation_at_date")}
                value={situationAtDate}
                onChange={(event) => setSituationAtDate(event)}
                format={"dd/MM/yyyy"}
                disableFuture={true}
            />
        </div>

        <IconButton onClick={search}
                    disabled={loading}
                    style={{
                        display: "flex",
                        backgroundColor: 'transparent',
                        margin: "auto",
                        marginBottom: "2%"
                    }}>{loading ?
            <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
            <SearchOutlined style={{color: theme.palette.primary.main}}/>}
        </IconButton>

        {shipments.length > 1 ?
            <div style={{display: "flex"}}>
                <Button style={{
                    width: "fit-content",
                    margin: "auto",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    marginBottom: "5%"
                }} disabled={loadingCSV || shipments.length < 1} onClick={downloadCSV}
                        endIcon={loadingCSV ?
                            <CircularProgress size={24} style={{color: "white"}}/> :
                            <CloudDownload/>}>{loadingCSV ? t("please_wait") : t("download_csv")}</Button>
            </div>
            :
            null
        }

        {
            !report ? null :
                <div>
                    {/*<div>{JSON.stringify(report)}</div>*/}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{t("supplier")}</TableCell>
                                <TableCell>{t("to_collect_without_checkin")}</TableCell>
                                <TableCell>{t("to_collect_with_checkin")}</TableCell>
                                <TableCell>{t("collected")}</TableCell>
                                <TableCell>{t("verified_in_warehouse")}</TableCell>
                                <TableCell>{t("paid_on_bank_account")}</TableCell>
                                <TableCell>{t("exposition")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                        </TableBody>
                        {
                            !report ? null :
                                report.suppliers.map(supplier => {
                                    return <ReportSupplier supplier={supplier}/>
                                })
                        }
                    </Table>
                </div>
        }

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </Paper>
}