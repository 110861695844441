import {Button, Paper} from "@material-ui/core";
import React, {useState} from "react";
import AccountService from "../../accounts/AccountService";
import RouteService from "../routes/RouteService";
import {DialogTitle, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import {ArrowBackRounded, SaveOutlined} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import TripService from "./TripService";
import moment from "moment";
import Notification from "../../components/notifications/Notification";
import VehicleService from "../../vehicles/VehicleService";


function TripsForm(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const [supplier, setSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [route, setRoute] = useState(null);
    const [routes, setRoutes] = useState([]);

    const [departureDateTime, setDepartureDateTime] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleType, setVehicleType] = useState(null);

    const [plateNumber, setPlateNumber] = useState("");
    const [driverName, setDriverName] = useState("");

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    async function fetchSuppliers() {

        let filters = {};
        filters.isSupplier = true;

        let customers = await new AccountService().getAccounts(filters);
        setSuppliers(customers)
    }

    async function fetchRoutes() {

        await new RouteService().getRoutes()
            .then(response => {
                setRoutes(response.routes)
            })
            .catch(error => {

            })
    }

    async function fetchVehicleTypes(){
        await new VehicleService().getVehicleTypes()
            .then(result => {
                setVehicleTypes(result);
            })
            .catch(error => {

            })
    }

    function handleSupplierChange(event, supplier) {
        setSupplier(supplier);
    }

    function handleRouteChange(event, route) {
        setRoute(route)
    }

    function handleDepartureDateTimeChange(event) {
        setDepartureDateTime(event);
    }

    function handleVehicleTypeChange(event, type){
        setVehicleType(type);
    }

    function handlePlateNumberChange(event) {
        setPlateNumber(event.target.value);
    }

    function handleDriverNameChange(event) {
        setDriverName(event.target.value);
    }

    function goBack(){
        history.goBack();
    }

    async function saveTrip() {

        let trip = {};

        trip.supplierId = supplier.id;
        trip.routeId = route.id;
        trip.departureDateTime = moment(departureDateTime).format('YYYY-MM-DDTHH:mm:ssZ');
        trip.vehicleTypeId = vehicleType.id;
        trip.plateNumber = plateNumber;
        trip.driverName = driverName;

        await new TripService().saveTrip(trip)
            .then(success => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)

                setTimeout(() => {
                        history.push("/trips");
                    }, 2000
                )
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }


    return <Paper style={{width: "100%"}}>
        <Button
            style={{margin: "2%", background: "transparent"}}
            onClick={goBack}
            startIcon={<ArrowBackRounded/>}
            variant={"filled"}
        >
            {t("back")}
        </Button>
        <DialogTitle>{t("new_trip")}</DialogTitle>
        <Autocomplete
            id="supplier"
            size="small"
            style={{width: "15%", margin: "auto", marginBottom: "1%"}}
            options={suppliers}
            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
            value={supplier}
            defaultValue={supplier}
            onOpen={fetchSuppliers}
            noOptionsText={t("no_options")}
            onChange={handleSupplierChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("supplier")}
                    size={"small"}
                    variant="outlined"
                    margin="dense"
                    value={supplier}
                    defaultValue={supplier}
                />
            )}
        />
        <Autocomplete
            id="route"
            size="small"
            style={{width: "15%", margin: "auto", marginBottom: "2%"}}
            options={routes}
            getOptionLabel={option => option.name}
            value={route}
            defaultValue={route}
            onOpen={fetchRoutes}
            noOptionsText={t("no_options")}
            onChange={handleRouteChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("route")}
                    size={"small"}
                    variant="outlined"
                    margin="dense"
                    value={route}
                    defaultValue={route}
                />
            )}
        />
        <DateField
            style={{margin: "auto", width: "15%"}}
            label={t("departure_date")}
            value={departureDateTime}
            onChange={handleDepartureDateTimeChange}
            format={"dd/MM/yyyy"}
            disableFuture={false}
        />
        <Autocomplete
            id="vehicleType"
            size="small"
            style={{width: "15%", margin: "auto", marginBlock: "2%"}}
            options={vehicleTypes}
            getOptionLabel={option => option.type}
            value={vehicleType}
            defaultValue={vehicleType}
            onOpen={fetchVehicleTypes}
            noOptionsText={t("no_options")}
            onChange={handleVehicleTypeChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("vehicle_type")}
                    size={"small"}
                    variant="outlined"
                    margin="dense"
                    value={vehicleType}
                    defaultValue={vehicleType}
                />
            )}
        />
        <TextField
            style={{display: "flex", width: "15%", margin: "auto", marginBlock: "2%"}}
            label={t("plate_number")}
            size={"small"}
            variant="outlined"
            margin="dense"
            value={plateNumber}
            defaultValue={plateNumber}
            onChange={handlePlateNumberChange}
        />
        <TextField
            style={{display: "flex", width: "15%", margin: "auto", marginBottom: "1%"}}
            label={t("driver_name")}
            size={"small"}
            variant="outlined"
            margin="dense"
            value={driverName}
            defaultValue={driverName}
            onChange={handleDriverNameChange}
        />
        <div style={{width: "100%", display: "flex"}}>
            <Button disabled={!supplier || !route || !departureDateTime || !vehicleType}
                    onClick={saveTrip} style={{
                width: "fit-content",
                margin: "auto", marginBlock: "2%", backgroundColor: theme.palette.primary.main,
                color: "white",
            }} endIcon={<SaveOutlined/>}>{t("save")}</Button>
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </Paper>


}

export default TripsForm;