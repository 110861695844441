import {
    Checkbox,
    FormControlLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableCell,
    TableContainer,
    TablePagination,
    useTheme
} from "@material-ui/core";
import DateField from "../../components/fields/DateField";
import {
    Autocomplete,
    Card,
    CircularProgress,
    DialogTitle,
    IconButton,
    TableBody,
    TableHead,
    TextField,
    Typography
} from "@mui/material";
import theme from "../../theme/theme";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import BankAccountService from "../../bank_accounts/BankAccountService";
import WarehouseService from "../../warehouses/WarehouseService";
import InfoChip from "../../components/chips/InfoChip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StyledButton from "../../components/buttons/StyledButton";
import {useHistory} from "react-router-dom";
import PaymentSlipsService from "./PaymentSlipsService";
import {makeStyles} from "@material-ui/core/styles";
import PaymentSlip from "./PaymentSlip";
import Notification from "../../components/notifications/Notification";
import CompanyService from "../../companies/CompanyService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function PaymentSlipsTable(props) {


    const {t} = useTranslation();

    const history = useHistory();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);

    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState(null);

    const [toBePaidOnly, setToBePaidOnly] = useState(true);
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));

    const [pages, setPages] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [dateTo, setDateTo] = useState(moment())

    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [payments, setPayments] = useState([]);

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };

    function search() {
        setPage(0);
        setRefresh(!refresh);
    }

    async function getPayments() {

        setLoading(true);

        let filters = {};

        if (company) {
            filters.companyId = company.id;
        }
        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (toBePaidOnly === true) {
            filters.isFullyPaid = false;
        }

        if (bankAccount) {
            filters.bankAccountId = bankAccount.id;
        }

        filters.dateFrom = moment(dateFrom).format('yyyy-MM-DD');
        filters.dateTo = moment(dateTo).format('yyyy-MM-DD');

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        await new PaymentSlipsService().getPaymentSlipHeads(filters)
            .then(result => {
                setPayments(result.paymentSlips)
                setPages(Array.from(Array(Math.ceil(result.count / rowsPerPage)).keys()));
            })
            .catch(error => {

            })

        setLoading(false);
        setFetched(true);

    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleCompanyChange(event, company) {
        setCompany(company);
        setBankAccount(null);
    }

    function handleBankAccountChange(event, account) {
        setBankAccount(account);
    }

    function handleToBePaidOnlyChange(event) {
        setToBePaidOnly(event.target.checked);
    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })

    }

    async function fetchCompanies() {

        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchBankAccounts() {

        let filters = {};

        filters.companyId = company.id;

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })

    }

    function failureCallback(message) {

        setNotificationMessage(message.status + " - " + message.message);
        setNotifySeverity('error');
        setOpenNotify(true);

    }

    useEffect(() => {
        getPayments();
    }, [refresh, page, rowsPerPage])


    return <Paper style={{
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
    }}>
        <DialogTitle style={{margin: "auto"}}>{t("payment_slips")}</DialogTitle>
        <Card style={{
            width: "90%",
            margin: "auto",
            marginTop: "3%"
        }}>
            <div style={{display: "flex"}}>
                <DateField
                    style={{width: "15%", margin: "auto"}}
                    label={t("date_from")}
                    value={dateFrom}
                    onChange={handleDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
                <DateField
                    style={{width: "15%", margin: "auto"}}
                    label={t("date_to")}
                    value={dateTo}
                    onChange={handleDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
            </div>

            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "40%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <Autocomplete
                id="companies"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "40%", margin: "auto"}}
                options={companies}
                getOptionLabel={option => option.companyName}
                value={company}
                defaultValue={company}
                noOptionsText={t("no_options")}
                onOpen={fetchCompanies}
                onChange={handleCompanyChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("company")}
                        variant="outlined"
                        margin="dense"
                        value={company}
                        defaultValue={company}
                    />
                )}
            />
            <Autocomplete
                id="bank-account"
                size="small"
                disabled={!company}
                style={{marginBottom: "2%", width: "fit-content", minWidth: "40%", margin: "auto"}}
                options={bankAccounts}
                getOptionLabel={option => option.bankName + " - " + option.iban}
                value={bankAccount}
                defaultValue={bankAccount}
                noOptionsText={t("no_options")}
                onOpen={fetchBankAccounts}
                onChange={handleBankAccountChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("bank_account")}
                        variant="outlined"
                        margin="dense"
                        value={bankAccount}
                        defaultValue={bankAccount}
                    />
                )}
            />
            <FormControlLabel
                style={{marginTop: "5%"}}
                label={<Typography fontSize={12}
                                   variant={"button"}>{t("show_to_be_paid_only")}</Typography>}
                control={
                    <Checkbox
                        style={{color: theme.palette.primary.main}}
                        checked={toBePaidOnly}
                        onChange={handleToBePaidOnlyChange}
                    />
                }
                labelPlacement={"right"}
            />
            <div style={{margin: "auto"}}>
                <IconButton onClick={search}
                            disabled={loading}
                            style={{backgroundColor: 'transparent', margin: "auto", marginBlock: "3%"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>

        <StyledButton onClick={() => history.push("/payments/new")}
                      icon={<AddCircleIcon/>}>{t("new_payment")}</StyledButton>

        {fetched ?
            <div>
                {payments.length > 0 ?
                    <TableContainer style={{width: "90%", margin: "auto"}}>
                        <Table>
                            <TableHead>
                                <TableCell align={"center"}>
                                    {t("payment_slip_number")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("warehouse")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("supplier")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("total_payment_slip")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("payment_slip_date")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("amount_to_pay")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("total_transactions")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("transaction_number")}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {t("transaction_amount")}
                                </TableCell>
                                <TableCell/>
                            </TableHead>
                            <TableBody>
                                {payments.map(payment => {
                                    return <PaymentSlip payment={payment} failureCallback={failureCallback}/>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'}
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableContainer>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }

            </div>

            :
            null
        }

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </Paper>

}

export default PaymentSlipsTable;