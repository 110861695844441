import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

const userService = new UserService();

export default class DriverWorkListService {


    getDriverWorkListByID(id){

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/driver-work-lists/${id}`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }


    getDriverTruckLoad(driverID) {

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/driver-work-lists/${driverID}/truck-load`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }


    getDetailedWorkLists(filters) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/details`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCsvDetails(driverWorkListId){

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}/detail-csv`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    loadParcel(driverId, barcodes) {


        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                driverId: driverId,
                barcodes: barcodes
            })
        };

        return fetch(`/api/v1/driver-work-lists/truck-load`, requestOption)
            .then(checkStatus)
            .then(response => response.json());
    }

    addParcelToExistingWorkList(driverWorkListId, barcodes) {


        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                barcodes: barcodes
            })
        };

        return fetch(`/api/v1/driver-work-lists/${driverWorkListId}/add`, requestOption)
            .then(checkStatus)
            .then(response => response.json());
    }

    unloadParcel(driverId, parcelId, pickupId) {


        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                driverId: driverId,
                parcelId: parcelId,
                pickupId: pickupId
            })
        };

        return fetch(`/api/v1/driver-work-lists/truck-load`, requestOption)
            .then(checkStatus)
    }

    createDriverWorkList(request) {

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                driverId: request.driverId,
                vehicleId: request.vehicleId,
                dateTime: request.dateTime,
                onlyConfirmed: request.onlyConfirmed,
                delayed: request.delayed
            })
        };

        return fetch(`/api/v1/driver-work-lists`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }


    confirmDriverWorkList(id){

        const apiKey = userService.getApiKey();


        const requestOption = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/driver-work-lists/${id}/confirm`, requestOption)
            .then(checkStatus)


    }

    getDriverWorkLists(filters) {
        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getDriverWorkListsForClosure(filters) {
        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/closure`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getDriverWorkListDocument(driverWorkListId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}/document`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    deleteDriverWorkList(driverWorkListId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }

    getOptimizedWorkList(driverWorkListId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}/optimized`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getPolyline(driverWorkListId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}/polyline`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    optimizeDriverWorkList(driverWorkListId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/driver-work-lists/${driverWorkListId}/optimize`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            // .then(response => response.json());
    }
}