import {Button, Grid, Paper, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Autocomplete} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DialogTitle, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ContractService from "../../accounts/contracts/ContractService";
import {ArrowBackRounded, Save} from "@material-ui/icons";
import StyledButton from "../../components/buttons/StyledButton";
import {useHistory, useParams} from "react-router-dom";
import AccountService from "../../accounts/AccountService";
import RatingOptionService from "./RatingOptionService";
import Notification from "../../components/notifications/Notification";
import {LoadingComponent} from "../../components/loading/LoadingComponent";


function RatingOptionForm(props) {

    const {t} = useTranslation();

    const {id} = useParams();

    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [minScore, setMinScore] = useState(1);
    const [maxScore, setMaxScore] = useState(10);

    const [priority, setPriority] = useState(0);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [locales, setLocales] = useState([
        {language: "it", text: ""},
        {language: "en", text: ""},
        {language: "es", text: ""},
        {language: "fr", text: ""},
        {language: "de", text: ""}
    ]);

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        setContracts([]);
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isCustomer: true})
            .then(data => {
                setAccounts(data)
            })
            .catch((err) => {

            })
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = account.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }

    async function fetchOption() {

        setLoading(true);

        await new RatingOptionService().getOptionById(id)
            .then(async response => {
                response.locales.map(locale => {
                    for (let i = 0; i < locales.length; i++) {
                        if (locales[i].language === locale.language) {
                            locales[i].text = locale.text;
                        }
                    }
                })
                setMinScore(response.minScore);
                setMaxScore(response.maxScore);
                setPriority(response.priority);

                await new AccountService().getAccounts()
                    .then(async result => {
                        let account = result.find(account => account.accountCorporateCode === response.accountCorporateCode);
                        setAccount(account);
                        await new ContractService().getContractsByFilters({accountId: account.id})
                            .then(contracts => {
                                setContract(contracts.find(contract => contract.code === response.contractCode));
                            })
                            .catch(error => {

                            })
                    })
                    .catch(error => {

                    })

                setLoading(false);
            })
            .catch(error => {

            })

    }

    async function saveOption() {

        let input = {};

        if (account && !contract) {
            setNotificationMessage(t("select_contract_first"));
            setNotifySeverity("error");
            setOpenNotify(true);
            return;
            //TODO error
        }

        if (account) {
            input.accountCorporateCode = account.accountCorporateCode;
            input.contractCode = contract.code;
        }

        input.minScore = minScore;
        input.maxScore = maxScore;

        input.priority = priority;

        input.locales = [];

        for (let i = 0; i < locales.length; i++) {
            if (locales[i].text !== null && locales[i].text !== "") {
                input.locales.push(locales[i]);
            }
        }

        if (minScore > maxScore) {
            setNotificationMessage(t("min_score_greater_than_max_score"));
            setNotifySeverity("error");
            setOpenNotify(true);
            return;
        }

        if (input.locales.length === 0) {
            setNotificationMessage(t("insert_at_least_one_locale"));
            setNotifySeverity("error");
            setOpenNotify(true);
            return;
        }

        if(id){
            await new RatingOptionService().updateRatingOption(id, input)
                .then(response => {
                    setNotificationMessage(t("successful"));
                    setNotifySeverity("success");
                    setOpenNotify(true);
                    setTimeout(()=>{
                        history.push("/rating-options")
                    }, 3000)

                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            await new RatingOptionService().saveNewRatingOption(input)
                .then(response => {
                    setNotificationMessage(t("successful"));
                    setNotifySeverity("success");
                    setOpenNotify(true);
                    setTimeout(()=>{
                        history.push("/rating-options")
                    }, 3000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }

    }

    function goBack() {
        history.goBack();
    }


    function renderLocaleForm(locale, index) {

        return <Grid item style={{minWidth: "45%"}}>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <Chip style={{width: "5%", marginLeft: "5%", marginTop: "1%"}}
                      label={<Typography variant={"button"}>{locale.language}</Typography>}/>
                <TextField
                    style={{width: "70%", marginLeft: "5%"}}
                    variant={"outlined"}
                    multiline
                    minRows={1}
                    size={"medium"}
                    label={t("text")}
                    value={locale.text}
                    defaultValue={locale.text}
                    onChange={event => {
                        let newLocales = [...locales];
                        newLocales[index].text = event.target.value;
                        setLocales(newLocales);
                    }}
                />
            </div>
        </Grid>

    }

    useEffect(() => {
        if (id) {
            fetchOption();
        }
    }, [])

    return <Paper>

        {loading ?
            <LoadingComponent/>
            :
            <div>
                <Button
                    style={{margin: "2%", background: "transparent"}}
                    onClick={goBack}
                    startIcon={<ArrowBackRounded/>}
                    variant={"filled"}
                >
                    {t("back")}
                </Button>
                <div style={{display: "flex"}}>
                    <DialogTitle style={{margin: "auto"}}><Typography
                        variant={"button"}>{t("rating_option")}</Typography></DialogTitle>
                </div>

                <Autocomplete
                    id="accounts"
                    size="small"
                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                    options={accounts}
                    getOptionLabel={account => account.accountCorporateCode + " - " + account.companyName}
                    value={account}
                    defaultValue={account}
                    onOpen={fetchAccounts}
                    onChange={handleAccountChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        label={t("account")}
                        variant="outlined"
                        margin="dense"
                        value={account}
                        defaultValue={account}
                    />}
                />
                <Autocomplete
                    id="contract"
                    size="small"
                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                    options={contracts}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={contract}
                    disabled={!account}
                    defaultValue={contract}
                    onOpen={fetchContracts}
                    noOptionsText={t("no_options")}
                    onChange={handleContractChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contract")}
                            size={"small"}
                            variant="outlined"
                            margin="dense"
                            value={contract}
                            defaultValue={contract}
                        />
                    )}
                />
                <div style={{marginBlock: "5%", display: "flex"}}>
                    <CurrencyTextField
                        label={t("min_score")}
                        variant="outlined"
                        outputFormat="number"
                        margin="dense"
                        minimumValue={1}
                        maximumValue={10}
                        currencySymbol={''}
                        onChange={event => {
                            setMinScore(event.target.value)
                        }}
                        value={minScore}
                        decimalPlaces={0}
                        style={{width: "10%", margin: "auto"}}
                    />
                    <CurrencyTextField
                        label={t("max_score")}
                        variant="outlined"
                        outputFormat="number"
                        margin="dense"
                        minimumValue={1}
                        maximumValue={10}
                        currencySymbol={''}
                        onChange={event => {
                            setMaxScore(event.target.value)
                        }}
                        value={maxScore}
                        decimalPlaces={0}
                        style={{width: "10%", margin: "auto"}}
                    />
                </div>
                <div style={{marginBlock: "5%", display: "flex"}}>
                    <CurrencyTextField
                        label={t("priority")}
                        variant="outlined"
                        outputFormat="number"
                        margin="dense"
                        minimumValue={0}
                        currencySymbol={''}
                        onChange={event => {
                            setPriority(event.target.value)
                        }}
                        value={priority}
                        decimalPlaces={0}
                        style={{width: "10%", margin: "auto"}}
                    />
                </div>

                <div>
                    {locales.map((locale, index) => {
                        return renderLocaleForm(locale, index)
                    })}
                </div>

                <div style={{margin: "auto", width: "fit-content", marginBlock: "2%"}}>
                    <StyledButton onClick={saveOption} endIcon={<Save/>}>
                        {t("save")}
                    </StyledButton>
                </div>
            </div>
        }
        <Notification open={openNotify} severity={notifySeverity} duration={100000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>


    </Paper>


}

export default RatingOptionForm;