import React, {useEffect, useState} from "react";
import {AppStyles} from "../../theme/AppStyles";
import {useTranslation} from "react-i18next";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import ShipmentService from "../../shipments/ShipmentService";
import CompanyService from "../../companies/CompanyService";
import CodService from "../../shipments/services/CodService";
import moment from "moment";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CircularProgress,
    DialogTitle,
    IconButton,
    TableCell
} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import {Button, Checkbox, FormControlLabel, Table, TableRow, TextField, Typography} from "@material-ui/core";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import {
    ArrowBackRounded,
    CheckCircleOutlined,
    ErrorOutlined,
    ExpandMore,
    SaveOutlined,
    SearchOutlined
} from "@material-ui/icons";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import AmountChip from "../../components/chips/AmountChip";
import Chip from "@mui/material/Chip";
import InfoChip from "../../components/chips/InfoChip";
import Notification from "../../components/notifications/Notification";
import ChequePaymentToCustomerService from "./ChequePaymentToCustomerService";
import {useHistory} from "react-router-dom";


function NewChequePaymentToCustomerPage(props) {


    const [inError, setInError] = useState(false);

    const classes = AppStyles();

    const history = useHistory();

    const {t} = useTranslation();

    const [expanded, setExpanded] = React.useState("null");

    function handleChange(panel) {

        if (panel === expanded) {
            setExpanded("null");
        } else {
            setExpanded(panel);
        }

    }

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);

    const [dataset, setDataset] = useState(null);

    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);

    const [currency, setCurrency] = useState(null);
    const [currencies, setCurrencies] = useState([]);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [labelContent, setLabelContent] = useState(null);
    const [pdfContents, setPdfContents] = useState(null);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [labelError, setLabelError] = useState(false);

    const [codStatus, setCodStatus] = useState('bankAccountVerified');

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const [paymentDate, setPaymentDate] = useState(new Date());

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleCurrencyChange(event, currency) {
        setCurrency(currency);
    }

    function handleCodStatusChange(event) {
        setCodStatus(event.target.value);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleCompanyChange(event, company) {
        setCompany(company);
    }

    function handlePaymentDateChange(event) {
        setPaymentDate(event);
    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }


    function fetchAccounts() {

        let filters = {};

        filters.isCustomer = true;

        const accountService = new AccountService();
        accountService.getAccounts(filters)
            .then(data => {
                setAccounts(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContracts(response);
            })

    }

    function fetchCurrencies() {
        let shipmentService = new ShipmentService();
        shipmentService.getCurrencies()
            .then(currencies => {
                setCurrencies(currencies);
                setCurrency(currencies[0]);
            })
            .then(error => {

            })
    }

    function fetchCompanies() {
        let companyService = new CompanyService();
        companyService.getCompanies()
            .then(response => {
                setCompanies(response)
            })
            .catch(error => {

            })
    }

    async function fetchCods() {

        setLoading(true);

        setExpanded("null");

        let filters = {};

        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (currency) {
            filters.currency = currency.code
        }

        filters.hasEventWithCode = [5,6];

        filters.isInChequePaymentToCustomer = false;

        filters.isAdminCenterVerified = true;

        await new CodService().getCods(filters)
            .then(result => {

                const map = {};

                for (let i = 0; i < result.length; i++) {

                    let mapRecord = map[result[i].contractCode]

                    if (!mapRecord) {
                        mapRecord = {
                            error: null,
                            success: false,
                            allChecked: true,
                            shipmentId: '',
                            generateShipment: true,
                            contractCode: result[i].contractCode,
                            contractId: result[i].contractId,
                            accountCompanyName: result[i].accountCompanyName,
                            cods: []
                        };
                    }

                    let item = result[i];

                    item.checked = true;

                    mapRecord.cods.push(item);

                    map[result[i].contractCode] = mapRecord;
                }

                let data = [];

                for (const item in map) {

                    data.push(map[item]);

                }

                setDataset(data);

                setLoading(false);

            })
            .catch(error => {


                setLoading(false);

            })


    }

    function calculateDynamicTotal(dataset) {

        let total = 0;

        if (dataset) {
            for (let i = 0; i < dataset.length; i++) {

                for (let j = 0; j < dataset[i].cods.length; j++) {

                    if (dataset[i].cods[j].checked) {
                        total += dataset[i].cods[j].value;
                    }
                }

            }
        }


        return total;
    }


    function calculateTotal(cods) {

        let total = 0;

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked) {
                total += cods[i].value;
            }
        }

        return total;
    }

    function handleAllChecked(event, data) {
        data.allChecked = !!event.target.checked

        for (let i = 0; i < data.cods.length; i++) {
            data.cods[i].checked = !!event.target.checked;

        }

        setRefresh(!refresh);

    }

    function handleChecked(event, cod) {

        cod.checked = !!event.target.checked;

        // if (cods.some(row => row.checked === false)) {
        //     setAllChecked(false);
        // } else {
        //     setAllChecked(true);
        // }

        setRefresh(!refresh);

    }


    function handleShipmentIdChange(event, key) {

        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            dataset[key].shipmentId = event.target.value;
        }

        setRefresh(!refresh);
    }

    function handleGenerateShipmentChange(event, key) {
        dataset[key].generateShipment = event.target.checked;
        dataset[key].shipmentId = '';

        setRefresh(!refresh);
    }

    async function savePayments() {

        let shipmentIds = [];
        let chequePaymentToCustomerIds = [];

        for (let i = 0; i < dataset.length; i++) {

            if (!dataset[i].success) {
                let input = {};

                input.contractId = dataset[i].contractId;
                if (company) {
                    input.companyId = company.id;
                }

                if (currency) {
                    input.currencyId = currency.id;
                }

                if (paymentDate) {
                    input.dateTime = moment(paymentDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }

                if (dataset[i].shipmentId && dataset[i].shipmentId !== '') {
                    input.shipmentId = dataset[i].shipmentId;
                }

                if (dataset[i].generateShipment && dataset[i].generateShipment === true) {
                    input.generateShipment = true;
                }

                let codIds = [];

                for (let j = 0; j < dataset[i].cods.length; j++) {
                    if (dataset[i].cods[j].checked) {
                        codIds.push(dataset[i].cods[j].id)
                    }
                }

                input.cods = codIds;


                await new ChequePaymentToCustomerService().newChequePaymentToCustomer(input)
                    .then(success => {
                        dataset[i].error = null;
                        dataset[i].success = true;
                        if (success.shipmentId) {
                            shipmentIds.push(success.shipmentId);
                        }

                        chequePaymentToCustomerIds.push(success.id);

                        setRefresh(!refresh);
                    })
                    .catch(error => {
                        setInError(true);
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                            dataset[i].error = response.message;
                            setRefresh(!refresh);
                        })
                    })
            }

        }

        if (shipmentIds.length > 0) {
            await fetchLabels(shipmentIds);
        }

        if (chequePaymentToCustomerIds.length > 0) {
            // await fetchLabels(shipmentIds);

            for (let i = 0; i < chequePaymentToCustomerIds.length; i++) {

                let data = {ids: chequePaymentToCustomerIds}

                await fetchMultiplePdf(data);
            }
        }


    }

    async function fetchMultiplePdf(data) {

        await new ChequePaymentToCustomerService().getPdfMultiple(data)
            .then(result => {
                setPdfContents(result.content);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchPdf(id) {

        await new ChequePaymentToCustomerService().getPdf(id)
            .then(result => {
                const newPdfContents = [...pdfContents, result.content];
                setPdfContents(newPdfContents);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchLabels(shipmentIds) {

        setLoadingLabel(true);

        let filters = {};
        filters.idList = shipmentIds;


        await new ShipmentService().getShipmentLabelsByFilters(filters, "PDF")
            .then(result => {
                setLabelContent(result[0].content)
                setLoadingLabel(false)
                setLabelError(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    if (response.status === 425) {
                        setTimeout(() => {
                            fetchLabels(shipmentIds);
                        }, 5000)
                    } else {
                        setLoadingLabel(false);
                        setLabelError(true);
                    }
                })
            })

    }


    useEffect(() => {
        if (currencies.length === 0) {
            fetchCurrencies();
        }
    }, [refresh])

    return <div className={classes.root}>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={() => history.goBack()}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>
        <Card style={{width: "90%", margin: "auto"}}>
            <div className={classes.select_div}>
                <Autocomplete
                    id="account"
                    options={accounts}
                    className={classes.select}
                    getOptionLabel={option => option.companyName}
                    value={account}
                    defaultValue={account}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchAccounts}
                    onChange={handleAccountChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("account")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={account}
                            defaultValue={account}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="contract"
                    disabled={!account}
                    options={contracts}
                    className={classes.select}
                    getOptionLabel={option => option.code}
                    value={contract}
                    defaultValue={contract}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchContracts}
                    onChange={handleContractChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contract")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={contract}
                            defaultValue={contract}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="currency"
                    options={currencies}
                    className={classes.select}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={currency}
                    defaultValue={currency}
                    noOptionsText={t("no_options_available")}
                    // onOpen={fetchCurrencies}
                    onChange={handleCurrencyChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("currency")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={currency}
                            defaultValue={currency}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <div style={{display: "flex", margin: "auto", width: "50%"}}>
                    <DateField
                        style={{width: "30%", margin: "auto"}}
                        label={t("date_from")}
                        value={dateFrom}
                        onChange={handleDateFromChange}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={true}
                    />
                    <DateField
                        style={{width: "30%", margin: "auto"}}
                        label={t("date_to")}
                        value={dateTo}
                        onChange={handleDateToChange}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={true}
                    />
                </div>
            </div>
            <IconButton onClick={fetchCods}
                        disabled={loading}
                        style={{
                            display: "flex",
                            backgroundColor: 'transparent',
                            margin: "auto",
                            marginBottom: "2%"
                        }}>{loading ?
                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
        </Card>
        {loading ?

            <LoadingComponent/>
            :
            <div>

                {dataset && dataset.length > 0 ?
                    <div>
                        <Card style={{width: "90%", margin: "auto", marginTop: "2%"}}>
                            <DialogTitle
                                style={{width: "fit-content", margin: "auto"}}>{t("payment_data")}</DialogTitle>
                            <div>
                                <Autocomplete
                                    style={{width: "40%", margin: "auto"}}
                                    id="companies"
                                    size="small"
                                    options={companies}
                                    getOptionLabel={option => option.companyName}
                                    value={company}
                                    defaultValue={company}
                                    noOptionsText={t("no_options")}
                                    onOpen={fetchCompanies}
                                    onChange={handleCompanyChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("company")}
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            error={!company}
                                            value={company}
                                            defaultValue={company}
                                        />
                                    )}
                                />
                            </div>
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("payment_date")}
                                value={paymentDate}
                                onChange={handlePaymentDateChange}
                                format={"dd/MM/yyyy"}
                                disablePast={true}
                            />

                            <AmountChip><Typography>{t("total")} {dataset ? dataset[0]?.cods[0]?.currency : null} {calculateDynamicTotal(dataset).toFixed(2)}</Typography></AmountChip>
                        </Card>


                        {dataset?.map((data, key) => {
                            return <Accordion style={{width: "90%", margin: "auto", marginTop: "1%"}}
                                              expanded={expanded === key.toString()}
                                              onChange={() => handleChange(key.toString())}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography sx={{width: '33%', flexShrink: 0}}>
                                        <Typography
                                            variant={"subtitle2"}>{data.accountCompanyName} - {data.contractCode} - {data.cods[0].currency} {calculateTotal(data.cods).toFixed(2)}</Typography>
                                    </Typography>
                                    {data.error ?
                                        <Chip style={{width: "fit-content", margin: "auto", marginBlock: "1%"}}
                                              icon={<ErrorOutlined style={{color: "red"}}/>} label={data.error}/>
                                        :
                                        null
                                    }
                                    {data.success ?
                                        <Chip style={{width: "fit-content", margin: "auto", marginBlock: "1%"}}
                                              icon={<CheckCircleOutlined style={{color: "green"}}/>}
                                              label={t("successful")}/>
                                        :
                                        null
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{
                                        alignItems: 'center',
                                        display: "flex",
                                        width: "fit-content",
                                        margin: "auto",
                                        marginBottom: "4%"
                                    }}>
                                        <TextField
                                            disabled={data.generateShipment}
                                            size={"small"}
                                            style={{width: "50%"}}
                                            label={t("shipmentId")}
                                            value={data.shipmentId}
                                            defaultValue={data.shipmentId}
                                            onChange={event => handleShipmentIdChange(event, key)}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                        <Typography style={{marginInline: "4%"}}>{t("or")}</Typography>
                                        <FormControlLabel
                                            style={{width: "60%"}}
                                            label={<Typography fontSize={12}
                                                               variant={"button"}>{t("generate_shipment")}</Typography>}
                                            control={
                                                <Checkbox
                                                    style={{color: theme.palette.primary.main}}
                                                    checked={data.generateShipment}
                                                    onChange={event => handleGenerateShipmentChange(event, key)}
                                                />
                                            }
                                            labelPlacement={"right"}
                                        />
                                    </div>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    disabled={data.success}
                                                    color="primary"
                                                    onChange={event => handleAllChecked(event, data)}
                                                    checked={data.allChecked}/>
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_id")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("sender")}
                                            </TableCell>
                                            <TableCell>
                                                {t("amount")}
                                            </TableCell>
                                            <TableCell>
                                                {t("last_event")}
                                            </TableCell>
                                        </TableRow>
                                        {data.cods.map(cod => {
                                            return <TableRow>
                                                <TableCell>
                                                    <Checkbox
                                                        disabled={data.success}
                                                        color="primary"
                                                        onChange={event => handleChecked(event, cod)}
                                                        checked={cod.checked}/>
                                                </TableCell>
                                                <TableCell>
                                                    {cod.contractCode}
                                                </TableCell>
                                                <TableCell>
                                                        <Typography style={{
                                                            cursor: "pointer",
                                                            transition: "all 0.2s",
                                                            color: "#144dff",
                                                            '& > *': {
                                                                borderBottom: 'unset',
                                                            }
                                                        }} onClick={() => goToAboutMe(cod.shipmentId)}>
                                                            {cod.shipmentId}
                                                        </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {cod.shipmentReference}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.senderReference}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.currency} {cod.value.toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.events[cod.events.length - 1].eventCodeName}
                                                </TableCell>

                                            </TableRow>
                                        })}
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        })}
                        <div style={{width: "100%", display: "flex"}}>
                            <Button
                                style={{width: "fit-content", margin: "auto", marginBlock: "5%"}}
                                startIcon={<SaveOutlined/>}
                                color="primary"
                                onClick={savePayments}>
                                {t("save")}
                            </Button>
                        </div>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }


            </div>
        }

        {loadingLabel || pdfContents || labelContent ?
            <div>
                {loadingLabel ?
                    <div style={{marginBlock: "5%", margin: "auto"}}>
                        <Typography variant={"subtitle2"} fontSize={20}
                                    style={{marginBlock: "5%"}}>{t("shipment_created_wait_label_message")}</Typography>
                        <LoadingComponent/>
                    </div>

                    :

                    (labelError ?

                            <div>
                                <Typography
                                    variant={"button"}>{"Si è verificato un errore nel recupero dell'etichetta"}</Typography>
                            </div>
                            :
                            <div style={{width: "fit-content", margin: "auto"}}>
                                <object style={{marginBlock: "5%"}} width={400} height={400}
                                        type={'application/pdf'}
                                        data={"data:application/pdf;base64," + labelContent}>";
                                    html += "
                                </object>
                                {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                            </div>
                    )
                }

                <div style={{margin: "auto", width: "90%"}}>
                    <object style={{margin: "auto", marginBlock: "5%"}} width={"100%"} height={600}
                            type={'application/pdf'} data={"data:application/pdf;base64," + pdfContents}>";
                        html += "
                    </object>
                </div>
            </div>
            :
            null
        }

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>


}

export default NewChequePaymentToCustomerPage;